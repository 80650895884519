import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import {
  Tabs,
  Form,
  Input,
  Button,
} from 'antd';
import { controllerApplication } from 'controller';

const { TabPane } = Tabs;
const { TextArea } = Input;

function AppDetail({ history, location, match }) {
  const id = parseInt(match.params.id);
  const [form] = Form.useForm();
  const [title, setTitle] = useState(id > 0 ? '应用详情' : '新增应用');
  const [editFlag, setEditFlag] = useState(!id);
  const [fetchFlag, setFetchFlag] = useState(false);
  useEffect(() => {
    if (id > 0 && !fetchFlag) {
      controllerApplication.applyInfo({ id: id }).then(res => {
        if (res.code === __SUCCESS__) {
          // setDetail(res.data);
          setFetchFlag(true);
          form.setFieldsValue({
            code: res.data.code,
            name: res.data.name,
            remarks: res.data.remarks,
          });
        } else {
          console.error(res.msg);
        }
      });
    }
  }, [fetchFlag, form, id]);
  const onFinish = values => {
    console.log('Success:', values);
    if (id > 0) {
      controllerApplication.updateApp({ ...values, id }).then(res => {
        if (res.code === __SUCCESS__) {
          goBack();
        } else {
          console.error(res.msg);
        }
      });
    } else {
      controllerApplication.addApply(values).then(res => {
        if (res.code === __SUCCESS__) {
          goBack();
        } else {
          console.error(res.msg);
        }
      });
    }
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const onCancel = () => {
    goBack();
  };
  const changeEditFlag = () => {
    setEditFlag(!editFlag);
    setTitle(editFlag ? '应用详情' : '编辑应用');
    setFetchFlag(editFlag);
  };
  const goBack = () => {
    if (id > 0 && editFlag) {
      setEditFlag(false);
      setTitle('应用详情');
      setFetchFlag(false);
    } else {
      history.go(-1);
    }
  };
  const rightTopBtns = <>
    <Button size="small" onClick={goBack}>返回</Button>&emsp;
    {!!id && <><Button size="small" onClick={changeEditFlag}>{editFlag ? '取消' : '编辑'}</Button>&emsp;</>}
  </>;
  return <Fragment>
    <div className="app-body">
      <Tabs defaultActiveKey="1" className="gray" tabBarExtraContent={rightTopBtns}>
        <TabPane tab={<span>&emsp;{title}&emsp;</span>} key="1" />
      </Tabs>
      <div className="form-box-m">
        <Form
          form={form}
          name="basic"
          layout="vertical"
          hideRequiredMark={!editFlag}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="应用代码"
            name="code"
            rules={[{ required: true, message: '请输入应用代码' }]}
          >
            <Input disabled={!editFlag} />
          </Form.Item>

          <Form.Item
            label="应用名称"
            name="name"
            rules={[{ required: true, message: '请输入应用名称' }]}
          >
            <Input disabled={!editFlag} />
          </Form.Item>

          <Form.Item
            label="备注"
            name="remarks"
            rules={[{ required: false, message: 'Please input your username!' }]}
          >
            <TextArea rows={4} disabled={!editFlag} />
          </Form.Item>

          {editFlag && <Form.Item>
            <Button onClick={onCancel}>
              取消
            </Button>
            <span>&emsp;&emsp;</span>
            <Button type="danger" htmlType="submit">
              提交
            </Button>
          </Form.Item>}
        </Form>
      </div>
    </div>
  </Fragment>;
}

export default AppDetail;
