import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  TreeSelect,
  message,
} from 'antd';
import {
  controllerOrg,
  controllerDC,
} from 'controller';

const { TreeNode } = TreeSelect;

function MyTreeSelect({ value, onChange, antdTreeSelectProps, keyField, titelField, childrenField, treeType, params }) {
  const [result, setResult] = useState(value !== undefined && value !== null && value !== '' ? `${value}` : undefined);
  const [treeData, setTreeData] = useState([]);
  const [prevParams, setPrevParams] = useState(null);
  if (params && JSON.stringify(params) !== JSON.stringify(prevParams)) {
    setPrevParams(params);
  }
  useEffect(() => {
    setResult(value !== undefined && value !== null && value !== '' ? `${value}` : undefined);
  }, [value]);
  useEffect(() => {
    const fetchData = async () => {
      let fetchFun = null;
      switch (treeType) {
        case 'org':
          fetchFun = controllerOrg.getOrgTree;
          break;
        case 'category':
          fetchFun = controllerDC.category.categoryTree;
          if (!prevParams.categorySetCode && !prevParams.categorySetId) return;
          break;
        case 'label':
          fetchFun = controllerDC.label.labelTree;
          break;
        default:
          fetchFun = controllerOrg.getOrgTree;
          break;
      }
      const res = await fetchFun(prevParams);
      setTreeData(res.data || []);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    if(!!prevParams) {
      fetchData();
    }
  }, [prevParams, treeType]);
  const triggerChange = (res) => {
    if (onChange) {
      onChange(res);
    }
  };
  const handleChange = (value, label, extra) => {
    setResult(value);
    triggerChange(value);
  };
  const loop = (data) => data.map(item => {
    if (item[childrenField] && item[childrenField].length > 0) {
      return <TreeNode key={item[keyField]} value={`${item[keyField]}`} title={item[titelField]} extra={item}>
        {loop(item[childrenField])}
      </TreeNode>;
    }
    delete item[childrenField];
    return <TreeNode key={item[keyField]} value={`${item[keyField]}`} title={item[titelField]} extra={item} isLeaf />;
  });
  return <TreeSelect {...antdTreeSelectProps} value={result} onChange={handleChange}>
    {loop(treeData)}
  </TreeSelect>;
}

MyTreeSelect.propTypes = {
  params: PropTypes.object, // 接口参数
  treeType: PropTypes.string, // 树类型，组织树、分类树等
  keyField: PropTypes.string, // antd tree 组件参数 key 对应的字段
  titelField: PropTypes.string, // antd tree 组件参数 title 对应的字段
  childrenField: PropTypes.string, // antd tree 组件参数 children 对应的字段
  onChange: PropTypes.func,
  antdTreeSelectProps: PropTypes.object, // antd TreeSelect 组件参数，参考：https://ant.design/components/tree-select-cn/#API
};
MyTreeSelect.defaultProps = {
  treeType: 'org',
  params: { orgId: 0 },
  keyField: 'orgId', // antd tree 组件参数 key 对应的字段
  titelField: 'orgName', // antd tree 组件参数 title 对应的字段
  childrenField: 'children', // antd tree 组件参数 children 对应的字段
};
// export default forwardRef(MyTreeSelect);
export default MyTreeSelect;
