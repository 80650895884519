/**
 * 接口配置
 */
// 当前环境
const curEnv = process.env.REACT_APP_SECRET_ENV_SIGN ? process.env.REACT_APP_SECRET_ENV_SIGN : (process.env.NODE_ENV || 'development');
// 不同环境中接口的请求地址
const targetConfig = {
  // 开发环境
  development: {
    // host: 'https://devt.yunbiaokj.com',
    // host: 'http://10.0.4.57:10000',
    host: 'https://crmtest.hione.com:10000',
    crm: ''
  },
  // 测试环境
  uat: {
    host: 'https://crmtest.hione.com:10000',
    crm: ''
  },
  // 生产环境
  production: {
    host: 'https://crm.hione.com:10000',
    crm: ''
  },
};
// SERVERENV ---- 线上运行时，从服务器读取的环境信息 index.html [<script src="../config/config.js"></script>]
const service = !!window.SERVERENV ? SERVERENV : targetConfig[curEnv];

export {
  curEnv,
  service,
};
