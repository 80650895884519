import React, {
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  PageHeader,
} from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { TableView } from 'components';
import { controllerApplication } from 'controller';
import { connect } from 'dva';

class ApplicationManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showForm: false,
      searchObj: {
        code: '',
        count: 10,
        name: '',
        page: 1,
      },
      list: [],
      total: 0,
    };
    this.setApp = this.setApp.bind(this);
    this.getData = this.getData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const timer = window.setInterval(() => {
      if (this.props.loginInfo.userId) {
        clearInterval(timer);
        this.getData();
      }
    }, 100);
  }

  handleClick() {
    this.setState({showForm: !this.state.showForm})
  }

  onSearch(values) {
    this.setState({ searchObj: { ...this.state.searchObj, ...values, page: 1 } }, () => {
      this.getData();
    });
  }

  resetSearch() {
    this.setState({
      searchObj: {
        ...this.state.searchObj,
        code: '',
        name: '',
        page: 1,
      },
    }, () => {
      this.getData();
      this.formEl.setFieldsValue({code: '', name: ''});
    });
  }

  pageChange(page, pageSize) {
    this.setState({ searchObj: { ...this.state.searchObj, count: pageSize, page: page } }, () => {
      this.getData();
    });
  }

  getData() {
    const { searchObj } = this.state;
    this.setState({ loading: true });
    controllerApplication.applyAllList(searchObj).then(res => {
      this.setState({ loading: false });
      if (res.code === __SUCCESS__) {
        this.setState({ total: res.total, list: res.data });
      } else {
        console.error(res.msg);
      }
    });
  }

  setApp(data) {
    controllerApplication.setApp({ id: data.id, status: data.status === '1' ? '0' : '1' }).then(res => {
      this.setState({ loading: false });
      if (res.code === __SUCCESS__) {
        this.getData();
      } else {
        console.error(res.msg);
      }
    });
  }

  render() {
    const { searchObj, total, loading, list, showForm } = this.state;
    const tableProps = {
      rowKey: 'id',
      loading,
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '应用代码',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: '应用名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '创建人',
          dataIndex: 'createBy',
          key: 'createBy',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          render: (val) => (val === '1' ? '启用中' : '已停用'),
        },
        {
          title: '操作',
          width: 160,
          align: 'center',
          render: (val, record, index) => <Fragment>
            <Button type="link" href={`#applicationManage/${record.id}`}>查看</Button>
            <Button type="link" onClick={() => this.setApp(record)}>{record.status === '1' ? '停用' : '启用'}</Button>
          </Fragment>,
        },
      ],
      rows: list,
      pageParam: {
        total: total,
        current: searchObj.page,//当前页码
        pageSize: searchObj.count,//当前分页数
        onChange: this.pageChange,
      },
    };
    return <Fragment>
      <div className="app-body">
        <PageHeader
          ghost={false}
          className="gray-header"
          title={<Button type="gray" href="#applicationManage/0">新增应用</Button>}
          extra={[
            <Button className="pos-bottom"
                    key="1"
                    onClick={this.handleClick}
                    icon={<DoubleRightOutlined rotate={showForm ? 270 : 90} />}>展开查询</Button>,
          ]}
        />
        <Form
          name="basic"
          className="top-search-box"
          ref={node => {this.formEl = node}}
          style={{display: showForm ? 'block' : 'none'}}
          initialValues={{
            code: searchObj.code,
            name: searchObj.name,
          }}
          onFinish={this.onSearch}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="应用代码"
                name="code"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="应用名称"
                name="name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button type="danger" htmlType="submit">
                搜索
              </Button>
              &emsp;
              <Button onClick={this.resetSearch} style={{ margin: '0 8px' }}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="page-main-box">
          <TableView {...tableProps} />
        </div>
      </div>
    </Fragment>;
  }
}

ApplicationManage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loginInfo: PropTypes.object,
};
export default connect(({ applicationManage }) => applicationManage)(ApplicationManage);
