/**
 * sharing-rules-controller : 共享规则管理
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'addSharing': { 'path': '/uc/sharingRules/v6/addSharing', 'method': 'POST', 'desc': '添加共享数据类型' },
  'getSharingData': { 'path': '/uc/sharingRules/v6/getSharingData', 'method': 'POST', 'desc': '查询共享数据类型' },
  'getSharingDetail': { 'path': '/uc/sharingRules/v6/getSharingDetail', 'method': 'POST', 'desc': '添加共享数据详情' },
  'getSharingList': { 'path': '/uc/sharingRules/v6/getSharingList', 'method': 'POST', 'desc': '添加共享数据列表' },
  'setSharing': { 'path': '/uc/sharingRules/v6/setSharing', 'method': 'POST', 'desc': '设置共享数据启用/停用' },
  'updateSharing': { 'path': '/uc/sharingRules/v6/updateSharing', 'method': 'POST', 'desc': '修改共享数据' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
