import React, {
  forwardRef,
  useState,
  // useEffect,
  useImperativeHandle,
} from 'react';
import {
  Tabs,
  Row,
  Col,
  Modal,
  Form,
  // Space,
  Input,
  Select,
  // TreeSelect,
  Button,
  InputNumber,
  message,
} from 'antd';
import { MyTreeSelect } from 'components';
// import { MinusCircleOutlined } from '@ant-design/icons';
import { controllerProdCenter } from 'controller';
// import { controllerDC } from 'controller';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

function SpecModal({ refreshList }, ref) {
  const [detailData, setDetailData] = useState({});
  const [visible, setVisible] = useState(false);
  // const [categoryTree, setCategoryTree] = useState([]);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setDetailData(data || {});
      if (data) {
        form.setFieldsValue({
          name: data.name || undefined,
          productTypeId: data.productTypeId || undefined,
          active: `${data.active}`,
          sortOrder: data.sortOrder || undefined,
          description: data.description || undefined,
          specValueList: (data.specValueList || []).map(x => ({specValue: x.specValue, sortOrder: x.sortOrder})),
        });
      }
    },
  }));
  /*useEffect(() => {
    const fetchData = async () => {
      const res = await controllerDC.category.categoryTree({categorySetId: 2});
      setCategoryTree(res.data || []);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    fetchData();
  }, [categoryTree])*/
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        active: values.active ? parseInt(values.active) : undefined
      };
      let func = controllerProdCenter.spec.save;
      if (detailData.id) { // 编辑
        params.id = detailData.id;
        params.source = detailData.source;
        func = controllerProdCenter.spec.update;
      }
      func(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  // const layout = {
  //   labelCol: { span: 3 },
  //   wrapperCol: { span: 21 },
  // };
  const modalProps = {
    // title: '新增/编辑规格',
    title: detailData.id ? '编辑规格' : '新增规格',
    visible,
    width: 800,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
  };
  const myTreeSelectProps = {
    treeType: 'category',
    keyField: 'id',
    titelField: 'name',
    params: {categorySetCode: 'PRO_BASE_CLASS'},
  };
  return <Modal {...modalProps}>
    <Form
      form={form}
      name="basic"
      layout="vertical"
      initialValues={{
        active: '1'
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="规格名称"
            name="name"
            rules={[{ required: true, message: '请输入规格名称' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/*<TreeSelect>
           <Option value={1}>启用</Option>
           <Option value={0}>禁用</Option>
           </TreeSelect>*/}
          <Form.Item
            label="商品分类"
            name="productTypeId"
            rules={[{ required: true, message: '请选择商品分类' }]}
          >
            <MyTreeSelect {...myTreeSelectProps} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="状态"
            name="active"
            rules={[{ required: true, message: '请选择规格状态' }]}
          >
            <Select>
              <Option value="1">启用</Option>
              <Option value="0">禁用</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="排序"
            name="sortOrder"
            rules={[{ required: true, message: '请输入品牌排序' }]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="备注"
        name="description"
        rules={[{ required: false, message: 'Please input your username!' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.List name="specValueList">
        {(fields, { add, remove }) => {
          return (
            <div>
              <Tabs defaultActiveKey="1" className="gray mb0" tabBarExtraContent={<><Button size="small" onClick={() => {add()}}>增加</Button>&emsp;</>}>
                <TabPane tab={<span>&emsp;规格值&emsp;</span>} key="1" />
              </Tabs>
              <Row>
                <Col span={3} className="my-th">序号</Col>
                <Col span={9} className="my-th"><span className="my-require">*</span> 值</Col>
                <Col span={6} className="my-th">排序</Col>
                <Col span={6} className="my-th">操作</Col>
              </Row>
              {fields.map((field, index) => (
                <Row key={field.key}>
                  <Col span={3} className="my-td">{index+1}</Col>
                  <Col span={9} className="my-td">
                    <Form.Item
                      {...field}
                      name={[field.name, 'specValue']}
                      fieldKey={[field.fieldKey, 'specValue']}
                      rules={[{ required: true, message: '请填写规格值' }]}
                    >
                      <Input placeholder="规格值" />
                    </Form.Item>
                  </Col>
                  <Col span={6} className="my-td">
                    <Form.Item
                      {...field}
                      name={[field.name, 'sortOrder']}
                      fieldKey={[field.fieldKey, 'sortOrder']}
                      rules={[{ required: false, message: 'Missing last name' }]}
                    >
                      <InputNumber min={0} style={{width: '100%'}} />
                    </Form.Item>
                  </Col>
                  <Col span={6} className="my-td">
                    <Button type="link" onClick={() => remove(field.name)}>删除</Button>
                  </Col>
                </Row>
              ))}
            </div>
          );
        }}
      </Form.List>
    </Form>
  </Modal>;
}

export default forwardRef(SpecModal);
