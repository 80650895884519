/**
 * 导航菜单
 */
import controllerResource from 'controller/resource';
import { routerRedux } from 'dva/router';
import { storage } from 'utils';


export default {
  state: {
    // 后台配置菜单
    originMenus: [],
    // 首页
    home: {},
    menusObj: {},
    routerTag: 'code'
  },
  effects: {
    * getAllResourceListFn({ payload }, { call, put, select }) {
      const routerTag = yield select((state)=>state.common.routerTag )
      const response = yield call(controllerResource.allResourceList, payload);
      // let active = storage.get('activeMenu');
      if (response.code === __SUCCESS__) {
        let home = response.data[0] || {};
        home = home.list && home.list.length ? home.list[0] : home;

        yield put({
          type: 'updateState',
          payload: {
            originMenus: response.data,
            home: home
          },
        });
        yield put({ type: 'menu2obj' });
        // if (!active) {
          // 缓存当恰活跃菜单
          storage.set('activeMenu', home);
          // 跳转默认首页
          yield put(routerRedux.push(home[routerTag]));
        // }
      }
      return response;
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    menu2obj(state) {
      const tag = state.routerTag;
      let c2m = {};
      const menuLoop = list => {
        list.map(l => {
          c2m[l['code']] = {
            id: l['id']
          }
          if (l.list.length) {
            menuLoop(l.list);
          } else {
            l[tag] = `/${l[tag]}`;
            menusObj[l[tag]] = l;
          }
          return l;
        });
      };

      let menusObj = {};
      state.originMenus.map(i => {
        c2m[i['code']] = {
          id: i['id']
        }
        i[tag] = `/${i[tag]}`;
        menusObj[i[tag]] = i;
        menuLoop(i.list);
        return i.id;
      });
      // 缓存当恰角色菜单
      storage.set('menu', c2m);

      return {
        ...state,
        menusObj
      };
    }
  },
};
