/**
 * dc-pro-spec-controller : 产品规格接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'delete': { 'path': '/procenter/api/v1/procenter/spec/delete', 'method': 'GET', 'desc': '删除规格' },
  'detail': { 'path': '/procenter/api/v1/procenter/spec/detail', 'method': 'GET', 'desc': '规格详情' },
  'invalid': { 'path': '/procenter/api/v1/procenter/spec/invalid', 'method': 'POST', 'desc': '失效规格' },
  'list': { 'path': '/procenter/api/v1/procenter/spec/list', 'method': 'POST', 'desc': '产品规格列表' },
  'save': { 'path': '/procenter/api/v1/procenter/spec/save', 'method': 'POST', 'desc': '新增规格' },
  'specList': { 'path': '/procenter/api/v1/procenter/spec/specList', 'method': 'POST', 'desc': '产品规格列表-新建产品选择产品类别使用' },
  'update': { 'path': '/procenter/api/v1/procenter/spec/update', 'method': 'POST', 'desc': '修改规格' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
