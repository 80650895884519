import React, {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
} from 'antd';
import { controllerDC } from 'controller';

const { Option } = Select;
const { TextArea } = Input;

function CategorySetModal({ refreshList }, ref) {
  const [detailData, setDetailData] = useState({});
  const [type, setType] = useState(1);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setType(data.type);
      setDetailData(data || {});
      if (data && data.id) {
        form.setFieldsValue({
          code: data.code || undefined,
          name: data.name || undefined,
          state: data.state || undefined,
          remark: data.remark || undefined,
        });
      }
    },
  }));
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        type: type, // 1：商品分类，2：客户分类
        source: 2, // 来源 1系统 2用户
      };
      if (detailData.id) { // 编辑
        params.id = detailData.id;
        params.type = detailData.type;
        params.source = detailData.source;
      }
      controllerDC.categorySet.saveOrUpdate(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const modalProps = {
    // title: detailData.id ? `编辑${type === 1 ? '商品' : '客户'}分类集` : `新增${type === 1 ? '商品' : '客户'}分类集`,
    title: detailData.id ? `编辑分类集` : `新增分类集`,
    visible,
    width: 600,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
  };
  return <Modal {...modalProps}>
    <Form
      {...layout}
      form={form}
      initialValues={{type: type}}
      name="basic"
    >
      <Form.Item
        label="分类集类型"
        name="type"
        rules={[
        { required: true, message: '请选择分类集类型' },
      ]}
      >
        <Select>
          <Option value={1}>商品分类集</Option>
          <Option value={2}>客户分类集</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="分类集名称"
        name="name"
        rules={[
          { required: true, message: '请输入名称' },
          { type: 'string', max: 50, message: '不超过50个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="分类集代码"
        name="code"
        rules={[
          { required: true, message: '请输入代码' },
          { type: 'string', max: 20, message: '不超过20个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="状态"
        name="state"
        rules={[{ required: true, message: '请选择状态' }]}
      >
        <Select>
          <Option value={1}>启用</Option>
          <Option value={2}>禁用</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="备注"
        name="remake"
        rules={[{ required: false, message: 'Please input your username!' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  </Modal>;
}

export default forwardRef(CategorySetModal);
