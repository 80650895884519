import React, {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  message,
} from 'antd';
import { controllerProdCenter } from 'controller';

const { Option } = Select;
const { TextArea } = Input;

function UnitModal({ refreshList }, ref) {
  const [detailData, setDetailData] = useState({});
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setDetailData(data || {});
      if (data) {
        form.setFieldsValue({
          nativeCode: data.nativeCode || undefined,
          code: data.code || undefined,
          name: data.name || undefined,
          active: `${data.active}`,
          sortOrder: data.sortOrder || undefined,
          description: data.description || undefined,
        });
      }
    },
  }));
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        active: values.active ? parseInt(values.active) : undefined
      };
      let func = controllerProdCenter.unit.save;
      if (detailData.id) { // 编辑
        params.id = detailData.id;
        params.source = detailData.source;
        func = controllerProdCenter.unit.update;
      }
      func(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  const modalProps = {
    // title: '新增/编辑单位',
    title: detailData.id ? '编辑单位' : '新增单位',
    visible,
    width: 600,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
  };
  return <Modal {...modalProps}>
    <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{
        active: '1'
      }}
    >
      <Form.Item
        label="编码"
        name="nativeCode"
        rules={[
          { required: true, message: '请输入编码' },
          { type: 'string', max: 5, message: '不超过5个字符' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="代码"
        name="code"
        rules={[
          { required: true, message: '请输入代码' },
          { type: 'string', max: 10, message: '不超过10个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="名称"
        name="name"
        rules={[
          { required: true, message: '请输入名称' },
          { type: 'string', max: 50, message: '不超过50个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="状态"
        name="active"
        rules={[{ required: true, message: '请选择状态' }]}
      >
        <Select>
          <Option value={'1'}>启用</Option>
          <Option value={'0'}>禁用</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="排序"
        name="sortOrder"
        rules={[{ required: true, message: '请输入排序' }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="备注"
        name="description"
        rules={[{ required: false, message: 'Please input your username!' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  </Modal>;
}

export default forwardRef(UnitModal);
