/**
 * mxm-service-controller : mxm服务
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'getHomeConfig': { 'path': '/mxmService/home/getHomeConfig', 'method': 'GET', 'desc': '获取主页配置' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
