/**
 * dms-*-controller : dms功能
 */
import order from './order';
import channel from './channel';

export default {
  order,
  channel,
};
