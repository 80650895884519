import React, {
  Fragment,
} from 'react';
import { connect } from 'dva';
import PropTypes from 'prop-types';
import { Form, Input, Button, Checkbox } from 'antd';
import { controllerFile } from 'controller';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import style from './styles.module.less';
import ModifyPwd from '../../layouts/BaseLayout/ModifyPwd';

let source = '';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '应用管理',
      loading: false
    };

    this.getCaptcha = this.getCaptcha.bind(this);
    this.getCaptcha();
  }

  // 刷新验证码
  getCaptcha() {
    source = `web_${new Date().getTime()}`;
    this.props.dispatch({
      type: 'login/captchaFn',
      payload: {
        source: source
      }
    });
  }

  setLoading(flag) {
    this.setState({
      loading: flag
    });
  }

  render() {
    const { loading } = this.state;
    const { captcha, message, homeConfig } = this.props;
    const onFinish = values => {
      this.setLoading(true);
      this.props.dispatch({
        type: 'login/loginFn',
        payload: {
          captcha: values.captcha,
          loginName: values.loginName,
          password: values.password,
          rememberMe: values.rememberMe,
          source: source,
        },
      }).then(t => {
        this.setLoading(false);
        if(t == 'firstLogin') {
          this.refs.ModifyModal.showModal()
        }
      });
    }
    let mainLogoId = (homeConfig && homeConfig.code === 0 && homeConfig.loginLogoId.confValue);
    let logoStyle = !!mainLogoId ? {
      'backgroundImage': `url('${controllerFile.download}/${mainLogoId}')`
    } : null;

    return <Fragment>
      <div className={style["wrapper"]}>
        <div className="bg-white pd20">
          <div className="logo ml80" style={logoStyle}></div>
        </div>
        <div className="mg-at login-wrapper">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {/*<div className="logo mg-at mb40" style={logoStyle}></div>*/}
            <div className="logo logo2"></div>
            <Form.Item
              name="loginName"
              rules={[{ required: true, message: '请输入用户名!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="密码"
              />
            </Form.Item>

            <div className="relative">
              <Form.Item
                name="captcha"
                rules={[{ required: true, message: '请输入验证码!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="验证码" />
              </Form.Item>
              <div className="captcha" onClick={this.getCaptcha}>
                <img className="wr100 hr100" src={captcha} />
                <a className="pd10 fs12 cl333">看不清楚, 换一张</a>
              </div>
            </div>

            <Form.Item>
              <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                <Checkbox>记住密码</Checkbox>
              </Form.Item>

              {/* <a className="login-form-forgot fr" href="">
                忘记密码
              </a> */}
            </Form.Item>

            <Form.Item>
              <Button type="danger" htmlType="submit" className="login-form-button wr100" loading={loading}>
                登   录
              </Button>
              <p className="red">{message}</p>
            </Form.Item>

          </Form>
        </div>
      </div>

      <ModifyPwd ref="ModifyModal"></ModifyPwd>

    </Fragment>;
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  captcha: PropTypes.string.isRequired,
  loginInfo: PropTypes.object,
};

export default connect(({ common, login }) => {
  return { ...common, ...login }
})(Login);
