import React from 'react';
import { connect } from 'dva';
import {
  Redirect,
  Route,
  Switch,
} from 'dva/router';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import dynamic from 'dva/dynamic';
import Layout from './layouts/index';
import routeConfig from './config/route.config.js';
import './styles/App.less';
moment.locale('zh-cn');
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    props.dispatch({
      type: 'common/getHomeConfigFn'
    });
  }
  static getDerivedStateFromProps(props, state)  {
    const { homeConfig } = props;
    if(homeConfig && homeConfig.code === 0 && homeConfig.browserTitle.confValue) {
      document.title = (homeConfig && homeConfig.code === 0 && homeConfig.browserTitle.confValue) || "火王开发环境";
    }
    return null;
  }

  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Layout>
          <Switch>
            {
              routeConfig.map(({path, redirect, ...dynamics},index) => {
                if(redirect) {
                  return <Redirect key={index} from={path} to={redirect} exact />
                }
                return <Route
                  key={index}
                  path={path}
                  exact
                  component={dynamic({
                    app: window.g_app,
                    ...dynamics
                  })}
                />
              })
            }
          </Switch>
        </Layout>
      </ConfigProvider>
    )
  }
  /*return (
    <div className="App">
      {/!*<Switch>
        {
          routes.map(({path, redirect, ...rest},index) => {
            if(redirect) {
              return <Redirect key={index} from={path} to={redirect} exact />
            }
            return <Route
              key={index}
              path={path}
              exact
              component={dynamic({
                app: window.g_app,
                models: () => (rest.models||[]).map(x => require(`${x}`).defalut),
                // component: () => require(`${rest.component}`).defalut,
                component: () => loadable(() => import(`${rest.component}`)),
              })}
            />
          })
        }
      </Switch>*!/}
      <div className="main">
        <Switch>
          {
            routeConfig.map(({path, redirect, ...dynamics},index) => {
              if(redirect) {
                return <Redirect key={index} from={path} to={redirect} exact />
              }
              return <Route
                key={index}
                path={path}
                exact
                component={dynamic({
                  app: window.g_app,
                  ...dynamics
                })}
              />
            })
          }
        </Switch>
      </div>
      <footer className="footer">
        <span>@2019 ©云镖demo</span>
        <span>版本信息:V6.0   最后更新日期:2020年4月1日</span>
      </footer>
    </div>
  );*/
}

export default connect(({ common }) => common)(App);
