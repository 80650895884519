/**
 * group-controller : 分组(用户分组)管理
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'addGroup': { 'path': '/uc/group/v6/addGroup', 'method': 'POST', 'desc': '添加分组' },
  'addGroupEmp': { 'path': '/uc/group/v6/addGroupEmp', 'method': 'POST', 'desc': '添加分成员' },
  'delGroup': { 'path': '/uc/group/v6/delGroup', 'method': 'POST', 'desc': '删除分组' },
  'groupEmpList': { 'path': '/uc/group/v6/groupEmpList', 'method': 'POST', 'desc': '分组成员' },
  'groupInfo': { 'path': '/uc/group/v6/groupInfo', 'method': 'POST', 'desc': '分组详情' },
  'groupList': { 'path': '/uc/group/v6/groupList', 'method': 'POST', 'desc': '分组列表' },
  'groupMxmList': { 'path': '/uc/group/v6/groupMxmList', 'method': 'POST', 'desc': 'mxm分组列表' },
  'listSelectOrg': { 'path': '/uc/group/v6/listSelectOrg', 'method': 'POST', 'desc': '分组组织' },
  'updateGroup': { 'path': '/uc/group/v6/updateGroup', 'method': 'POST', 'desc': '修改分组' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
