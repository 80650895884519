import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  PageHeader,
  Modal,
} from 'antd';
import SpecModal from './components/specModal';
import { TableView, MyTreeSelect } from 'components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { controllerProdCenter } from 'controller';

const confirm = Modal.confirm;

function Specification() {
  const modalRef1 = useRef(null);
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchObj, setSearchObj] = useState({ name: '', productTypeId: undefined, p: 1, s: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await controllerProdCenter.spec.list(searchObj);
      setIsLoading(false);
      setList(res.data || []);
      setTotal(res.total || 0);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    fetchData();
  }, [searchObj]);
  const handleClick = () => {
    setShowForm(!showForm);
  };
  const setSpecState = (record) => {
    controllerProdCenter.spec.invalid({
      id: record.id,
      active: record.active === 1 ? 0 : 1, // 状态：1-启用；2-禁用
    }).then(res => {
      res.code === __SUCCESS200__ && setSearchObj({ ...searchObj });
      res.code !== __SUCCESS200__ && message.error(res.msg);
    });
  };
  const optClickHandle = (record, act) => {
    switch (act) {
      case 'edit':
        modalRef1.current.showModal(record);
        break;
      case 'valid': // 启用/禁用
        setSpecState(record);
        break;
      case 'delete':
        confirm({
          title: '是否删除该条数据？',
          //content: 'Some descriptions',
          okText: '是',
          okType: 'danger',
          cancelText: '否',
          onOk() {
            controllerProdCenter.spec.delete({id: record.id}).then(res => {
              res.code === __SUCCESS200__ && setSearchObj({ ...searchObj });
              res.code !== __SUCCESS200__ && message.error(res.msg);
            })
          },
          onCancel() {
            console.log('cancel');
          }
        });
        break;
      default:
        break;
    }
  };
  const pageChange = (page, pageSize) => {
    setSearchObj({
      ...searchObj,
      p: page,
      s: pageSize,
    });
  };
  const onSearch = (values) => {
    setSearchObj({
      ...searchObj,
      ...values,
      p: 1,
    });
  };
  const resetSearch = () => {
    setSearchObj(data => ({
      ...data,
      name: '',
      productTypeId: undefined,
      page: 1,
    }));
    form.setFieldsValue({
      name: '',
      productTypeId: undefined,
    });
  };
  const tableProps = {
    rowKey: 'id',
    loading: isLoading,
    columns: [
      {
        title: '规格名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '商品分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
      },
      {
        title: '有效性',
        dataIndex: 'active',
        key: 'active',
        width: 80,
        render: (val) => (val === 1 ? '启用' : '禁用'),
      },
      {
        title: '规格值',
        dataIndex: 'specValueList',
        key: 'specValueList',
        render: (val) => (val || []).map(x => x.specValue).join(),
      },
      {
        title: '操作',
        width: 200,
        align: 'center',
        render: (val, record, index) => <Fragment>
          <Button type="link" onClick={() => optClickHandle(record, 'edit')}>编辑</Button>
          <Button type="link"
                  onClick={() => optClickHandle(record, 'valid')}>{record.active === 1 ? '禁用' : '启用'}</Button>
          <Button type="link" onClick={() => optClickHandle(record, 'delete')}>删除</Button>
        </Fragment>,
      },
    ],
    rows: list,
    pageParam: {
      total: total,
      current: searchObj.p,//当前页码
      pageSize: searchObj.s,//当前分页数
      onChange: pageChange,
    },
  };
  const baseCategoryTreeProps = {
    treeType: 'category',
    keyField: 'id',
    titelField: 'name',
    params: { categorySetCode: 'PRO_BASE_CLASS' },
  };
  return <Fragment>
    <div className="app-body">
      <PageHeader
        ghost={false}
        className="gray-header"
        title={<Button type="gray" onClick={() => modalRef1.current.showModal()}>新增</Button>}
        extra={[
          <Button className="pos-bottom"
                  key="1"
                  onClick={handleClick}
                  icon={<DoubleRightOutlined rotate={showForm ? 270 : 90} />}>{showForm ? '关闭查询' : '展开查询'}</Button>,
        ]}
      />
      <Form
        form={form}
        name="basic"
        className="top-search-box"
        style={{ display: showForm ? 'block' : 'none' }}
        initialValues={searchObj}
        onFinish={onSearch}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="规格名称"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="商品类别"
              name="productTypeId"
            >
              <MyTreeSelect {...baseCategoryTreeProps} />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button type="danger" htmlType="submit">
              搜索
            </Button>
            &emsp;
            <Button onClick={resetSearch} style={{ margin: '0 8px' }}>
              重置
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="page-main-box">
        <TableView {...tableProps} />
      </div>
    </div>
    <SpecModal ref={modalRef1} refreshList={onSearch}></SpecModal>
  </Fragment>;
}

export default Specification;
