import React, {
  useState,
  useEffect,
  // forwardRef,
  // useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Tree,
  Spin,
  message,
} from 'antd';
import style from './style.module.less';
import {
  controllerOrg,
  controllerDC,
  controllerCs
} from 'controller';

function MyTree({ treeProps, keyField, titelField, childrenField, treeType, params, onSelect, onCheck }) {
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);
  // const [prevParams] = useState(params);
  const [prevParams, setPrevParams] = useState(params);
  if (params && JSON.stringify(params) !== JSON.stringify(prevParams)) {
    setPrevParams(params);
  }
  /*useImperativeHandle(ref, () => ({
   showModal: () => {
   setVisible(true);
   },
   }));*/
  useEffect(() => {
    const fetchData = async () => {
      let fetchFun = null;
      switch (treeType) {
        case 'org':
          fetchFun = controllerOrg.getOrgTree;
          if(!prevParams.hasOwnProperty('orgId')) {
            return
          }
          break;
        case 'category':
          fetchFun = controllerDC.category.categoryTree;
          if(!prevParams.hasOwnProperty('categorySetId')) {
            return
          }
          break;
        case 'label':
          fetchFun = controllerDC.label.labelTree;
          if(!prevParams.hasOwnProperty('labelSetId')) {
            return
          }
          break;
        case 'bomTree':
          fetchFun = controllerCs.bom.bomItemTree;
          if(!prevParams.hasOwnProperty('bomId')) {
            return
          }
          break;
        default:
          fetchFun = controllerOrg.getOrgTree;
          break;
      }
      setLoading(true);
      const res = await fetchFun(prevParams);
      setLoading(false);
      setTreeData(res.data || []);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    if(!!prevParams && prevParams instanceof Object && Object.keys(prevParams).length > 0) {
      fetchData();
    }
  }, [prevParams, treeType]);
  const handleSelect = (selectedKeys, e) => {
    const { selected, selectedNodes, node, event } = e;
    console.log('debug tree select', selected, selectedNodes, node, event);
    if (onSelect && typeof onSelect === 'function') {
      onSelect(node);
    }
  };
  const handleCheck = (checkedKeys, e) => {
    const { checked, checkedNodes, node, event, halfCheckedKeys } = e;
    console.log('debug tree check', checked, checkedNodes, node, event, halfCheckedKeys);
    if (onCheck && typeof onCheck === 'function') {
      onCheck(checkedKeys, checkedNodes);
    }
  };
  const loop = (data) => data.map(item => {
    if (item[childrenField] && item[childrenField].length > 0) {
      return {
        ...item,
        key: item[keyField],
        title: item[titelField],
        children: loop(item[childrenField]),
      };
    }
    delete item[childrenField];
    return {
      ...item,
      key: item[keyField],
      title: item[titelField],
    };
  });
  return <div className={style['my-tree-box']}>
    <Spin spinning={loading} wrapperClassName={style['my-tree-box-spin']}>
      <Tree {...treeProps} treeData={loop(treeData)} onSelect={handleSelect} onCheck={handleCheck} />
    </Spin>
  </div>;
}

MyTree.propTypes = {
  params: PropTypes.object, // 接口参数
  treeType: PropTypes.string, // 树类型，组织树、分类树等
  keyField: PropTypes.string, // antd tree 组件参数 key 对应的字段
  titelField: PropTypes.string, // antd tree 组件参数 title 对应的字段
  childrenField: PropTypes.string, // antd tree 组件参数 children 对应的字段
  onSelect: PropTypes.func,
  onCheck: PropTypes.func,
  treeProps: PropTypes.object, // antd tree 组件参数，参考：https://ant.design/components/tree-cn/#API
};

MyTree.defaultProps = {
  treeType: 'org',
  params: { orgId: 0 },
  keyField: 'orgId', // antd tree 组件参数 key 对应的字段
  titelField: 'orgName', // antd tree 组件参数 title 对应的字段
  childrenField: 'children', // antd tree 组件参数 children 对应的字段
};
// export default forwardRef(MyTree);
export default MyTree;
