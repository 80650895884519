/**
 * application-controller : 应用管理
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'addApply': { 'path': '/uc/application/v6/addApply', 'method': 'POST', 'desc': '添加应用' },
  'applyAllList': { 'path': '/uc/application/v6/applyAllList', 'method': 'POST', 'desc': '应用列表' },
  'applyInfo': { 'path': '/uc/application/v6/applyInfo', 'method': 'POST', 'desc': '应用详情' },
  'applyList': { 'path': '/uc/application/v6/applyList', 'method': 'POST', 'desc': '应用列表' },
  'delApply': { 'path': '/uc/application/v6/delApply', 'method': 'POST', 'desc': '删除应用' },
  'setApp': { 'path': '/uc/application/v6/setApp', 'method': 'POST', 'desc': '设置应用' },
  'updateApp': { 'path': '/uc/application/v6/updateApp', 'method': 'POST', 'desc': '修改应用' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
