import React from 'react';
import { connect } from 'dva';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Link } from 'dva/router';
import { storage } from 'utils';
require('./style.module.less');
const { SubMenu } = Menu;
const defaultIcon = 'iconfont icon-yingyongguanli';

class AsynMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: '',
      openKeys: '',
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  selectFn = (key) => {
    this.setState({
      'current': [`/${key}`],
    });
  };

  onMenuClick(e) {
    const { menusObj, onSamePageClick } = this.props;
    const menu = menusObj[e.key];
    this.setState({
      current: [e.key],
    });
    storage.set('activeMenu', menu);
    if (new RegExp(`${this.state.current[0]}$`).test(window.location.hash)) onSamePageClick(menu);
  }

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    this.setState({ openKeys: [latestOpenKey] });
  };

  render() {
    const { originMenus, routerTag } = this.props;
    const link = (i, to) => {
      let anchor = `anchor_${i[to]}`.replace(/\//g, '');
      return i[to] ? <Link to={i[to]} id={anchor}>{i.name}</Link> : '';
    };
    const MenuLoop = i => {
      return <SubMenu key={i.code} title={i.name} icon={<i className={i.icon || defaultIcon}></i>}>
        {i.list.map(li => li.list && li.list.length ?
          MenuLoop(li)
          :
          <Menu.Item key={li.code}>
            {link(li, routerTag)}
          </Menu.Item>)}
      </SubMenu>;
    };
    const MenuItem = (list, node) => {
      return list.map(i => {
        if (i.list && i.list.length) {
          return MenuLoop(i);
        }
        return <Menu.Item key={i.code} icon={<i className={i.icon || defaultIcon}></i>}>
          {link(i, routerTag)}
        </Menu.Item>;
      });
    };
    return (
      <Menu
        className="asyn-menu"
        onClick={this.onMenuClick.bind(this)}
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        selectedKeys={this.state.current}
        mode="inline"
      >
        {MenuItem(originMenus)}
      </Menu>
    );
  }
}

AsynMenu.propTypes = {
  originMenus: PropTypes.array,
  menusObj: PropTypes.object,
};
export default connect(({ common }) => common)(AsynMenu);
