/**
 * dc-product-controller : 产品管理接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'create': { 'path': '/procenter/api/v1/procenter/product/create', 'method': 'POST', 'desc': '新增产品' },
  'createForChannel': {
    'path': '/procenter/api/v1/procenter/product/createForChannel',
    'method': 'POST',
    'desc': '产品列表(非厂商)。预售产品入库',
  },
  'delete': { 'path': '/procenter/api/v1/procenter/product/delete', 'method': 'POST', 'desc': '删除产品' },
  'details': { 'path': '/procenter/api/v1/procenter/product/details', 'method': 'GET', 'desc': '产品详情' },
  'detailsSpecMatrix': {
    'path': '/procenter/api/v1/procenter/product/detailsSpecMatrix',
    'method': 'GET',
    'desc': '获取产品详情页规格矩阵列表（规格矩阵列表，作用于前端判断是否可以删除该规格）',
  },
  'down': { 'path': '/procenter/api/v1/procenter/product/down', 'method': 'POST', 'desc': '批量下架产品' },
  'initStressData': { 'path': '/procenter/api/v1/procenter/product/initStressData', 'method': 'GET', 'desc': '插入压测数据' },
  'list': { 'path': '/procenter/api/v1/procenter/product/list', 'method': 'POST', 'desc': '产品列表' },
  'listForStore': {
    'path': '/procenter/api/v1/procenter/product/listForStore',
    'method': 'POST',
    'desc': '(暂时废弃，使用listProDuct代替)产品列表(门店顾客，新增零售时产品列表)',
  },
  'listPc': { 'path': '/procenter/api/v1/procenter/product/listPc', 'method': 'POST', 'desc': '根据条件获取产品列表(pc)' },
  'listPcForChannel': {
    'path': '/procenter/api/v1/procenter/product/listPcForChannel',
    'method': 'POST',
    'desc': '产品列表(非厂商)。获取供货商的在售产品列表',
  },
  'listPcSpec': { 'path': '/procenter/api/v1/procenter/product/listPcSpec', 'method': 'POST', 'desc': '产品-规格列表' },
  'listPcSpecByStorage': {
    'path': '/procenter/api/v1/procenter/product/listPcSpecByStorage',
    'method': 'POST',
    'desc': '产品-规格列表（调拨使用）',
  },
  'listProDuct': {
    'path': '/procenter/api/v1/procenter/product/listProDuct',
    'method': 'POST',
    'desc': '产品列表(调货，销售出库，零售出库)',
  },
  'read': { 'path': '/procenter/api/v1/procenter/product/read', 'method': 'GET', 'desc': '增加浏览次数' },
  'specInventory': {
    'path': '/procenter/api/v1/procenter/product/specInventory',
    'method': 'POST',
    'desc': '根据产品id和规格获取产品库存',
  },
  'specInventoryForSale': {
    'path': '/procenter/api/v1/procenter/product/specInventoryForSale',
    'method': 'POST',
    'desc': '根据产品id和规格获取产品库存(销售出库，零售出库产品列表)',
  },
  'getAllTypeList': {
    'path': '/procenter/api/v1/procenter/categoryType/getAllTypeList',
    'method': 'GET',
    'desc': '选择产品类型下拉 接口用',
  },
  'specMatrix': { 'path': '/procenter/api/v1/procenter/product/specMatrix', 'method': 'GET', 'desc': '获取产品规格属性矩阵' },
  'up': { 'path': '/procenter/api/v1/procenter/product/up', 'method': 'POST', 'desc': '批量上架产品' },
  'update': { 'path': '/procenter/api/v1/procenter/product/update', 'method': 'POST', 'desc': '更新产品' },
  'proSpecConfigList': { 
    'path': '/procenter/api/v1/procenter/spec/proSpecConfigList', 'method': 'POST', 'desc': '更新产品' 
  },

};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
