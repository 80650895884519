import request from './request';
import storage from './storage';
import tools from './tools';
import { controllerFile } from 'controller';

// 富文本编辑器BraftEditor上传文件方法
const BraftEditorUploadFn = (param) => {
  const serverURL = controllerFile.uploadUrl;
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  const successFn = (response) => {
    // 假设服务端直接返回文件上传后的地址
    // 上传成功后调用param.success并传入上传后的文件地址
    const data = JSON.parse(xhr.responseText || '{}').data || {}
    let metaInfo = {
      id: data.id,
      title: data.orginalName,
      alt: data.orginalName,
    }
    if(/(video|audio)\/*/.test(param.file.type)){
      metaInfo = {
        ...metaInfo,
        loop: true, // 指定音视频是否循环播放
        autoPlay: true, // 指定音视频是否自动播放
        controls: true, // 指定音视频是否显示控制栏
        // poster: '', // 指定视频播放器的封面
      }
    }
    param.success({
      url: data.filePath,
      meta: metaInfo,
    });
  };
  const progressFn = (event) => {
    // 上传进度发生变化时调用param.progress
    param.progress(event.loaded / event.total * 100);
  };
  const errorFn = (response) => {
    // 上传发生错误时调用param.error
    param.error({
      msg: 'unable to upload.',
    });
  };
  xhr.upload.addEventListener('progress', progressFn, false);
  xhr.addEventListener('load', successFn, false);
  xhr.addEventListener('error', errorFn, false);
  xhr.addEventListener('abort', errorFn, false);
  fd.append('file', param.file);
  fd.append('source', 'mxm-pc');
  xhr.open('POST', serverURL, true);
  xhr.send(fd);
};
export {
  request,
  storage,
  tools,
  BraftEditorUploadFn
};
