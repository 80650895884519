import React from 'react';
import { connect } from 'dva';
import {
  Layout,
} from 'antd';
import PropTypes from 'prop-types';

const { Content, Footer } = Layout;

// const { SubMenu } = Menu;
class BaseLayout extends React.Component {

  render() {
    const { homeConfig } = this.props;
    let bottomTitle = (homeConfig.code === 0 && homeConfig.bottomTitle.confValue) || '@2019 云镖网络 版权所有 粤ICP备18130807号-1';

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Content>
            {this.props.children}
          </Content>
          <Footer>
            <div className="mg-at fs12 gray tc">{bottomTitle}</div>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

BaseLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default connect(({ common }) => common)(BaseLayout);