export const stateMap = {
  //1-启用；2-禁用
  '1': '启用',
  '0': '禁用',
}
export const sourceMap = {
  //来源 1 系统 2 用户
  '1': '系统',
  '2': '用户',
}
