import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Cascader,
  message,
} from 'antd';
import {
  controllerOrg,
  controllerDC,
} from 'controller';

function MyCascader({ value, onChange, antdCascaderProps, labelInValue, keyField, titelField, childrenField, treeType, params }) {
  // const [val, setVal] = useState(value || undefined);
  const [result, setResult] = useState(value || []);
  const [treeData, setTreeData] = useState([]);
  const [prevParams, setPrevParams] = useState(null);
  if (params && JSON.stringify(params) !== JSON.stringify(prevParams)) {
    setPrevParams(params);
  }
  useEffect(() => {
    setResult((value || []).map(x => x.value));
    // setVal((value || undefined).map(x => x.value));
  }, [value]);
  useEffect(() => {
    const loop = (data) => data.map(item => {
      if (item[childrenField] && item[childrenField].length > 0) {
        return {
          value: item[keyField],
          label: item[titelField],
          children: loop(item[childrenField]),
        };
      }
      delete item[childrenField];
      return {
        value: item[keyField],
        label: item[titelField],
      };
    });
    const fetchData = async () => {
      let fetchFun = null;
      switch (treeType) {
        case 'org':
          fetchFun = controllerOrg.getOrgTree;
          break;
        case 'category':
          fetchFun = controllerDC.category.categoryTree;
          if (!prevParams.categorySetCode && !prevParams.categorySetId) return;
          break;
        case 'label':
          fetchFun = controllerDC.label.labelTree;
          break;
        default:
          fetchFun = controllerOrg.getOrgTree;
          break;
      }
      const res = await fetchFun(prevParams);
      setTreeData(loop(res.data || []));
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    if(!!prevParams) {
      fetchData();
    }
  }, [childrenField, keyField, prevParams, titelField, treeType]); // 此处禁止了eslint自动补全依赖列表，减少请求次数
  const triggerChange = (res) => {
    if (onChange) {
      onChange(res);
    }
  };
  const handleChange = (value, selectedOptions) => {
    if(labelInValue) {
      let val = (selectedOptions || []).map(x => ({label: x.label, value: x.value}))
      setResult(value);
      // setVal(value);
      triggerChange(val);
    } else {
      setResult(value);
      // setVal(value);
      triggerChange(value);
    }
  };
  const cascaderProps = {
    ...antdCascaderProps,
    /*displayRender: (label, selectedOptions) => {
      if(labelInValue) {
        return label.map(x => x.label).join(' / ')
      }
      return label.join(' / ')
    }*/
  }
  /*return <TreeSelect {...antdCascaderProps} value={result} onChange={handleChange}>
    {loop(treeData)}
  </TreeSelect>;*/
  return <Cascader {...cascaderProps} options={treeData} onChange={handleChange} value={result} />
}

MyCascader.propTypes = {
  params: PropTypes.object, // 接口参数
  labelInValue: PropTypes.bool, // 树类型，组织树、分类树等
  treeType: PropTypes.string, // 树类型，组织树、分类树等
  keyField: PropTypes.string, // antd tree 组件参数 key 对应的字段
  titelField: PropTypes.string, // antd tree 组件参数 title 对应的字段
  childrenField: PropTypes.string, // antd tree 组件参数 children 对应的字段
  onChange: PropTypes.func,
  antdCascaderProps: PropTypes.object, // antd Cascader 组件参数，参考：https://ant.design/components/cascader-cn/#API
};
MyCascader.defaultProps = {
  treeType: 'org',
  params: { orgId: 0 },
  labelInValue: false,
  keyField: 'orgId', // antd tree 组件参数 key 对应的字段
  titelField: 'orgName', // antd tree 组件参数 title 对应的字段
  childrenField: 'children', // antd tree 组件参数 children 对应的字段
};
// export default forwardRef(MyCascader);
export default MyCascader;
