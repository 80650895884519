import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  message,
} from 'antd';
import { controllerFile } from 'controller';
import { storage } from 'utils';

function MyUpload({ value, onChange, antdUploadProps, maxSize, maxWidth, maxHeight, maxCount, children }) {
  const loginInfo = storage.get('loginInfo');
  const [list, setList] = useState(value || []);
  useEffect(() => {
    setList(value || []);
  }, [value]);
  const triggerChange = (list) => {
    if (onChange) {
      onChange(list);
    }
  };
  const checkImgWidthHeight = (file) => new Promise((resolve, reject) => {
    // 如果 maxWidth 和 maxHeight 有值，则检测图片尺寸，否则不检测
    if (maxWidth && maxHeight) {
      const img = new Image();//创建新的图片对象
      img.src = window.URL.createObjectURL(file);
      img.setAttribute('crossOrigin', 'Anonymous');
      img.onload = function () {
        //图片宽、高超过最大值
        const widthHeightOverflow = img.naturalHeight > maxHeight || img.naturalWidth > maxWidth;
        window.URL.revokeObjectURL(this.src);
        resolve(widthHeightOverflow);
      };
    } else {
      resolve(false);
    }
  });
  const uploadProps = {
    name: 'file',
    accept: antdUploadProps.accept || 'image/*',
    action: controllerFile.uploadUrl,
    headers: {
      corTicket: loginInfo && loginInfo.corTicket,
    },
    fileList: list,
    beforeUpload: (file) => new Promise((resolve, reject) => {
      checkImgWidthHeight(file).then((widthHeightOverflow) => {
        // 检测文件类型
        let fileTypeCorrect = true;
        // accept，参考：https://ant.design/components/upload-cn/#API
        // accept，参考：https://developer.mozilla.org/zh-CN/docs/Web/HTML/Element/input/file#accept
        if (antdUploadProps && antdUploadProps.accept) {
          fileTypeCorrect = antdUploadProps.accept.indexOf(file.type) > -1;
          if (!fileTypeCorrect) {
            message.error('不支持上传这种文件！');
          }
        } else {
          fileTypeCorrect = file.type === 'image/jpeg' || file.type === 'image/png';
          if (!fileTypeCorrect) {
            message.error('只能上传JPG/PNG !');
          }
        }
        // 检测文件大小
        const sizeOverflow = maxSize ? (file.size / 1024 / 1024 > maxSize) : false;
        if (sizeOverflow) {
          message.error(`文件必须小于${maxSize}MB !`);
        }
        // 检测图片尺寸
        if (widthHeightOverflow) {
          message.warning(`建议图片大小：${maxWidth}px * ${maxHeight}px !`);
        }
        // if (fileTypeCorrect && !sizeOverflow && !widthHeightOverflow) {
        if (fileTypeCorrect && !sizeOverflow) {
          resolve();
        } else {
          reject();
        }
      });
    }),
    data: (e) => {
      return {
        source: 'mxm-pc',
      };
    },
    onChange: info => {
      let fileList = [...info.fileList];
      if (maxCount) {
        fileList = fileList.slice(0 - maxCount);
      }
      fileList = fileList.map(file => {
        if (file.response && file.response.code === __SUCCESS__) {
          file.fileId = file.response.data.id;
          file.url = `${controllerFile.fileUrlHead}${file.response.data.url}`;
        }
        return file;
      });
      setList(fileList);
      triggerChange(fileList);
    },
    ...antdUploadProps,
  };
  return <Upload {...uploadProps}>
    {children}
  </Upload>;
}

MyUpload.propTypes = {
  value: PropTypes.array, // 文件列表
  onChange: PropTypes.func,
  maxSize: PropTypes.number, // 文件大小
  maxWidth: PropTypes.number, // 图片最大宽度
  maxHeight: PropTypes.number, // 图片最大高度
  maxCount: PropTypes.number, // 文件数量
  children: PropTypes.node,
  antdUploadProps: PropTypes.object, // antd upload 组件参数，参考：https://ant.design/components/upload-cn/#API
};
export default MyUpload;
