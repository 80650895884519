/**
 * dc-var-label-controller : 全局标签调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'delete': { 'path': '/var/v1/label/delete', 'method': 'POST', 'desc': '删除标签' },
  'detail': { 'path': '/var/v1/label/detail', 'method': 'POST', 'desc': '按ID获取标签详情' },
  'labelTree': { 'path': '/var/v1/label/labelTree', 'method': 'POST', 'desc': '获取标签树接口' },
  'labelTreeByIds': { 'path': '/var/v1/label/labelTreeByIds', 'method': 'POST', 'desc': '通过id获取标签树接口' },
  'saveOrUpdate': { 'path': '/var/v1/label/saveOrUpdate', 'method': 'POST', 'desc': 'save' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
