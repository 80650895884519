/**
 * dc-var-sort-collect-controller : 分类集调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'copy': { 'path': '/var/v1/categorySet/copy', 'method': 'POST', 'desc': '按ID复制分类集' },
  'detail': { 'path': '/var/v1/categorySet/detail', 'method': 'POST', 'desc': '按ID获取分类集详情' },
  'disable': { 'path': '/var/v1/categorySet/disable', 'method': 'POST', 'desc': '按ID禁用分类集' },
  'list': { 'path': '/var/v1/categorySet/list', 'method': 'POST', 'desc': '按条件获取分类集列表' },
  'saveOrUpdate': { 'path': '/var/v1/categorySet/saveOrUpdate', 'method': 'POST', 'desc': '新增或者修改分类集' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
