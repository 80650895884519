import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Tabs,
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Switch,
  Table,
  Checkbox,
  DatePicker,
  InputNumber,
} from 'antd';
import moment from 'moment';
import 'braft-editor/dist/index.css';
import BraftEditor from 'braft-editor';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  MyUpload,
  TableModal,
  MyCascader,
  SelectRange,
  MyTreeSelect,
  MyListSelect,
  StrangeTreeSelect,
} from 'components';
import {
  controllerDMS,
  controllerProdCenter,
} from 'controller';
import {
  storage,
  BraftEditorUploadFn,
} from 'utils';

const { TabPane } = Tabs;
const dateFormat = 'YYYY-MM-DD';
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const layout2 = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const layout3 = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 19 },
};
const otherStyle = {
  padding: '8px',
  border: 'solid 1px #ddd',
  borderRadius: '6px',
};
const antdSelectProps = {
  labelInValue: true,
};
const antdTreeSelectProps = {
  labelInValue: true,
};

function AppDetail({ history, location, match }) {
  const modalRef1 = useRef(null);
  const modalRef2 = useRef(null);
  const id = match.params.id === '0' ? 0 : (match.params.id);
  // const dmsUserInfo = registerInfo && registerInfo.dms;
  const [form] = Form.useForm();
  const [baseCategoryId, setBaseCategoryId] = useState(undefined); // 商品基本类别
  const [saleAreaKeys, setSaleAreaKeys] = useState([]); // 销售区域
  const [saleAreaNodes, setSaleAreaNodes] = useState([]); // 销售区域
  const [minimumOrderQuantityStep, setMinimumOrderQuantityStep] = useState(1);
  const [selectSpec, setSelectSpec] = useState([]);
  const [jsonObject, setJsonObject] = useState([]); // 已选择的规格选项，编辑时控制页面展示
  const [prefixColumns, setPrefixColumns] = useState([]);
  const [proSpecConfigVoList, setProSpecConfigVoList] = useState([]);
  const [title, setTitle] = useState(!!id ? '商品详情' : '新增商品');
  const [rangeType, setRangeType] = useState(2); //  1组织,2区域 默认2
  const [detailData, setDetailData] = useState({});
  const [editFlag, setEditFlag] = useState(!id);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [disableBtnFlag, setDisableBtnFlag] = useState(false); // 控制批次和序列号是否能编辑
  const registerInfo = storage.get('registerInfo');
  const [dmsUserInfo] = useState(registerInfo && registerInfo.dms ? registerInfo.dms : {});
  useEffect(() => {
    const fetchSpecListForDetail = (arr) => {
      const list = [];
      const promises = arr.map(x => new Promise((resolve, reject) => {
        controllerProdCenter.spec.detail({ id: x.specId }).then(res => {
          if (res.code === __SUCCESS200__) {
            list.push(res.data);
            resolve();
          } else {
            reject();
          }
        });
      }));
      return new Promise((resolve, reject) => {
        Promise.all(promises).then((values) => {
          list.forEach(x => {
            const index = arr.findIndex(y => y.specId === x.id);
            x.specValueList.forEach(z => {
              // arr[index].value.includes(z.specValue); 兼容历史商品规格数据,所有商品重新编辑后可移除
              z.checked = arr[index].data ? arr[index].data.some(i => i.text === z.specValue) : arr[index].value.includes(z.specValue);
              z.disabled = z.checked;
            });
          });
          setSelectSpec(list);
          resolve();
        });
      });
    };
    const fetchDetail = () => {
      if (!!id && !fetchFlag) {
        setFetchFlag(true);
        controllerProdCenter.product.details({ id: id }).then(res => {
          if (res.code === __SUCCESS200__) {
            const data = res.data || {};
            // 商品基本分类
            const categoryPathId = (data.pathIds || '').split(',').filter(x => !!x).map(y => parseInt(y));
            const categoryPathName = (data.categoryText || '').split(',').filter(x => !!x);
            const categoryPath = categoryPathId.map((x, i) => ({ value: x, label: categoryPathName[i] || '' }));
            //标签 label labelIds
            const labelTexts = (data.label || '').split(',').filter(x => !!x);
            const labelIds = (data.labelIds || '').split(',').filter(x => !!x).map(x => parseInt(x));
            // 销售区域
            let saleAreaNodes = [];
            let saleAreaKeys = [];
            let rangeType = 2;
            (data.publishRangeList || []).forEach((x) => {
              rangeType = x.rangeType;
              if (x.sign === 1 || x.rangeType === 1) {
                saleAreaKeys.push(x.rangeId + '');
              }
              saleAreaNodes.push({
                name: x.rangeName,
                key: x.rangeId,
                rangeId: x.rangeId,
                rangeType: x.rangeType,
                sign: x.sign,
              });
            });
            // 规格属性
            const fields = {};
            let jsonObject = JSON.parse(data.jsonObject || '[]'); // 已选择的规格选项，控制页面展示
            let proSpecConfigVoList = data.proSpecConfigVoList || [];
            // 过滤掉接口返回的冗余字段
            // 此处因为 forEach 报错，所以改用 for 循环
            if (proSpecConfigVoList.length > 0) {
              for (let i = 0; i < proSpecConfigVoList.length; i++) {
                const tmp = {};
                Object.keys(proSpecConfigVoList[i]).filter(y => /^(id|specIds|specValues|specUnionKey|productCode|barCode|proType|description|price|suggestedRetailPrice|active)$/.test(y)).forEach(z => {
                  if (z === 'specIds') {
                    tmp[z] = proSpecConfigVoList[i][z].split(',').filter(x => !!x).map(x => parseInt(x));
                  } else if (z === 'specValues') {
                    tmp[z] = proSpecConfigVoList[i][z].split(',').filter(x => !!x);
                  } else if (z === 'specUnionKey') {
                    let specUnionKey = proSpecConfigVoList[i][z];
                    specUnionKey.split(';').filter(x => !!x).forEach(y => {
                      const s = y.split(':');
                      if (s && s.length === 2) {
                        tmp[s[0]] = s[1];
                      }
                    });
                  } else {
                    tmp[z] = proSpecConfigVoList[i][z];
                  }
                });
                proSpecConfigVoList.splice(i, 1, tmp);
              }
            }
            // 组装规格属性列表，参考 addRow 函数
            let specNames = [];
            (proSpecConfigVoList || []).forEach(x => {
              Object.keys(x).forEach(y => {
                if (/^(specIds|specValues|productCode|barCode|proType|description|price|suggestedRetailPrice|active)$/.test(y)) {
                  fields[`proSpecConfigVoList@${x.id}@${y}`] = y === 'active' ? !!x[y] : x[y];
                } else if (y !== 'id') {
                  fields[`proSpecConfigVoList@${x.id}@specUnionKey@${y}`] = x[y];
                  specNames.push(y);
                }
              });
            });
            specNames = Array.from(new Set(specNames)); // 去重
            setPrefixColumns(specNames.map(x => ({
              title: x,
              dataIndex: x,
              render: (val, record, index) =>
                <Form.Item name={`proSpecConfigVoList@${record.id}@specUnionKey@${x}`}
                           initialValue={val}>
                  <Input disabled style={{ width: '100%' }} />
                </Form.Item>,
            })));
            setProSpecConfigVoList(proSpecConfigVoList);
            setBaseCategoryId(data.categoryId);
            setJsonObject(jsonObject);
            setDetailData(data);
            setMinimumOrderQuantityStep(data.minimumPacking);
            setRangeType(rangeType);
            setSaleAreaKeys(saleAreaKeys);
            setSaleAreaNodes(saleAreaNodes);
            fetchSpecListForDetail(jsonObject).then(() => {
              let proCatList = [];
              (data.productCategoryVos || []).forEach((x)=>{
                if(x!=null){
                  proCatList.push({
                    categorySetId: x.categorySetId,
                    categoryId: x.id
                  })
                }
              })
              window.setTimeout(() => {
                form.setFieldsValue({
                  ...fields,
                  orgName: data.orgName,
                  orgId: data.orgId,
                  name: data.name,
                  code: data.code,
                  transportFee: data.transportFee,
                  unitId: { value: data.unitId, label: data.unitText }, // data.unitText
                  weight: data.weight,
                  weightUnitId: { value: data.weightUnitId, label: data.weigthtUnitText }, // weigthtUnitText
                  minimumOrderQuantity: data.minimumOrderQuantity,
                  minimumPacking: data.minimumPacking,
                  showPrice: data.showPrice,
                  suggestedPrice: data.suggestedPrice,
                  typeId: !!data.typeId && !!data.productCategoryTypeName ? { value: data.typeId, label: data.productCategoryTypeName } : undefined, // data.typeId
                  brandId: { value: data.brandId, label: data.brand }, // data.brandId,
                  barCode: data.barCode,
                  listingTime: data.listingTime ? moment(data.listingTime, dateFormat) : undefined,
                  placeOfOrigin: data.placeOfOrigin,
                  keyword: data.keyword,
                  labelIds: labelIds.map((x, index) => ({ value: x, label: labelTexts[index] })),
                  bathEnable: !!data.bathEnable ? 1 : 0,
                  indate: data.indate,
                  serialNumberEnable: !!data.serialNumberEnable ? 1 : 0,
                  active: !!data.active ? 1 : 0,
                  // categoryId: { value: data.categoryId, label: data.categoryText },
                  categoryId: categoryPath,
                  saleArea: (data.publishRangeList || []).map(x => x.rangeName).join(';'),
                  proOtherCategoryList: proCatList,
                  picIds: (data.picUrls || []).map((x, index) => ({
                    uid: `${(-1 - index)}`,
                    name: x.orginalName,
                    status: 'done',
                    fileId: x.id,
                    url: x.filePath,
                  })), // 主图
                  picId: data.picId && data.thumbnailUrl ? [
                    {
                      uid: '-1',
                      name: 'image.png',
                      status: 'done',
                      fileId: data.picId,
                      url: data.thumbnailUrl,
                    },
                  ] : undefined, // 产品缩略图
                  graphicDetails: BraftEditor.createEditorState(data.graphicDetails || null),
                  parameter: BraftEditor.createEditorState(data.parameter || null),
                });
              }, 0);
            });
          } else {
            console.error(res.msg);
          }
        });
        controllerDMS.order.productIsEdit({ productId: id }).then(res => {
          if (res.status) {
            // 取data 里的值，true 表示能编辑，false 表示不能编辑；
            setDisableBtnFlag(!res.data);
          }
        });
      }
    };
    fetchDetail();
  }, [detailData, fetchFlag, disableBtnFlag, form, id]);
  const onFinish = values => {
    let specConfigList = {};
    Object.keys(values).filter(x => x.indexOf('proSpecConfigVoList@') > -1).forEach(x => {
      const s = x.split('@');
      const id = s[1];
      let field = x.indexOf('specUnionKey') > -1 ? s[3] : s[2];
      if (x.indexOf('specUnionKey') > -1) {
        const arr = specConfigList[id] && specConfigList[id].specUnionKey ? specConfigList[id].specUnionKey : [];
        specConfigList[id] = {
          ...specConfigList[id],
          specUnionKey: [...arr, `${field}:${values[x]}`], //将多个规格值合并在一起
        };
      } else if (x.indexOf('specId') > -1) {
        const arr = specConfigList[id] && specConfigList[id].specId ? specConfigList[id].specId : [];
        specConfigList[id] = {
          ...specConfigList[id],
          specId: [...arr, ...values[x]], //将多个规格id合并在一起
        };
      } else {
        specConfigList[id] = {
          ...specConfigList[id],
          [field]: values[x],
        };
      }
    });
    specConfigList = Object.keys(specConfigList).map(key => ({
      ...specConfigList[key],
      id: (typeof key === 'string' && key.indexOf('TEMPID_') > -1) ? undefined : key,
    })).map(item => ({
      ...item,
      specUnionKey: (item.specUnionKey || []).join(';'), // 将多个规格值合并在一起，如：尺寸:L;颜色:绿色
      specValues: (item.specValues || []).join(),
      specIds: (item.specId || []).sort().join(),
      active: !!item.active ? 1 : 0,
    }));
    // if(!specConfigList.length) {
    //   message.error('请设置规格属性');
    //   return;
    // }
    console.log('debug saleAreaNodes:', saleAreaNodes);
    const lastCategory = values.categoryId && values.categoryId.length ? [...values.categoryId].pop() : {};
    const params = {
      orgName: values.orgName,
      orgId: values.orgId,
      name: values.name,
      code: values.code,
      transportFee: values.transportFee,
      unitId: !!values.unitId && values.unitId.value ? values.unitId.value : '',
      unitText: !!values.unitId && values.unitId.label ? values.unitId.label : '',
      weight: values.weight || '',
      weightUnitId: !!values.weightUnitId && values.weightUnitId.value ? values.weightUnitId.value : '',
      weigthtUnitText: !!values.weightUnitId && values.weightUnitId.label ? values.weightUnitId.label : '',
      minimumOrderQuantity: values.minimumOrderQuantity || '',
      minimumPacking: values.minimumPacking || '',
      showPrice: values.showPrice || '',
      suggestedPrice: values.suggestedPrice || '',
      typeId: !!values.typeId && values.typeId.value ? values.typeId.value : '',
      typeName: !!values.typeId && values.typeId.label ? values.typeId.label : '',
      brandId: !!values.brandId && values.brandId.value ? values.brandId.value : '',
      brand: !!values.brandId && values.brandId.label ? values.brandId.label : '',
      barCode: values.barCode || '',
      listingTime: values.listingTime ? moment(values.listingTime).format(dateFormat) : '',
      placeOfOrigin: values.placeOfOrigin || '',
      keyword: values.keyword || '',
      labelIds: (values.labelIds || []).map(x => x.value).join(),
      label: (values.labelIds || []).map(x => x.label).join(),
      bathEnable: !!values.bathEnable ? 1 : 0,
      indate: values.indate || '',
      serialNumberEnable: !!values.serialNumberEnable ? 1 : 0,
      active: !!values.active ? 1 : 0,
      categoryId: lastCategory.value || '',
      categoryText: !!values.categoryId && values.categoryId.map(x => x.label).join(),
      // 'saleArea': '南山区;华北战区;南头',
      publishRangeList: saleAreaNodes,
      proOtherCategoryList: values.proOtherCategoryList,
      picIds: values.picIds && !!values.picIds.length ? values.picIds.map(x => x.fileId) : [], // 主图ids
      picId: values.picId && !!values.picId.length ? values.picId.map(x => x.fileId)[0] : '', // 略缩图id
      thumbnailUrl: values.picId && !!values.picId.length ? values.picId.map(x => x.url)[0] : '', // 产品缩略图url
      graphicDetails: values.graphicDetails.toHTML(),
      parameter: values.parameter.toHTML(),
      proSpecConfigVoList: specConfigList,
      jsonObject: JSON.stringify((selectSpec || []).map(x => ({
        text: x.name,
        specId: x.id,
        data: x.specValueList.filter(y => !!y.checked).map((z, i) => {
          return {
            id: z.id,
            selected: true,
            text: z.specValue,
          };
        }),
      })).filter(x => !!x.data.length)),
    };
    console.log('Success params:', params);
    params.id = id || undefined;
    const fetchAPI = !!id ? controllerProdCenter.product.update : controllerProdCenter.product.create;
    fetchAPI(params).then(res => {
      if (res.code === __SUCCESS200__) {
        goBack();
      } else {
        message.error(res.message);
      }
    });
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
    if (errorInfo.errorFields && errorInfo.errorFields.length) {
      message.error('请检查各页签中的必填字段');
    }
  };
  const onCancel = () => {
    goBack();
  };
  const changeEditFlag = () => {
    setEditFlag(!editFlag);
    setTitle(editFlag ? '商品详情' : '编辑商品');
    setFetchFlag(editFlag);
  };
  const goBack = () => {
    if (id > 0 && editFlag) {
      setEditFlag(false);
      setTitle('商品详情');
      setFetchFlag(false);
    } else {
      history.go(-1);
    }
  };
  const rightTopBtns = <>
    {!editFlag && <Button size="small" onClick={goBack}>返回</Button>}&emsp;
    {!!id && <><Button size="small" onClick={changeEditFlag}>{editFlag ? '取消' : '编辑'}</Button>&emsp;</>}
  </>;
  const openModal = () => {
    form.validateFields(['categoryId']).then(values => {
      if (values.categoryId) {
        const tmp = values.categoryId[values.categoryId.length - 1].value;
        setBaseCategoryId(tmp);
        modalRef1.current.showModal(selectSpec, { productTypeId: tmp });
      } else {
        message.error('请选择商品基本类别');
      }
    }).catch(errorInfo => {
      (errorInfo.errorFields || []).forEach(x => {
        x.errors.forEach(y => {
          message.error(y);
        });
      });
    });
  };
  const rawColumns = [
    {
      title: <span>{!!editFlag && <span className="my-require">* </span>}产品编码</span>,
      dataIndex: 'productCode',
      render: (val, record, index) => <Form.Item
        initialValue={val}
        name={`proSpecConfigVoList@${record.id}@productCode`}
        rules={[{ required: true, message: '请输入产品编码' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>,
    },
    {
      title: '产品型号',
      dataIndex: 'proType',
      render: (val, record, index) => <Form.Item
        initialValue={val}
        name={`proSpecConfigVoList@${record.id}@proType`}
        rules={[{ required: false, message: '请输入产品型号' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>,
    },
    {
      title: '物料描述',
      dataIndex: 'description',
      render: (val, record, index) => <Form.Item
        initialValue={val}
        name={`proSpecConfigVoList@${record.id}@description`}
        rules={[{ required: false, message: '请输入物料描述' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>,
    },
    {
      title: '条形码',
      dataIndex: 'barCode',
      render: (val, record, index) => <Form.Item
        initialValue={val}
        name={`proSpecConfigVoList@${record.id}@barCode`}
        rules={[{ required: false, message: '请输入条形码' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>,
    },
    {
      title: <span>{!!editFlag && <span className="my-require">* </span>}产品标价</span>,
      dataIndex: 'price',
      render: (val, record, index) => <Form.Item
        initialValue={val}
        name={`proSpecConfigVoList@${record.id}@price`}
        rules={[{ required: true, message: '请输入产品标价' }]}
      >
        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
      </Form.Item>,
    },
    {
      title: <span>{!!editFlag && <span className="my-require">* </span>}建议零售价</span>,
      dataIndex: 'suggestedRetailPrice',
      render: (val, record, index) => <Form.Item
        initialValue={val}
        name={`proSpecConfigVoList@${record.id}@suggestedRetailPrice`}
        // rules={[{ required: true, message: '请输入建议零售价' }]}
      >
        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
      </Form.Item>,
    },
    {
      title: '上架',
      dataIndex: 'active',
      width: 60,
      fixed: 'right',
      render: (val, record, index) => <>
        <Form.Item
          key={`proSpecConfigVoList@${record.id}@active`}
          name={`proSpecConfigVoList@${record.id}@active`}
          valuePropName="checked"
          initialValue={val}
          rules={[{ required: false, message: '请设置上架状态' }]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item hidden
                   key={`proSpecConfigVoList@${record.id}@specIds`}
                   name={`proSpecConfigVoList@${record.id}@specIds`}
                   initialValue={record.specIds}>
          <Input disabled style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item hidden
                   key={`proSpecConfigVoList@${record.id}@specValues`}
                   name={`proSpecConfigVoList@${record.id}@specValues`}
                   initialValue={record.specValues}>
          <Input disabled style={{ width: '100%' }} />
        </Form.Item>
      </>,
    },
  ];
  const getSpecMatrix = (rawList, specList) => {
    if (!specList.length) {
      return rawList;
    }
    let specSet = specList.shift();
    if (!specSet.data || !specSet.data.length) {
      return rawList;
    }
    let m = rawList.length;
    let n = specSet.data.length;
    let result = [];
    for (let i = 0; i < m; i++) {
      for (let j = 0; j < n; j++) {
        const idArr = rawList[i].specIds ? [...rawList[i].specIds] : [];
        const valArr = rawList[i].specValues ? [...rawList[i].specValues] : [];
        if (!idArr.includes(specSet.data[j].id)) idArr.push(specSet.data[j].id);
        if (!valArr.includes(specSet.data[j].text)) valArr.push(specSet.data[j].text);
        result.push({
          ...rawList[i],
          id: j === 0 && rawList[i].id ? rawList[i].id : `TEMPID_${Math.random().toString()}`,
          // id: `TEMPID_${Math.random().toString()}`,
          [specSet.text]: specSet.data[j].text,
          specIds: idArr,
          specValues: valArr,
        });
      }
    }
    return getSpecMatrix(result, specList);
  };
  const megerData = (oldData, specArr) => {
    if (!oldData || !oldData.length) return [];
    if (!specArr || !specArr.length) return [];
    let oldSpecKeys = Object.keys(oldData[0]).filter(key => !/^(id|specIds|specValues|specUnionKey|productCode|barCode|proType|description|price|suggestedRetailPrice|active)$/.test(key));
    let oldSpecArr = []; //specArr.filter(x=>oldData[0].specIds.includes(x.specId));
    specArr.forEach(x => {
      if (x.data.some(y => oldData[0].specIds.includes(y.id))) {
        oldSpecArr.push(x);
      }
    });
    let newSpecArr = [];//specArr.filter(x=>!oldData[0].specIds.includes(x.specId));
    specArr.forEach(x => {
      if (x.data.every(y => !oldData[0].specIds.includes(y.id))) {
        newSpecArr.push(x);
      }
    });
    let newData = [];
    oldData.forEach(x => {
      // 删除已取消的规格行
      if (oldSpecArr.every(y => x.specValues.some(z => y.data.some(z1 => z1.text === z)))) {
        newData.push(x);
      }
    });
    // 过滤掉规格值已清空的行
    //oldData = oldSpecKeys.every(x=>specArr.some(y=>y.name === x)) ? [...newData] : [];
    oldData = oldSpecKeys.every(x => specArr.some(y => y.text === x)) ? [...newData] : [];
    newData = [];
    oldData.forEach(x => { // 旧数据与新规格项组合
      newData.push(getSpecMatrix([x], [...newSpecArr]));
    });
    newData = [].concat(...newData);
    console.log('newData: ', newData);
    return newData;
  };
  const addRow = (specArr) => {
    const fileds = [];
    proSpecConfigVoList.forEach(x => {
      Object.keys(x).forEach(y => {
        if (/^(specIds|specValues|productCode|barCode|proType|description|price|suggestedRetailPrice|active)$/.test(y)) {
          fileds.push(`proSpecConfigVoList@${x.id}@${y}`);
        } else if (y !== 'specUnionKey' && y !== 'id' && y !== 'ids') {
          fileds.push(`proSpecConfigVoList@${x.id}@specUnionKey@${y}`);
        }
      });
    });
    const tmp = form.getFieldsValue(fileds);
    let oldData = {}; // 页面上已经存在的数据
    Object.keys(tmp).forEach(x => {
      let s = x.split('@');
      let id = s[1];
      let key = x.indexOf('specUnionKey') > -1 ? s[3] : s[2];
      if (oldData[id]) {
        oldData[id] = {
          ...oldData[id],
          [key]: tmp[x],
        };
      } else {
        oldData[id] = {
          [key]: tmp[x],
        };
      }
    });
    oldData = Object.keys(oldData).map(x => ({ ...oldData[x], id: x }));
    const newData = megerData(oldData, [...specArr]); // 整合旧数据
    const newDataSpecValue = newData.map(item => {
      let tmp = item.specValues.sort((a, b) => {
        let codeA = a.charCodeAt(0);
        let codeB = b.charCodeAt(0);
        return codeA - codeB;
      });
      return JSON.stringify(tmp);
    });
    const rawArr = [
      {
        specUnionKey: '',
        productCode: form.getFieldValue('code') || undefined,
        proType: '',
        description: '',
        barCode: form.getFieldValue('barCode') || undefined,
        price: form.getFieldValue('showPrice') || undefined,
        suggestedRetailPrice: form.getFieldValue('suggestedPrice') || undefined,
        active: true, // 1已上架、0已下架
      },
    ];
    const matrixArr = !!specArr.length && !!rawArr.length ? getSpecMatrix(rawArr, [...specArr]) : []; // 全部组合
    const matrixArrFilter = [];
    // 过滤掉包含旧数据的组合
    matrixArr.forEach(item => {
      let tmp = item.specValues.sort((a, b) => {
        let codeA = a.charCodeAt(0);
        let codeB = b.charCodeAt(0);
        return codeA - codeB;
      });
      tmp = JSON.stringify(tmp);
      if (newDataSpecValue.indexOf(tmp) === -1) {
        matrixArrFilter.push(item);
      }
    });
    const fields = {};
    const result = [].concat(newData, matrixArrFilter);
    result.forEach(x => {
      Object.keys(x).forEach(key => {
        if (/id|specUnionKey|specIds|specValues|productCode|barCode|proType|description|price|suggestedRetailPrice|active/.test(key)) {
          if (key !== 'id' && key !== 'specUnionKey') {
            fields[`proSpecConfigVoList@${x.id}@${key}`] = x[key];
          }
        } else {
          fields[`proSpecConfigVoList@${x.id}@specUnionKey@${key}`] = x[key];
        }
      });
    });
    setPrefixColumns((specArr || []).map(x => {
      return {
        title: x.text,
        dataIndex: x.text,
        render: (val, record, index) => {
          return (
            <Form.Item name={`proSpecConfigVoList@${record.id}@specUnionKey@${x.text}`}
                       initialValue={val}>
              <Input disabled style={{ width: '100%' }} />
            </Form.Item>);
        },
      };
    }));
    setProSpecConfigVoList(result); // 动态设置规格属性列表的 column
    window.setTimeout(() => {
      form.setFieldsValue(fields);
    }, 100);
  };
  const specCheckHandel = (checkedSpecValues, specId) => {
    const arr = [...selectSpec];
    const index = arr.findIndex(x => x.id === specId);
    arr.splice(index, 1, {
      ...arr[index],
      specValueList: arr[index].specValueList.map(x => ({
        ...x,
        checked: checkedSpecValues.includes(x.specValue),
      })),
    });
    setSelectSpec(arr);
    const specTableRow = arr.map(x => ({
      text: x.name,
      specId: x.id,
      data: x.specValueList.filter(y => !!y.checked).map(z => {
        return {
          id: z.id,
          selected: true,
          text: z.specValue,
        };
      }),
    })).filter(x => !!x.data.length);
    addRow(specTableRow);
  };
  const getNewColumns = () => {
    return [].concat(prefixColumns, rawColumns); // [...prefixColumns, ...rawColumns];
  };
  const otherCategoryListChange = (e, index) => {
    const tmp = form.getFieldsValue();
    form.setFieldsValue({
      ...tmp,
      proOtherCategoryList: (tmp.proOtherCategoryList || []).map((x, i) => ({
        ...x,
        categoryId: index === i ? undefined : x.categoryId,
      })),
    });
    return e.target ? e.target.value : e;
  };
  const getCategorySetId = (index) => {
    const tmp = form ? form.getFieldsValue() : {};
    return tmp.proOtherCategoryList && tmp.proOtherCategoryList.length > 0 && tmp.proOtherCategoryList[index] ? tmp.proOtherCategoryList[index].categorySetId || undefined : undefined;
  };
  const tableProps = {
    rowKey: 'id',
    pagination: false,
    scroll: { y: 600, x: 1300 },
    locale: {
      emptyText: !!selectSpec.length ? '请选择规格属性' : '请选择规格',
    },
    columns: getNewColumns(),
    dataSource: proSpecConfigVoList,
  };
  const baseCategoryTreeProps = {
    treeType: 'category',
    keyField: 'id',
    titelField: 'name',
    labelInValue: true,
    params: { categorySetCode: 'PRO_BASE_CLASS' },
    antdCascaderProps: {
      disabled: !!id,
      placeholder: '请选择商品基本类别',
    },
  };
  const baseCategoryIdChangeHandle = (e) => {
    let tmp = e && Array.isArray(e) && !!e.length ? e[e.length - 1].value : '';
    if (tmp !== baseCategoryId) {
      setSelectSpec([]);
      setPrefixColumns([]);
      setProSpecConfigVoList([]);
    }
    return e;
  };
  const baseCategoryTreeProps2 = {
    treeType: 'category',
    keyField: 'id',
    titelField: 'name',
    params: { categorySetId: 2 },
  };
  // const productTypeSelectProps = {
  //   dataType: 'productType',
  //   params: { flag: 1 },
  // };
  const brandSelectProps = {
    dataType: 'brand',
  };
  const categorySetSelectProps = {
    dataType: 'categorySet',
    params: { name: '', type: 1, p: 1, s: 10000 },
    antdSelectProps: {
      style: { width: '100%' },
    },
  };
  const disableKeys = !!id ? jsonObject.map(x => x.specId) : undefined; // 已选择的规格选项，编辑时禁止取消
  const tableModalProps = {
    rowKey: 'id',
    selectType: 'checkbox',
    title: '选择规格',
    searchField: 'name',
    disableKeys: disableKeys, // 已选择的规格选项，编辑时禁止取消
    listApi: controllerProdCenter.spec.list,
    params: { code: '', name: '', productTypeId: baseCategoryId, active: undefined, p: 1, s: 10 },
    antdModalProps: {
      width: 800,
    },
    onOk: (selectRows) => {
      const list = (selectRows || []).map(x => {
        let index = (jsonObject || []).findIndex(y => y.specId === x.id);
        if (index > -1) {
          let specValues = jsonObject[index].data;
          return {
            ...x,
            specValueList: (x.specValueList || []).map(y => ({
              ...y,
              // checked: specValues.includes(y.specValue),
              // disabled: specValues.includes(y.specValue)
              // jsonobject 结构调整
              checked: specValues.some(z => z.id == y.id),
              disabled: specValues.some(z => z.id == y.id),
            })),
          };
        }
        return x;
      });
      setSelectSpec(list);
    },
    tableColumns: [
      {
        title: '规格名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '商品分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
      },
      {
        title: '有效性',
        dataIndex: 'active',
        key: 'active',
        width: 80,
        render: (val) => (val === 1 ? '启用' : '禁用'),
      },
      {
        title: '规格值',
        dataIndex: 'specValueList',
        key: 'specValueList',
        render: (val) => (val || []).map(x => x.specValue).join(),
      },
    ],
  };
  const selectRangeProps = {
    title: '销售区域',
    rangeType: rangeType,
    nodes: saleAreaNodes,
    keys: saleAreaKeys,
    onOk: (keys, nodes) => {
      const saleArea = nodes.map(x => x.name).join(';');
      setSaleAreaKeys(keys);
      setSaleAreaNodes(nodes);
      form.setFieldsValue({
        saleArea: saleArea,
      });
    },
  };
  const editorMedia = {
    uploadFn: BraftEditorUploadFn,
    accepts: {
      // image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg',
      image: 'image/png,image/jpeg',
      video: false, // 'video/mp4',
      audio: false, // 'audio/mp3',
    },
  };
  const myUploadProps = {
    maxWidth: 360,
    maxHeight: 235,
    maxSize: 1,
    maxCount: 1,
    antdUploadProps: {
      listType: 'picture',
      showUploadList: { showRemoveIcon: editFlag },
    },
  };
  const minimumPackingChange = (e) => {
    form.setFieldsValue({minimumOrderQuantity: e});
    setMinimumOrderQuantityStep(e)
    return e
  }
  return <Fragment>
    <div className="app-body">
      <Tabs defaultActiveKey="1" className="gray" tabBarExtraContent={rightTopBtns}>
        <TabPane tab={<span>&emsp;{title}&emsp;</span>} key="1" />
      </Tabs>
      <div className="form-box-lg">
        {/* layout="vertical" { categorySetId: '', categoryId: '' }*/}
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{
            orgName: dmsUserInfo.currentOrgName,
            orgId: dmsUserInfo.currentOrgId,
            active: true,
            minimumPacking: 1,
            minimumOrderQuantity: 1,
            proOtherCategoryList: [],
            parameter: BraftEditor.createEditorState(null),
            graphicDetails: BraftEditor.createEditorState(null),
          }}
          hideRequiredMark={!editFlag}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Tabs defaultActiveKey="baseInfo" className="gray mb0">
            <TabPane tab={<span>&emsp;基础信息&emsp;</span>} forceRender style={{position: 'relative'}} key="baseInfo">
              {!editFlag && <div className="mask">&nbsp;</div>}
              <Row gutter={16} className="mt20">
                <Col span={12}>
                  <Form.Item
                    label="所属组织"
                    name="orgName"
                    rules={[
                      { required: true, message: '当前登录账号没有所属组织' },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="所属组织"
                    name="orgId"
                    hidden
                    rules={[
                      { required: true, message: '请选择所属组织' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {/* <Form.Item
                    label="产品类型"
                    name="typeId"
                    rules={[{ required: true, message: '请选择产品类型' }]}
                  >
                    <MyListSelect {...productTypeSelectProps} antdSelectProps={antdSelectProps} />
                  </Form.Item> */}
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="商品名称"
                    name="name"
                    rules={[
                      { required: true, message: '请输入商品名称' },
                      { type: 'string', max: 50, message: '不超过50个字符' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="商品编码"
                    name="code"
                    rules={[
                      { required: true, message: '请输入商品编码' },
                      { type: 'string', max: 20, message: '不超过20个字符' },
                    ]}
                  >
                    <Input disabled={!!id} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="单位"
                    name="unitId"
                    rules={[{ required: true, message: '请选择单位' }]}
                  >
                    <MyListSelect antdSelectProps={{...antdSelectProps,  disabled:!!id}} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="单位重量"
                    name="weight"
                    rules={[
                      { required: false, message: '请输入单位重量' },
                    ]}
                  >
                    <InputNumber min={0} step={1} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="重量单位"
                    name="weightUnitId"
                    rules={[
                      { required: false, message: '请输入重量单位' },
                    ]}
                  >
                    <MyListSelect antdSelectProps={antdSelectProps} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="起订量"
                    name="minimumOrderQuantity"
                    rules={[{ required: true, message: '请输入起订量' }]}
                  >
                    <InputNumber step={minimumOrderQuantityStep} min={minimumOrderQuantityStep} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="最小包装量"
                    name="minimumPacking"
                    getValueFromEvent={minimumPackingChange}
                    rules={[{ required: true, message: '请输入最小包装量' }]}
                  >
                    <InputNumber step={1} min={1} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="产品标价"
                    name="showPrice"
                    rules={[
                      { required: true, message: '请输入产品标价' },
                    ]}
                  >
                    <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="建议零售价"
                    name="suggestedPrice"
                    // rules={[
                    //   { required: true, message: '请输入建议零售价' },
                    // ]}
                  >
                    <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="品牌"
                    name="brandId"
                    rules={[{ required: false, message: '请选择品牌' }]}
                  >
                    <MyListSelect {...brandSelectProps} antdSelectProps={antdSelectProps} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="条形码"
                    name="barCode"
                    rules={[
                      { required: false, message: '请输入条形码' },
                      { type: 'string', max: 50, message: '不超过50个字符' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="上市时间"
                    name="listingTime"
                    rules={[
                      { required: false, message: '请输入上市时间' },
                    ]}
                  >
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="产地"
                    name="placeOfOrigin"
                    rules={[
                      { required: false, message: '请输入产地' },
                      { type: 'string', max: 100, message: '不超过100个字符' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="关键字"
                    name="keyword"
                    rules={[
                      { required: false, message: '请输入关键字' },
                      { type: 'string', max: 100, message: '不超过100个字符' },
                    ]}
                  >
                    <Input placeholder="请输入关键字，多个请用分号隔开" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="商品标签"
                    name="labelIds"
                    rules={[
                      { required: false, message: '请选择商品标签' },
                    ]}
                  >
                    {/*<MyTreeSelect {...labelTreeProps} />*/}
                    <StrangeTreeSelect antdTreeSelectProps={{...antdTreeSelectProps, treeCheckable: false, multiple: false}} />
                  </Form.Item>
                </Col>
                <Col span={6} >
                  <Form.Item
                    {...layout2}
                    label="启用批次"
                    name="bathEnable"
                    valuePropName="checked"
                    rules={[{ required: false, message: '请输入商品名称' }]}
                  >
                    <Switch checkedChildren="是" disabled={disableBtnFlag} unCheckedChildren="否" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="有效期">
                    <Form.Item
                      name="indate"
                      noStyle
                      rules={[{ required: false, message: '请输入有效期' }]}
                    >
                      <InputNumber min={0} step={1} />
                    </Form.Item>
                    <span className="ant-form-text"> 天</span>
                  </Form.Item>
                </Col>
                <Col span={6} >
                  <Form.Item
                    {...layout2}
                    label="启用序列号"
                    name="serialNumberEnable"
                    valuePropName="checked"
                    rules={[{ required: false, message: '请输入商品名称' }]}
                  >
                    <Switch checkedChildren="是" disabled={disableBtnFlag} unCheckedChildren="否" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...layout2}
                    label="上下架"
                    name="active"
                    valuePropName="checked"
                    rules={[{ required: true, message: '请选择上下架' }]}
                  >
                    <Switch checkedChildren="上架" unCheckedChildren="下架" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...layout2}
                    label="零担费"
                    name="transportFee"
                    rules={[{ required: false, message: '请输入零担费' }]}
                  >
                    <InputNumber precision={2} min={0} step={1}  style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label="商品基本类别"
                    name="categoryId"
                    rules={[
                      { required: true, message: '请选择商品基本类别' },
                    ]}
                    getValueFromEvent={baseCategoryIdChangeHandle}
                  >
                    <MyCascader {...baseCategoryTreeProps} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label="销售区域授权"
                    name="saleArea"
                    rules={[
                      { required: true, message: '请选择销售区域授权' },
                    ]}
                  >
                    <Input placeholder="请选择销售区域授权" readOnly={true}
                           onClick={() => modalRef2.current.showModal()} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                {...layout3}
                label="其他类别">
                <Form.List name="proOtherCategoryList">
                  {(fields, { add, remove }) => {
                    if (!fields || !fields.length) {
                      return <div style={otherStyle}>
                        <Row gutter={16}>
                          &emsp;
                          <PlusCircleOutlined
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                              add();
                            }}
                          />
                        </Row>
                      </div>;
                    }
                    return (
                      <div style={otherStyle}>
                        {fields.map((field, index, thisArr) => (
                          // <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="start">
                          <Row key={field.key} gutter={16}>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="分类集"
                                name={[field.name, 'categorySetId']}
                                fieldKey={[field.fieldKey, 'categorySetId']}
                                rules={[{ required: true, message: '请选择分类集' }]}
                                getValueFromEvent={(e) => otherCategoryListChange(e, index)}
                              >
                                <MyListSelect {...categorySetSelectProps} />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="商品类别"
                                name={[field.name, 'categoryId']}
                                fieldKey={[field.fieldKey, 'categoryId']}
                                dependencies={['categorySetId']}
                                rules={[{ required: true, message: '请选择商品类别' }]}
                              >
                                <MyTreeSelect {...{
                                  ...baseCategoryTreeProps2,
                                  params: { categorySetId: getCategorySetId(index) },
                                }} />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item label={null} colon={false}>
                                &emsp;
                                <MinusCircleOutlined
                                  style={{ fontSize: '20px' }}
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                                &emsp;
                                {(index === thisArr.length - 1) && <PlusCircleOutlined
                                  style={{ fontSize: '20px' }}
                                  onClick={() => {
                                    add();
                                  }}
                                />}
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    );
                  }}
                </Form.List>
              </Form.Item>

              <Tabs defaultActiveKey="SpecMatrix"
                    className="gray mb0"
                    tabBarExtraContent={<>{!!editFlag && !id &&
                    <Button size="small" onClick={openModal}>选规格</Button>}&emsp;</>}>
                <TabPane tab={<span>&emsp;规格属性&emsp;</span>} key="SpecMatrix" />
              </Tabs>
              {
                (selectSpec || []).map((x, index) => (
                  <Form.Item
                    key={x.id}
                    label={x.name}>
                    <Checkbox.Group
                      value={(x.specValueList || []).filter(y => y.checked).map(y => y.specValue)}
                      options={(x.specValueList || []).map(y => ({
                        label: y.specValue,
                        value: y.specValue,
                        disabled: y.disabled,
                      }))}
                      onChange={(val) => specCheckHandel(val, x.id)}
                    />
                  </Form.Item>
                ))
              }
              <Table {...tableProps} />
            </TabPane>
            <TabPane tab={<span>&emsp;图文详情&emsp;</span>} forceRender key="mediaInfo">
              {/*{!editFlag && <div className="mask">&nbsp;</div>}*/}
              <Form.Item
                {...layout3}
                name="picId"
                className="mt20"
                label="产品缩略图"
                rules={[
                  { required: true, message: '请上传产品缩略图' },
                ]}
                extra={editFlag ? '建议图片大小：360x235px；图片格式jpg、png、jpeg；图片大小小于1M' : null}
              >
                <MyUpload {...myUploadProps}>
                  {editFlag && <Button>
                    <UploadOutlined /> 点击上传
                  </Button>}
                </MyUpload>
              </Form.Item>

              <Form.Item
                {...layout3}
                name="picIds"
                label="产品主图"
                rules={[
                  { required: true, message: '请上传产品主图' },
                ]}
                extra={editFlag ? '最多可上传5张图片，建议图片大小：750x550px；图片格式jpg、png、jpeg；图片大小小于1M' : null}
              >
                <MyUpload {...{ ...myUploadProps, maxCount: 5, maxWidth: 750, maxHeight: 550 }}>
                  {editFlag && <Button>
                    <UploadOutlined /> 点击上传
                  </Button>}
                </MyUpload>
              </Form.Item>
              <div style={{ display: editFlag ? 'block' : 'none' }}>
                <Form.Item
                  {...layout3}
                  label="图文详情"
                  name="graphicDetails"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (value.isEmpty()) {
                          return Promise.reject('请输入图文详情');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <BraftEditor
                    className="my-editor"
                    media={editorMedia}
                    placeholder="请输入图文详情正文内容"
                  />
                </Form.Item>
              </div>

              <div style={{ display: !editFlag ? 'block' : 'none' }}>
                <Form.Item
                  {...layout3}
                  label="图文详情"
                >
                  <div className="my-editor">
                    <div style={{ padding: '15px' }}
                         dangerouslySetInnerHTML={{ __html: detailData.graphicDetails }}></div>
                  </div>
                </Form.Item>
              </div>
            </TabPane>
            <TabPane tab={<span>&emsp;商品参数&emsp;</span>} forceRender key="params">
              {/*{!editFlag && <div className="mask">&nbsp;</div>}*/}
              <div style={{ display: editFlag ? 'block' : 'none' }}>
                <Form.Item
                  {...layout3}
                  className="mt20"
                  label="商品参数"
                  name="parameter"
                  validateTrigger="onBlur"
                >
                  <BraftEditor
                    className="my-editor"
                    media={editorMedia}
                    placeholder="请输入商品参数正文内容"
                  />
                </Form.Item>
              </div>
              <div style={{ display: !editFlag ? 'block' : 'none' }}>
                <Form.Item
                  {...layout3}
                  className="mt20"
                  label="商品参数"
                >
                  <div className="my-editor">
                    <div style={{ padding: '15px' }} dangerouslySetInnerHTML={{ __html: detailData.parameter }}></div>
                  </div>
                </Form.Item>
              </div>
            </TabPane>
          </Tabs>
          {editFlag && <Row gutter={16} className="mt20">
            <Col span={12}>
              <Form.Item {...tailLayout}>
                <Button onClick={onCancel}>
                  取消
                </Button>
                <span>&emsp;&emsp;</span>
                <Button type="danger" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Col>
          </Row>}
        </Form>
      </div>
    </div>
    <TableModal ref={modalRef1} {...tableModalProps} />
    <SelectRange ref={modalRef2} {...selectRangeProps} />
  </Fragment>;
}

export default AppDetail;
