/**
 * dms-chan-controller : 渠道接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'treeList': { 'path': '/chan/api/v1/dms/area/treeList', 'method': 'GET', 'desc': '区域管理'},
  'getListToSelect': { 'path': '/chan/api/v2/dms/sysOrg/getListToSelect', 'method': 'POST', 'desc': '渠道列表下拉框使用' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params,
      };
      return request(options);
    };
  });
})();
export default controller;
