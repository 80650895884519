/**
 * dc-var-sort-controller : 全局分类调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'categoryTree': { 'path': '/var/v1/category/categoryTree', 'method': 'POST', 'desc': '获取分类树接口' },
  'delete': { 'path': '/var/v1/category/delete', 'method': 'DELETE', 'desc': '删除分类' },
  'detail': { 'path': '/var/v1/category/detail', 'method': 'POST', 'desc': '按ID获取分类详情' },
  'saveOrUpdate': { 'path': '/var/v1/category/saveOrUpdate', 'method': 'POST', 'desc': 'save' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
