import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  message,
  PageHeader,
} from 'antd';
import {
  TableView,
  TableModal,
  // ImportModal,
  MyTreeSelect,
} from 'components';
// import MerchandiseModal from './components/merchandiseModal';
import { DoubleRightOutlined } from '@ant-design/icons';
import { controllerProdCenter } from 'controller';
import { storage } from 'utils';

const Option = Select.Option;
const registerInfo = storage.get('registerInfo');
const dmsUserInfo = (registerInfo && registerInfo.dms) || {};

function MchManage() {
  // const modalRef1 = useRef(null);
  const modalRef2 = useRef(null);
  // const modalRef3 = useRef(null);
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState([]);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0); // dmsUserInfo.currentOrgId ||
  const [searchObj, setSearchObj] = useState({ name: '', code: '', categoryId: undefined, active: undefined, orgId: dmsUserInfo.currentOrgId || undefined, p: 1, s: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await controllerProdCenter.product.listPc(searchObj);
      setIsLoading(false);
      setList(res.data || []);
      setTotal(res.total || 0);
      res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    fetchData();
  }, [searchObj]);
  const handleClick = () => {
    setShowForm(!showForm);
  };
  const activeChangeHandle = (opt) => {
    if(!selectedRows.length) {
      message.warn('请先选择商品');
      return;
    }
    const params = {
      ids: selectedRows.map(x => x.id),
    }
    const fetchAPI = opt === 1 ? controllerProdCenter.product.up : controllerProdCenter.product.down
    fetchAPI(params).then(res => {
      if(res.code === __SUCCESS200__) {
        setSelectedRows([]);
        setSearchObj({
          ...searchObj,
        });
      }
      res.code !== __SUCCESS200__ && message.error(res.message)
    })
  }
  const pageChange = (page, pageSize) => {
    setSearchObj({
      ...searchObj,
      p: page,
      s: pageSize,
    });
  };
  const onSearch = (values) => {
    setSearchObj({
      ...searchObj,
      ...values,
      p: 1,
    });
  };
  const resetSearch = () => {
    setSearchObj(data => ({
      ...data,
      code: '',
      name: '',
      valid: undefined,
      page: 1,
    }));
    form.setFieldsValue({
      code: '',
      name: '',
      valid: undefined,
    });
  };
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };
  const tableProps = {
    rowKey: 'id',
    loading: isLoading,
    columns: [
      {
        title: '商品编码',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '基本商品类别',
        dataIndex: 'categoryText',
        key: 'categoryText',
      },
      {
        title: '单位',
        dataIndex: 'unitText',
      },
      {
        title: '标价',
        dataIndex: 'showPrice',
      },
      {
        title: '市场价',
        dataIndex: 'suggestedPrice',
      },
      {
        title: '状态',
        dataIndex: 'active',
        render: text => text === 0 ? '下架' : '上架',
      },
      {
        title: '操作',
        width: 100,
        align: 'center',
        render: (val, record, index) => <Fragment>
          {/*<Button type="link" href={`#/mchManage/${record.id}`}>编辑</Button>*/}
          <Button type="link" href={`#/mchManage/${record.id}`}>查看</Button>
        </Fragment>,
      },
    ],
    rows: list,
    pageParam: {
      total: total,
      current: searchObj.p,//当前页码
      pageSize: searchObj.s,//当前分页数
      onChange: pageChange,
    },
    rowSelection: {
      selectedRowKeys: selectedRows.map(x => x.id),
      onChange: onSelectChange,
    },
  };
  const baseCategoryTreeProps = {
    treeType: 'category',
    keyField: 'id',
    titelField: 'name',
    params: { categorySetCode: 'PRO_BASE_CLASS' },
  };
  // const orgTreeProps = {
  //   treeType: 'org',
  //   keyField: 'orgId',
  //   titelField: 'orgName',
  //   params: { orgId: dmsUserInfo.currentOrgId || 0 },
  // };
  const tableModalProps = {
    rowKey: 'rowkey',
    selectType: 'checkbox',
    title: '选择商品',
    searchField: 'kw',
    pageNoField: 'p',
    pageSizeField: 's',
    listApi: controllerProdCenter.product.listPcForChannel,
    processList: list => list.map(x => ({...x, rowkey: `${x.id}_abc`})),
    params: { kw: '', categoryId: undefined, active: 1, orgId: dmsUserInfo.superiorOrgId || undefined, p: 1, s: 10 },
    antdModalProps: {
      width: 1000
    },
    onOk: (selectRows) => {
      // controllerProdCenter.product.createForChannel
      setIsLoading(true);
      controllerProdCenter.product.createForChannel(selectRows).then(res => {
        message[res.code === 200 ? 'success' : 'error'](res.message);
        if(res.code === 200) {
          setSearchObj({...searchObj, p: 1});
        }
        setIsLoading(false);
      });
    },
    tableColumns: [
      {
        title: '商品编码',
        dataIndex: 'code',
        key: 'code',

      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '基本商品类别',
        dataIndex: 'categoryText',
        key: 'categoryText',
        width: 200
      },
    ],
  };
  return <Fragment>
    <div className="app-body">
      <PageHeader
        ghost={false}
        className="gray-header"
        title={<>
          {/*<Button type="gray" onClick={() => modalRef1.current.showModal()}>新增</Button>*/}
          {/* <Button type="gray" href="#/mchManage/0">新增</Button> */}
          &emsp;
          <Button type="gray" onClick={() => activeChangeHandle(1)}>商品上架</Button>
          &emsp;
          <Button type="gray" onClick={() => activeChangeHandle(0)}>商品下架</Button>
          &emsp;
          {/* <Button type="gray" onClick={() => modalRef2.current.showModal()}>载入上级商品</Button> */}
          {/*&emsp;
          <Button type="gray" onClick={() => modalRef3.current.showModal()}>商品导入</Button>*/}
          {/*&emsp;
          <Button type="gray">导出</Button>*/}
        </>}
        extra={[
          <Button className="pos-bottom"
                  key="1"
                  onClick={handleClick}
                  icon={<DoubleRightOutlined rotate={showForm ? 270 : 90} />}>{showForm ? '关闭查询' : '展开查询'}</Button>,
        ]}
      />
      <Form
        form={form}
        name="basic"
        className="top-search-box"
        style={{ display: showForm ? 'block' : 'none' }}
        initialValues={searchObj}
        onFinish={onSearch}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="商品名称"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="商品编码"
              name="code"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="商品类别"
              name="categoryId"
            >
              <MyTreeSelect {...baseCategoryTreeProps} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="状态"
              name="active"
            >
              <Select>
                <Option value={undefined}>全部</Option>
                <Option value={1}>上架</Option>
                <Option value={0}>下架</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item
              label="组织名称"
              name="orgId"
            >
              <MyTreeSelect {...orgTreeProps} />
            </Form.Item>
          </Col> */}
          <Col span={12}>&nbsp;</Col>
          <Col span={12} style={{ textAlign: 'right', marginBottom: '24px' }}>
            <Button type="danger" htmlType="submit">
              搜索
            </Button>
            &emsp;
            <Button onClick={resetSearch} style={{ margin: '0 8px' }}>
              重置
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="page-main-box">
        <TableView {...tableProps} />
      </div>
    </div>
    {/*<MerchandiseModal ref={modalRef1} refreshList={onSearch}></MerchandiseModal>*/}
    <TableModal ref={modalRef2} {...tableModalProps}></TableModal>
    {/*<ImportModal ref={modalRef3} refreshList={onSearch}></ImportModal>*/}
  </Fragment>;
}

export default MchManage;
