/**
 * role-controller : 云镖角色管理
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'addRole': { 'path': '/uc/role/v6/addRole', 'method': 'POST', 'desc': '添加角色' },
  'addRoleMember': { 'path': '/uc/role/v6/addRoleMember', 'method': 'POST', 'desc': '添加角色成员' },
  'deleteRole': { 'path': '/uc/role/v6/deleteRole', 'method': 'POST', 'desc': '删除校色软删除' },
  'getMxmRoleList': { 'path': '/uc/role/v6/getMxmRoleList', 'method': 'POST', 'desc': '获取角色列表' },
  'getRoleInfo': { 'path': '/uc/role/v6/getRoleInfo', 'method': 'POST', 'desc': '获取角色信息' },
  'getRoleManager': { 'path': '/uc/role/v6/getRoleManager', 'method': 'POST', 'desc': '查询角色管理' },
  'getRoleMember': { 'path': '/uc/role/v6/getRoleMember', 'method': 'POST', 'desc': '查询角色成员' },
  'listAllRoles': { 'path': '/uc/role/v6/listAllRoles', 'method': 'POST', 'desc': '获取所有角色列表' },
  'updateRole': { 'path': '/uc/role/v6/updateRole', 'method': 'POST', 'desc': '修改角色' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
