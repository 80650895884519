import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Modal,
  Divider,
  message,
} from 'antd';
import TableView from '../TableView';

const { Search } = Input;

function TableModal({ rowKey='id', searchField, pageNoField = 'p', pageSizeField = 's', disableKeys = [], selectType = 'checkbox', title, params, listApi, processList, tableColumnsMap, tableColumns, antdModalProps, antdTableProps, onOk }, ref) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchObj, setSearchObj] = useState(params);
  const [selectedRows, setSelectedRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fetching, setFetching] = useState(false);
  useImperativeHandle(ref, () => ({
    showModal: (selectedRows, apiParams) => {
      setSelectedRows(selectedRows || []);
      setFetching(true);
      if(apiParams && typeof apiParams === 'object') {
        setSearchObj({
          ...searchObj,
          ...apiParams,
        });
      }
      setVisible(true);
    },
  }));
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await listApi(searchObj);
      setIsLoading(false);
      setFetching(false);
      if(processList){
        setList(processList(res.data || []));

      } else  {

        setList(res.data || []);
      }
      setTotal(res.total || 0);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    if (visible && fetching) {
      fetchData();
    }
  }, [listApi, searchObj, visible, fetching, processList]);
  const onCancel = () => {
    setVisible(false);
  };
  const onFinish = () => {
    if (onOk) {
      onOk(selectedRows);
    }
    setVisible(false);
  };
  const pageChange = (page, pageSize) => {
    setFetching(true);
    setSearchObj({
      ...searchObj,
      [pageNoField]: page,
      [pageSizeField]: pageSize,
    });
  };
  const onSearch = (val) => {
    setFetching(true);
    setSearchObj({
      ...searchObj,
      [searchField]: val,
      [pageNoField]: 1,
    });
  };
  const onChangeHandle = (e) => {
    setSearchObj({
      ...searchObj,
      [searchField]: e.target.value,
    });
  }
  const modalProps = {
    title,
    visible,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
    ...antdModalProps,
  };
  const tableProps = {
    rowKey: rowKey,
    loading: isLoading,
    columns: tableColumns || (Object.keys(tableColumnsMap) || []).map(key => ({
      title: tableColumnsMap[key],
      dataIndex: key,
      key: key,
    })),
    rows: list,
    pageParam: {
      total: total,
      current: searchObj.p,//当前页码
      pageSize: searchObj.s,//当前分页数
      onChange: pageChange,
    },
    rowSelection: {
      type: selectType || 'checkbox', // checkbox：多选；radio：单选
      selectedRowKeys: selectedRows.map(x => x[rowKey]),
      getCheckboxProps: record => ({
        disabled: (disableKeys || []).includes(record[rowKey]),
      }),
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
      },
    },
    antdTableProps,
  };
  return <Modal {...modalProps}>
    <Search loading={isLoading}  value={searchObj[searchField]} onChange={onChangeHandle} onSearch={onSearch} />
    <Divider />
    <TableView {...tableProps} />
  </Modal>;
}

// title, params, searchField, rowKey, tableColumnsMap, tableColumns, antdModalProps, antdTableProps
TableModal.propTypes = {
  title: PropTypes.string.isRequired, // 标题
  params: PropTypes.object.isRequired, // 接口参数
  listApi: PropTypes.func.isRequired, // 列表接口
  searchField: PropTypes.string.isRequired, // 列表接口分页参数，搜索字段
  pageNoField: PropTypes.string, // 列表接口分页参数，页码
  pageSizeField: PropTypes.string, // 列表接口分页参数，每页记录数量
  rowKey: PropTypes.string, // antd Table rowKey，数据中值具有唯一性的字段，一般是"id"
  tableColumnsMap: function (props, propName, componentName) {
    if (props[propName] && props[propName].constructor.name !== 'Object') {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Validation failed.' +
        '这是一个说明字段与列的映射关系的Object，如：{name: \'客户名称\'，code: \'客户编码\'}',
      );
    }
    if (!props[propName] && !props['tableColumns']) {
      return new Error('请设置属性 `tableColumnsMap` 或 `tableColumns`。它们是用来设置 Ant Design Table 组件的 `Columns` 属性的。' +
        'tableColumnsMap 是一个Object，仅支持 Columns 的 `dataIndex`、`title`、`key`，' +
        'tableColumns 是一个Array，支持 Columns 的所有字段。');
    }
  }, // tableColumnsMap 和 tableColumns 两个参数二选一，tableColumnsMap 仅支持 antd Table Columns
     // 的'dataIndex'、'title'、'key'，这是一个字段与列的映射关系，如：{name: '客户名称'，code: '客户编码'}
  tableColumns: function (props, propName, componentName) {
    if (props[propName] && props[propName].constructor.name !== 'Array') {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Validation failed.' +
        '这是一个说明字段与列的映射关系的Array，如：[\n{\n' +
        '        title: \'代码\',\n' +
        '        dataIndex: \'code\',\n' +
        '        key: \'code\',\n' +
        '      }\n]',
      );
    }
    if (!props[propName] && !props['tableColumnsMap']) {
      return new Error('请设置属性 `tableColumnsMap` 或 `tableColumns`。它们是用来设置 Ant Design Table 组件的 `Columns` 属性的。' +
        'tableColumnsMap 是一个Object，仅支持 Columns 的 `dataIndex`、`title`、`key`，' +
        'tableColumns 是一个Array，支持 Columns 的所有字段。');
    }
  }, // tableColumnsMap 和 tableColumns 两个参数二选一，tableColumns 支持 antd Table Columns 的所有字段,
  antdModalProps: PropTypes.object, // antd TreeSelect 组件参数，参考：https://ant.design/components/tree-select-cn/#API
  antdTableProps: PropTypes.object,
  onOk: PropTypes.func,
  processList: PropTypes.func,
  disableKeys: PropTypes.array,
  selectType: PropTypes.string, // checkbox：多选；radio：单选
};
TableModal.defaultProps = {
  disableKeys: [],
  rowKey: 'id',
  pageNoField: 'p',
  pageSizeField: 's',
  selectType: 'checkbox', // checkbox：多选；radio：单选
};
export default forwardRef(TableModal);
// export default TableModal;
