/**
 * 登录
 */
import { routerRedux } from 'dva/router'
import controllerUser from 'controller/user';
import { storage } from 'utils';

export default {
  namespace: 'login',
  state: {
    // 用户信息
    loginInfo: {},
    // 验证码
    captcha: '',
    // 提示消息
    message: ''
  },
  effects: {
    * captchaFn({ payload }, { call, put }) {
      const captcha = controllerUser.getApi('captcha');
      yield put({
        type: 'updateState',
        payload: {
          captcha: `${captcha}?source=${payload.source}`
        },
      });
    },
    * loginFn({ payload }, { call, put }) {
      const res = yield call(controllerUser.login, payload);
      if (res.code === __SUCCESS__) {
        window.localStorage.setItem('cor-ticket', !!res.data && !!res.data.corTicket ? res.data.corTicket : '');
        window.localStorage.setItem('corTicket', !!res.data && !!res.data.corTicket ? res.data.corTicket : '');
        // 缓存用户登录信息
        storage.set('loginInfo', res.data);
        // 获取业务系统注册信息
        if(res.data.firstLogin == null || (res.data.firstLogin && res.data.firstLogin == 1)) {
          storage.set('loginInfo', res.data);
          return 'firstLogin';
        }else {
          const res2 = yield call(controllerUser.regist, {
            "userName": res.data.loginName
          });
          yield put({
            type: 'updateState',
            payload: {
              loginInfo: res.data,
              registerInfo: res2.data
            },
          });
          // 缓存用户登录信息
          storage.set('registerInfo', res2.data);
          // 登录成功跳转首页
          yield put(routerRedux.push('/home'));
        }
      } else {
        // 提示错误
        yield put({
          type: 'updateState',
          payload: {
            message: res.message || '登录失败，请联系管理员',
          },
        });
      }
    },
    * logoutFn({ payload }, { call, put }) {
      const response = yield call(controllerUser.logout, payload);
      if (response.code === __SUCCESS__) {
        yield put({
          type: 'updateState',
          payload: {
            loginInfo: {},
          },
        });
        storage.clear('loginInfo');
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    }
  },
};
