import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  message
} from 'antd';

import { controllerProdCenter,controllerCs } from 'controller';
import {
  //TableView,
  TableModal,
  // ImportModal,
  //MyTreeSelect,
} from 'components';
const { Option } = Select;
const { TextArea } = Input;
const { Search } = Input;

function SparebomModel({ refreshList }, ref) {
  const [detailData, setDetailData] = useState({});
  const [type, setType] = useState(1);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const modalRef4 = useRef(null);
  const tableModalProps = {
    rowKey: 'rowkey',
    selectType: 'radio',
    title: '选择商品',
    searchField: 'filter',
    pageNoField: 'p',
    pageSizeField: 's',
    placeholder:'请输入产品名称',
    antdModalProps: {
      width: 1200
    },
    listApi: controllerProdCenter.product.proSpecConfigList,
    processList: list => list.map(x => ({...x, rowkey: `${x.id}_abc`})),
    params: { kw: '', categoryId: undefined, active: 1,  p: 1, s: 10 },
    onOk: (selectRows) => {
      // controllerProdCenter.product.createForChannel
      let selectRow = selectRows[0];
      form.setFieldsValue({
        'proSpecId':selectRow.id,
        'productId':selectRow.productId, 
        'proTypeId':selectRow.productTypeId, 
        'productName': selectRow.productName,
        'specUnionKey': selectRow.specUnionKey 
      });
    },
    tableColumns: [
      {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: '产品规格',
        dataIndex: 'specUnionKey',
        key: 'specUnionKey',
      },
      {
        title: '产品单位',
        dataIndex: 'unit',
        key: 'unit',
        width: 120
      },
      {
        title: '单价（元）',
        dataIndex: 'price',
        key: 'price',
        width: 120
      },
    ]         
};
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setType(data.type);
      setDetailData(data || {});
      if (data && data.id) {
        form.setFieldsValue({
          proSpecId: data.proSpecId || undefined,
          productId: data.productId || undefined,
          proTypeId: data.proTypeId || undefined,
          productName: data.productName || undefined,
          specUnionKey: data.proSpecName || undefined,
          active: data.active ,
          remark: data.remark || '',
        });
      }
      console.log(form.getFieldsValue());
    },
  }));
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const params = {
        proSpecId: form.getFieldValue('proSpecId'),
        productId: form.getFieldValue('productId'),
        proTypeId: form.getFieldValue('proTypeId'),
        active: values.active,
        remark: values.remark
      };
      console.log(params);
      if (detailData.id) { // 编辑
        params.id = detailData.id;
      }
      controllerCs.bom.insertOrUpdate(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const modalProps = {
    title: detailData.id ? `编辑商品BOM` : `新增商品BOM`,
    visible,
    width: 600,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '提交',
    forceRender: true,
    onOk: onFinish,
    onCancel: onCancel,
  };
  return <Modal {...modalProps}>
    <Form
      {...layout}
      form={form}
      initialValues={{type: type}}
      name="basic"
    >
      <Form.Item
        label="产品名称"
        name="productName"
        rules={[
          { required: true, message: '请输入名称' },
          { type: 'string', max: 50, message: '不超过50个字符' },
        ]}
      >
        <Search onFocus={(e) => {
          e.target.blur();
          !!modalRef4 && !!modalRef4.current && modalRef4.current.showModal();
        }} />
      </Form.Item>

      <Form.Item
        label="产品规格"
        name="specUnionKey"
        
        rules={[
          { required: true, message: '请输入代码' },
          { type: 'string', max: 120, message: '不超过120个字符' },
        ]}
        hasFeedback
      >
      <Input disabled />
      </Form.Item>
      <Form.Item
        label="状态"
        name="active"
        rules={[{ required: true, message: '请选择状态' }]}
      >
        <Select>
          <Option value={1}>启用</Option>
          <Option value={0}>禁用</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="备注"
        name="remark"
        rules={[{ required: false, message: 'Please input remark' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
    <TableModal ref={modalRef4} {...tableModalProps}></TableModal>
  </Modal>;
  
}
export default forwardRef(SparebomModel);
