import React, {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  message,
  InputNumber,
} from 'antd';
import {
  ApartmentOutlined,
  UploadOutlined,
  // BlockOutlined,
} from '@ant-design/icons';
import {
  MyUpload,
  MyTree,
} from 'components';
import { controllerDC } from 'controller';

const tab1Style = {
  height: '487px',
  position: 'relative',
};
const treeHeight = 476;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

function ManageCategoryModal(props, ref) {
  const [currentCategory, setCurrentCategory] = useState({});
  const [currentSet, setCurrentSet] = useState({});
  const [visible, setVisible] = useState(false);
  const [childFlag, setChildFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [treeParams, setTreeParams] = useState({ categorySetId: currentSet.id || undefined });
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setCurrentSet(data || {});
      setTreeParams({ categorySetId: data.id || undefined });
    },
  }));
  const getCategoryDetail = (id) => {
    controllerDC.category.detail({id}).then(res => {
      if (res.code === __SUCCESS200__) {
        const data = res.data || {};
        setCurrentCategory(data);
        form.setFieldsValue({
          name: data.name,
          code: data.code,
          sort: data.sort,
          sortPicUrl: data.sortPicUrl ? [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              fileId: undefined,
              url: data.sortPicUrl,
            },
          ] : [],
        });
      } else {
        message.error(res.message);
      }
    });
  }
  const onFinish = (values) => {
    console.log('Success:', values);
    const params = {
      ...values,
      sortPicUrl: values.sortPicUrl && values.sortPicUrl.length > 0 ? values.sortPicUrl[0].url : '',
      parentId: childFlag ? currentCategory.id : currentCategory.parentId || 0, // 顶级传0
      id: childFlag ? undefined : (currentCategory.id || undefined), // 不为空就是修改
      sortType: 1, // 类型：1-分类；2-标签
      categorySetId: currentSet.id,
      categorySetName: currentSet.name,
    };
    controllerDC.category.saveOrUpdate(params).then(res => {
      if (res.code === __SUCCESS200__) {
        !!res.data && !!res.data.id && getCategoryDetail(res.data.id); // 更新当前分类详情
        setTreeParams(prevState => {}); // 更新分类树
        setTreeParams({ ...treeParams }); // 更新分类树
        form.resetFields();
        setEditFlag(false);
        setChildFlag(false);
      } else {
        message.error(res.message);
      }
    });
  };
  const onCancel = () => {
    setEditFlag(false);
    setChildFlag(false);
    form.setFieldsValue({
      name: currentCategory.name,
      code: currentCategory.code,
      sort: currentCategory.sort,
      sortPicUrl: currentCategory.sortPicUrl ? [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          fileId: undefined,
          url: currentCategory.sortPicUrl,
        },
      ] : [],
    });
  };
  const onModalCancel = () => {
    form.resetFields();
    setVisible(false);
    setEditFlag(false);
    setChildFlag(false);
    setCurrentCategory({});
  };
  const deleteNode = () => {
    controllerDC.category.delete({id: currentCategory.id }).then(res => {
      if (res.code === __SUCCESS__ || res.code === __SUCCESS200__) {
        form.resetFields();
        setEditFlag(true);
        setCurrentCategory({});
        setTreeParams(prevState => {}); // 更新标签树
        setTreeParams({ ...treeParams }); // 更新标签树
      } else {
        message.error(res.message);
      }
    });
  };
  const addRootNode = () => {
    form.resetFields();
    setEditFlag(true);
    setCurrentCategory({});
  };
  const addChildNode = () => {
    form.resetFields();
    setEditFlag(true);
    setChildFlag(true);
  };
  const handleCategorySelect = (data) => {
    console.log('debug handleCategorySelect: ', data);
    setCurrentCategory(data);
    setEditFlag(false);
    setChildFlag(false);
    form.setFieldsValue({
      name: data.name,
      code: data.code,
      sort: data.sort,
      sortPicUrl: data.sortPicUrl ? [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          fileId: undefined,
          url: data.sortPicUrl,
        },
      ] : [],
    });
  };
  const modalProps = {
    // title: <span>分类集名称: <a>{currentSet.name} <BlockOutlined /></a></span>,
    title: <span>分类集名称: <a>{currentSet.name}</a></span>,
    visible,
    width: 1000,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    footer: null,
    onCancel: onModalCancel,
  };
  const myTreeProps = {
    treeType: 'category',
    keyField: 'id',
    titelField: 'name',
    params: treeParams,
    onSelect: handleCategorySelect,
    treeProps: { height: treeHeight, defaultExpandAll: true, selectedKeys: currentCategory.id ? [currentCategory.id] : [] },
  };
  const myUploadProps = {
    maxWidth: 118,
    maxHeight: 95,
    maxSize: 1,
    maxCount: 1,
    antdUploadProps: {
      listType: 'picture',
      showUploadList: { showRemoveIcon: editFlag },
    },
  };
  return <Modal {...modalProps}>
    <Row gutter={40}>
      <Col span={10}>
        <div className="page-content-title">
          <span><ApartmentOutlined /> 分类树</span>
          <span>
            <Button size="small" onClick={() => addRootNode()}>新增根分类</Button>
            &emsp;
            {!!currentCategory.id && <Button size="small" onClick={() => addChildNode()}>新增子分类</Button>}
          </span>
        </div>
        <div className="border-1" style={tab1Style}>
          <MyTree {...myTreeProps} />
        </div>
      </Col>
      <Col span={14}>
        <div className="page-content-title right-box">
          <span>{childFlag ? '父节点：' : ''}{currentCategory.name}</span>
          <span>
            {!!currentCategory.id && <Button size="small" onClick={() => deleteNode()}>删除</Button>}
            &emsp;
            {(!editFlag && !childFlag && !!currentCategory.id) && <Button size="small" onClick={() => setEditFlag(true)}>编辑</Button>}
          </span>
        </div>
        <div className="border-1 pd20" style={tab1Style}>
          {!editFlag && <div className="mask">&nbsp;</div>}
          <Form
            {...layout}
            form={form}
            name="basic"
            hideRequiredMark={!editFlag}
            onFinish={onFinish}
          >
            <Form.Item
              label="名称"
              name="name"
              rules={[
                { required: true, message: '请输入名称' },
                { type: 'string', max: 50, message: '不超过50个字符' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="编码"
              name="code"
              rules={[
                { required: true, message: '请输入编码' },
                { type: 'string', max: 20, message: '不超过20个字符' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="排序"
              name="sort"
              rules={[{ required: true, message: '请输入排序' }]}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              name="sortPicUrl"
              label="标签图片"
              extra={editFlag ? '建议图片大小：118px * 95px；图片格式jpg、png、jpeg；图片大小小于1M (只能保存一张图片)' : null}
            >
              <MyUpload {...myUploadProps}>
                {editFlag && <Button>
                  <UploadOutlined /> 点击上传
                </Button>}
              </MyUpload>
            </Form.Item>

            {editFlag && <Form.Item {...tailLayout}>
              <Button htmlType="submit" type="danger">
                保存
              </Button>
              <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCancel}>
                取消
              </Button>
            </Form.Item>}
            {/*<div style={{display: editFlag ? 'block' : 'none'}}>
             <Form.Item {...tailLayout}>
             <Button htmlType="submit" type="danger">
             保存
             </Button>
             <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCancel}>
             取消
             </Button>
             </Form.Item>
             </div>*/}
          </Form>
        </div>
      </Col>
    </Row>
  </Modal>;
}

export default forwardRef(ManageCategoryModal);
