/**
 * user-controller : 云镖用户中心
 *
 * GET /user/v6/captchapc 端获取验证码信息
 * POST /user/v6/checkTicket 检查Ticket
 * POST /user/v6/getUserName 获取获取用户角色信息所有菜单
 * POST /user/v6/loginpc 用户登入接口
 * POST /user/v6/logoutpc 用户登出
 * POST /user/v6/queryAccountList 账号管理查询列表
 * POST /user/v6/registregist
 * POST /user/v6/resetPassword 重置密码
 * POST /user/v6/userLoginInfo 获取用户账号信息
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';
const apiInfo = {
  'captcha': { 'path': '/uc/user/v6/captcha', 'method': 'GET', 'desc': '端获取验证码信息' },
  'checkTicket': { 'path': '/uc/user/v6/checkTicket', 'method': 'POST', 'desc': '检查Ticket' },
  'getUserName': { 'path': '/uc/user/v6/getUserName', 'method': 'POST', 'desc': '获取获取用户角色信息所有菜单' },
  'login': { 'path': '/uc/user/v6/login', 'method': 'POST', 'desc': 'pc用户登入接口' },
  'logout': { 'path': '/uc/user/v6/logout', 'method': 'POST', 'desc': 'pc用户登出' },
  'queryAccountList': { 'path': '/uc/user/v6/queryAccountList', 'method': 'POST', 'desc': '账号管理查询列表' },
  'registregist': { 'path': '/uc/user/v6/registregist', 'method': 'POST', 'desc': '' },
  'resetPassword': { 'path': '/uc/user/v6/resetPassword', 'method': 'POST', 'desc': '重置密码' },
  'userLoginInfo': { 'path': '/uc/user/v6/userLoginInfo', 'method': 'POST', 'desc': '获取用户账号信息' },
  'updatePwd': { 'path': '/uc/user/v6/updatePwd', 'method': 'POST', 'desc': '修改密码' },
  'regist': { 'path': '/uc/user/v6/regist', 'method': 'POST', 'desc': '获取用户信息' },
  'setUserRole': { 'path': '/uc/user/v6/setUserRole', 'method': 'POST', 'desc': '切换角色' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };

      return request(options);
    };
  })
})();

controller.getApi = key => `${service.host}${apiInfo[key].path}`;

export default controller;
