/**
 * employee-controller : 云镖员工管理中心
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'addEmp': { 'path': '/uc/emp/v6/addEmp', 'method': 'POST', 'desc': '添加员工' },
  'deleteEmp': { 'path': '/uc/emp/v6/deleteEmp', 'method': 'POST', 'desc': '删除员工' },
  'enableOrdisable': { 'path': '/uc/emp/v6/enableOrdisable', 'method': 'POST', 'desc': '启用/禁用' },
  'getEmpInfo': { 'path': '/uc/emp/v6/getEmpInfo', 'method': 'POST', 'desc': '获取员工信息' },
  'getEmpList': { 'path': '/uc/emp/v6/getEmpList', 'method': 'POST', 'desc': '员工信息列表' },
  'getMxmEmpList': { 'path': '/uc/emp/v6/getMxmEmpList', 'method': 'POST', 'desc': '员工信息列表' },
  'selectEmpList': { 'path': '/uc/emp/v6/selectEmpList', 'method': 'POST', 'desc': '员工信息列表' },
  'selectInitialsEmpList': { 'path': '/uc/emp/v6/selectInitialsEmpList', 'method': 'POST', 'desc': '字母分组员工信息列表' },
  'updateEmp': { 'path': '/uc/emp/v6/updateEmp', 'method': 'POST', 'desc': '修改员工' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
