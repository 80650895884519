import React from 'react';
import {
  Tree,
  Spin,
} from 'antd';
import style from './style.module.less';
import { controllerOrg } from 'controller';

class OrgTree extends React.Component {
  state = {
    loading: false,
    treeData: [],
  };

  componentDidMount() {
    this.setState({loading: true});
    controllerOrg.getOrgTree({ orgId: 0 }).then(res => {
      this.setState({loading: false});
      if (res.code === __SUCCESS__) {
        this.setState({
          treeData: res.data,
        });
      } else {
        console.error(res.msg);
      }
    });
  }

  handleSelect = (selectedKeys, e) => {
    const { selected, selectedNodes, node, event } = e;
    console.log('debug tree select', selected, selectedNodes, node, event);
    if (this.props.onSelect && typeof this.props.onSelect === 'function') {
      this.props.onSelect(node);
    }
  };

  handleCheck = (checkedKeys, e) => {
    const { checked, checkedNodes, node, event, halfCheckedKeys } = e;
    console.log('debug tree check', checked, checkedNodes, node, event, halfCheckedKeys);
    if (this.props.onCheck && typeof this.props.onCheck === 'function') {
      this.props.onCheck(checkedKeys, checkedNodes);
    }
  };

  render() {
    const { treeData, loading } = this.state;
    const { treeProps } = this.props;
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            key: item.orgId,
            title: item.orgName,
            children: loop(item.children),
          };
        }
        delete item.children;
        return {
          ...item,
          key: item.orgId,
          title: item.orgName,
        };
      });
    return <div className={style['org-tree-box']}>
      <Spin spinning={loading}>
        <Tree {...treeProps} treeData={loop(treeData)} onSelect={this.handleSelect} onCheck={this.handleCheck} />
      </Spin>
    </div>
  }
}

export default OrgTree;
