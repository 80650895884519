/**
 * dc-pro-brand-controller : 产品品牌调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'delete': {
    'path': '/procenter/api/v1/procenter/proBrand/delete',
    'method': 'POST',
    'desc': '删除产品品牌',
  },
  'detail': { 'path': '/procenter/api/v1/procenter/proBrand/detail', 'method': 'POST', 'desc': '按ID获取产品品牌详情' },
  'getAllList': { 'path': '/procenter/api/v1/procenter/proBrand/getAllList', 'method': 'GET', 'desc': '获取全部产品品牌列表' },
  'list': { 'path': '/procenter/api/v1/procenter/proBrand/list', 'method': 'POST', 'desc': '按条件获取产品品牌列表' },
  'saveOrUpdate': {
    'path': '/procenter/api/v1/procenter/proBrand/saveOrUpdate',
    'method': 'POST',
    'desc': '新增或者修改产品品牌',
  },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
