/**
 * common models
 */
import AsynMenu from 'components/AsynMenu/models';
import { routerRedux } from 'dva/router';
import { storage } from 'utils';
import merge from 'merge';
import controllerMxmService from 'controller/mxmService';
import controllerUser from 'controller/user';


let common = {
  namespace: 'common',
  state: {
    homeConfig: {}
  },
  subscriptions: {
    init({ dispatch, history }) {
      history.listen((location) => {
        let info = storage.get('loginInfo');
        if (location.pathname !== '/login' && !(info && info.corTicket)) {
          dispatch(routerRedux.push('/login'));
        }
      });
    },
  },
  effects: {
    * getHomeConfigFn({ payload }, { call, put }) {
      // 配置缓存1天
      const config = null; // storage.get('homeConfig');
      if (config) {
        yield put({
          type: 'updateState',
          payload: {
            homeConfig: config
          },
        });
      } else {
        const response = yield call(controllerMxmService.getHomeConfig);
        if (response.code === __SUCCESS__) {
          yield put({
            type: 'updateState',
            payload: {
              homeConfig: response
            },
          });
          // 缓存当恰活跃菜单
          storage.set('homeConfig', response);
        }
      }
    },
    * logoutFn({ payload }, { call, put }) {
      const userId = storage.get('loginInfo').userId;
      const response = yield call(controllerUser.logout, {userId});
      if (response.code === __SUCCESS__) {
        yield put({
          type: 'updateState',
          payload: {
            loginInfo: {},
          },
        });
        storage.clear('loginInfo');
        yield put(routerRedux.push('/login'));
        window.location.reload(); // 刷新页面，释放内存
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    }
  }
};

common = merge.recursive(true,
  AsynMenu,
  common
);


export default common;
