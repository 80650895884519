/**
 * dc-pro-unit-controller : 产品单位接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'delete': { 'path': '/procenter/api/v1/procenter/unit/delete', 'method': 'POST', 'desc': '删除单位' },
  'detail': { 'path': '/procenter/api/v1/procenter/unit/detail', 'method': 'POST', 'desc': '产品单位详情' },
  'invalid': { 'path': '/procenter/api/v1/procenter/unit/invalid', 'method': 'POST', 'desc': '失效单位' },
  'list': { 'path': '/procenter/api/v1/procenter/unit/list', 'method': 'POST', 'desc': '产品单位列表' },
  'save': { 'path': '/procenter/api/v1/procenter/unit/save', 'method': 'POST', 'desc': '新增单位' },
  'update': { 'path': '/procenter/api/v1/procenter/unit/update', 'method': 'POST', 'desc': '修改单位' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
