/**
 * 请求方法
 */
// import axios, { CancelToken } from 'axios';
import axios from 'axios';
import { storage } from 'utils';

// axios.defaults.withCredentials = true;
// axios.defaults.headers.common.system = 'web';
// axios.defaults.headers.common.AppAuthorization = '1.0.4';

const pending = [];
const removePending = (config) => {
  for (const p in pending) {
    if (pending[p].u === `${config.url}&${config.method}`) {
      pending[p].f();
      pending.splice(p, 1);
    }
  }
};

// 添加请求拦截器
/*axios.interceptors.request.use((config) => {
  const data = config; // 为了no-param-reassign的提示警告

  // 在一个请求发送前执行一下取消操作
  removePending(data);

  data.cancelToken = new CancelToken((c) => {
    pending.push({
      u: `${data.url}&${data.method}`,
      f: c,
    });
  });

  // 添加票据
  const info = storage.get('loginInfo');
  data.headers.corTicket = info && info.corTicket;

  return data;
}, error => Promise.reject(error));*/

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  removePending(response.config);

  return response;
}, (error) => {
  return Promise.resolve(error.response);
});

const fetch = (options) => {
  let {
    method = 'post', // eslint-disable-line prefer-const
    data,
    url,
  } = options;
  method = method.toLowerCase();

  // 接口签名
  // const signTimestamp = new Date().getTime();
  // const authToken = apiSign(data, signTimestamp);
  //
  // if (authToken !== false) {
  //   //axios.defaults.headers.common.Authorization = authToken;
  //   axios.defaults.headers.common.timestamp = signTimestamp;
  // }
  // if (options.header && options.header.language) {
  //   axios.defaults.headers.common.Language = options.header.language;
  // }

  const info = storage.get('loginInfo');
  if(info && info.corTicket) {
    axios.defaults.headers.common.corTicket = info.corTicket;
  }
  switch (method.toLowerCase()) {
    case 'get':
      return axios.get(url, {
        params: data,
      });
    case 'delete':
      return axios.delete(url, {
        data,
      });
    case 'post':
      return axios.post(url, data);
    case 'put':
      return axios.put(url, data);
    case 'patch':
      return axios.patch(url, data);
    default:
      return axios(options);
  }
};

const request = (options) => {
  const result = fetch(options).then((response) => {
    const { data } = response;
    // 珍藏代码
    // console.log('看一下', window.g_app._store.dispatch); // eslint-disable-line

    return data;
  }).catch((error) => {
    const { response } = error;

    let msg;
    let code;
    let otherData = {};

    if (response) {
      const { data, statusText } = response;

      otherData = data;
      code = response.status;
      msg = data.message || statusText;
    } else {
      code = __OFFLINE__;
      msg = '网络异常，请再试一遍';
    }

    return {
      code,
      message: msg,
      ...otherData,
    };
  });

  return result;
};

export default request;
