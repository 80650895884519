import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  PageHeader,
} from 'antd';
import SparebomModel from './components/sparebomModel';
import SparebomCopyModel from './components/sparebomCopyModel';
import ManageSparebomModel from './components/manageSparebomModel';

import {
  TableView,
  //TableModal,
  // ImportModal,
  //MyTreeSelect,
} from 'components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { controllerCs } from 'controller';
import {
  stateMap
} from './constVar';
function Sparebom() {
  const modalRef1 = useRef(null);
  const modalRef2 = useRef(null);
  const modalRef3 = useRef(null);
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchObj, setSearchObj] = useState({ productName: '', p: 1, s: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await controllerCs.bom.list(searchObj);
      setIsLoading(false);
      setList(res.data || []);
      setTotal(res.total || 0);
      res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    fetchData();
  }, [searchObj]);
  const handleClick = () => {
    setShowForm(!showForm);
  };
  const setBomState = (record) => {
    controllerCs.bom.enableOrDisable({
      ids: [record.id],
      status: record.active === 1 ? 0 : 1, // 状态：1-启用；2-禁用
    }).then(res => {
      res.code === __SUCCESS200__ && setSearchObj({ ...searchObj });
      res.code !== __SUCCESS200__ && message.error(res.msg);
    });
  };
  

  const optClickHandle = (record, act) => {
    console.log('debug record: ', record);
    switch (act) {
      case 'edit':
        modalRef1.current.showModal(record);
        break;
      case 'valid': // 启用/禁用
        setBomState(record);
        break;
      case 'manage':
        modalRef3.current.showModal(record);
        break;
      default:
        break;
    }
  };
  const pageChange = (page, pageSize) => {
    setSearchObj({
      ...searchObj,
      p: page,
      s: pageSize,
    });
  };
  const onSearch = (values) => {
    setSearchObj({
      ...searchObj,
      ...values,
      p: 1,
    });
  };
  const resetSearch = () => {
    setSearchObj(data => ({
      ...data,
      productName: '',
      valid: undefined,
      page: 1,
    }));
    form.setFieldsValue({
      productName: '',
      valid: undefined,
    });
  };
  const tableProps = {
    rowKey: 'id',
    loading: isLoading,
    columns: [
      {
        title: '产品代码',
        dataIndex: 'productCode',
        key: 'productCode',
      },
      {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
       title: '规格',
       dataIndex: 'proSpecName',
       key: 'proSpecName',
       },
      {
        title: '状态',
        dataIndex: 'active',
        key: 'active',
        render: (val) => stateMap[val] || '',
      },
      {
        title: '创建人',
        dataIndex: 'createdName',
        key: 'createdName',
      },
      {
        title: '创建时间',
        dataIndex: 'createdDate',
        key: 'createdDate',
      },
      {
        title: '操作',
        width: 300,
        align: 'center',
        render: (val, record, index) => <Fragment>
          <Button type="link" onClick={() => optClickHandle(record, 'edit')}>编辑</Button>
          <Button type="link" onClick={() => optClickHandle(record, 'valid')}>{record.active === 1 ? '禁用' : '启用'}</Button>
          <Button type="link" onClick={() => optClickHandle(record, 'manage')}>BOM管理</Button>
        </Fragment>,
      },
    ],
    rows: list,
    pageParam: {
      total: total,
      current: searchObj.p,//当前页码
      pageSize: searchObj.s,//当前分页数
      onChange: pageChange,
    },
  };
  return <Fragment>
    <div className="app-body">
      <PageHeader
        ghost={false}
        className="gray-header"
        title={<>
          <Button type="gray" onClick={() => modalRef1.current.showModal(searchObj)}>新增商品BOM</Button>
          &emsp;
          <Button type="gray" onClick={() => modalRef2.current.showModal(searchObj.type)}>复制商品BOM</Button>
        </>}
        extra={[
          <Button className="pos-bottom"
                  key="1"
                  onClick={handleClick}
                  icon={<DoubleRightOutlined rotate={showForm ? 270 : 90} />}>{showForm ? '关闭查询' : '展开查询'}</Button>,
        ]}
      />
      <Form
        form={form}
        name="basic"
        className="top-search-box"
        style={{ display: showForm ? 'block' : 'none' }}
        initialValues={searchObj}
        onFinish={onSearch}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="产品名称"
              name="productName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>&nbsp;</Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button type="danger" htmlType="submit">
              搜索
            </Button>
            &emsp;
            <Button onClick={resetSearch} style={{ margin: '0 8px' }}>
              重置
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="page-main-box">
        <TableView {...tableProps} />
      </div>
    </div>
    <SparebomModel ref={modalRef1} refreshList={onSearch}  />
    <SparebomCopyModel ref={modalRef2} refreshList={onSearch} />
    <ManageSparebomModel ref={modalRef3} refreshList={onSearch} />
   
  </Fragment>;
}

export default Sparebom;
