import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'dva/router';
import BlankLayout from './BlankLayout';
import BaseLayout from './BaseLayout';

class BasicLayout extends React.Component {
  componentDidUpdate (prevProps) {
    // 切换路由时，滚动条至顶
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render () {
    if (this.props.location.pathname === '/login') {
      return (
        <Fragment>
          <BlankLayout location={this.props.location}>
            { this.props.children }
          </BlankLayout>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <BaseLayout location={this.props.location}>
            { this.props.children }
          </BaseLayout>
        </Fragment>
      );
    }
  }
}
BasicLayout.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(BasicLayout);
