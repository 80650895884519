import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  message,
  InputNumber,
} from 'antd';
import {
  ApartmentOutlined,
  UploadOutlined,
  // BlockOutlined,
} from '@ant-design/icons';
import {
  MyUpload,
  MyTree,
  TableModal
} from 'components';
import { controllerDC,controllerCs,controllerProdCenter } from 'controller';
const { Search } = Input;
const tab1Style = {
  height: '487px',
  position: 'relative',
};
const treeHeight = 476;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

function ManageSparebomModel(props, ref) {
  const modalRef5 = useRef(null);
  const [currentBomItem, setCurrentCategory] = useState({});
  const [currentSet, setCurrentSet] = useState({});
  const [visible, setVisible] = useState(false);
  const [childFlag, setChildFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [treeParams, setTreeParams] = useState({ bomId: currentSet.id || undefined });
  const [form] = Form.useForm();

  const tableModalProps = {
    rowKey: 'rowkey',
    selectType: 'radio',
    title: '选择商品',
    searchField: 'filter',
    pageNoField: 'p',
    pageSizeField: 's',
    placeholder:'请输入产品名称',
    antdModalProps: {
      width: 1200
    },
    listApi: controllerProdCenter.product.proSpecConfigList,
    processList: list => list.map(x => ({...x, rowkey: `${x.id}_abc`})),
    params: { kw: '', categoryId: undefined, active: 1,  p: 1, s: 10 },
    onOk: (selectRows) => {
      // controllerProdCenter.product.createForChannel
      let selectRow = selectRows[0];
      form.setFieldsValue({
        'proSpecId':selectRow.id,
        'productId':selectRow.productId, 
        'proTypeId':selectRow.productTypeId, 
        'productName': selectRow.productName,
        'proSpecName': selectRow.specUnionKey 
      });
    },
    tableColumns: [
      {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: '产品规格',
        dataIndex: 'specUnionKey',
        key: 'specUnionKey',
      },
      {
        title: '产品单位',
        dataIndex: 'unit',
        key: 'unit',
        width: 120
      },
      {
        title: '单价（元）',
        dataIndex: 'price',
        key: 'price',
        width: 120
      },
    ]         
};
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setCurrentSet(data || {});
      setTreeParams({ bomId: data.id || undefined });
    },
  }));

  const getCategoryDetail = (id) => {
    controllerDC.category.detail({id}).then(res => {
      if (res.code === __SUCCESS200__) {
        const data = res.data || {};
        setCurrentCategory(data);
        form.setFieldsValue({
          name: data.name,
          code: data.code,
          sort: data.sort,
          sortPicUrl: data.sortPicUrl ? [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              fileId: undefined,
              url: data.sortPicUrl,
            },
          ] : [],
        });
      } else {
        message.error(res.message);
      }
    });
  }

  const onFinish = (values) => {
    console.log('Success:', values); 
    console.log('currentBomItem:', currentBomItem);
    console.log('currentSet:', currentSet);
    console.log('visible:', visible);
    console.log('childFlag:', childFlag);
    console.log('editFlag:', editFlag);
    console.log('treeParams:', treeParams);
    console.log('form:', form);
    const params = {
      proSpecId: form.getFieldValue('proSpecId'),
      productId: form.getFieldValue('productId'),
      proTypeId: form.getFieldValue('proTypeId'),
      picId: values.picUrl && values.picUrl.length > 0 ? values.picUrl[0].fileId : '',
      picUrl: values.picUrl && values.picUrl.length > 0 ? values.picUrl[0].url : '',
      supId: childFlag ? currentBomItem.id : currentBomItem.supId || undefined, // 顶级传0
      id: childFlag ? undefined : (currentBomItem.id || undefined), // 不为空就是修改
      dept: currentSet.dept || 1,
      sort: values.sort,
      active: 1,
      bomId: currentSet.id,
      remark: values.remark
    };

    console.log('params:', params);
    controllerCs.bom.addOrUpdateBomItem(params).then(res => {
      if (res.code === __SUCCESS200__) {
        !!res.data && !!res.data.id && getCategoryDetail(res.data.id); // 更新当前分类详情
        setTreeParams(prevState => {}); // 更新分类树
        setTreeParams({ ...treeParams }); // 更新分类树
        form.resetFields();
        setEditFlag(false);
        setChildFlag(false);
      } else {
        message.error(res.message);
      }
    });
  };
  const onCancel = () => {
    setEditFlag(false);
    setChildFlag(false);
    form.setFieldsValue({
      name: currentBomItem.name,
      code: currentBomItem.code,
      sort: currentBomItem.sort,
      sortPicUrl: currentBomItem.sortPicUrl ? [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          fileId: undefined,
          url: currentBomItem.sortPicUrl,
        },
      ] : [],
    });
  };
  const onModalCancel = () => {
    form.resetFields();
    setVisible(false);
    setEditFlag(false);
    setChildFlag(false);
    setCurrentCategory({});
  };
  const deleteNode = () => {
    controllerDC.category.delete({id: currentBomItem.id }).then(res => {
      if (res.code === __SUCCESS__ || res.code === __SUCCESS200__) {
        form.resetFields();
        setEditFlag(true);
        setCurrentCategory({});
        setTreeParams(prevState => {}); // 更新标签树
        setTreeParams({ ...treeParams }); // 更新标签树
      }
    });
  };
  const addRootNode = () => {
    form.resetFields();
    setEditFlag(true);
    setCurrentCategory({});
  };

  const addChildNode = () => {
    form.resetFields();
    setEditFlag(true);
    setChildFlag(true);
  };

  const handleCategorySelect = (data) => {
    console.log('debug handleCategorySelect: ', data);
    setCurrentCategory(data);
    setEditFlag(false);
    setChildFlag(false);
    form.setFieldsValue({
      id: data.id,
      productName: data.productName,
      proSpecName: data.proSpecName,
      sort: data.sort,
      picUrl: data.picUrl ? [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          fileId: undefined,
          url: data.picUrl,
        },
      ] : [],
    });
  };
  const modalProps = {
    title: <span>产品名称: <a>{currentSet.productName}</a></span>,
    visible,
    width: 1000,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    footer: null,
    onCancel: onModalCancel,
  };

  const myTreeProps = {
    treeType: 'bomTree',
    keyField: 'id',
    titelField: 'productName',
    params: treeParams,
    onSelect: handleCategorySelect,
    treeProps: { height: treeHeight, defaultExpandAll: true, selectedKeys: currentBomItem.id ? [currentBomItem.id] : [] },
  };

  const myUploadProps = {
    maxWidth: 118,
    maxHeight: 95,
    maxSize: 1,
    maxCount: 1,
    antdUploadProps: {
      listType: 'picture',
      showUploadList: { showRemoveIcon: editFlag },
    },
  };
  return <Modal {...modalProps}>
    <Row gutter={40}>
      <Col span={10}>
        <div className="page-content-title">
          <span><ApartmentOutlined /> 部件树</span>
          <span>
            <Button size="small" onClick={() => addRootNode()}>新增根部件</Button>
            &emsp;
            {!!currentBomItem.id && <Button size="small" onClick={() => addChildNode()}>新增子部件</Button>}
          </span>
        </div>
        <div className="border-1" style={tab1Style}>
          <MyTree {...myTreeProps} />
        </div>
      </Col>
      <Col span={14}>
        <div className="page-content-title right-box">
          <span>{childFlag ? '父节点：' : ''}{currentBomItem.name}</span>
          <span>
            {!!currentBomItem.id && <Button size="small" onClick={() => deleteNode()}>删除</Button>}
            &emsp;
            {(!editFlag && !childFlag && !!currentBomItem.id) && <Button size="small" onClick={() => setEditFlag(true)}>编辑</Button>}
          </span>
        </div>
        <div className="border-1 pd20" style={tab1Style}>
          {!editFlag && <div className="mask">&nbsp;</div>}
          <Form
            {...layout}
            form={form}
            name="basic"
            hideRequiredMark={!editFlag}
            onFinish={onFinish}
          >
            <Form.Item
              label="部件名称"
              name="productName"
              rules={[
                { required: true, message: '请输入名称' },
                { type: 'string', max: 50, message: '不超过50个字符' },
              ]}
            >
            <Search onFocus={(e) => {
              e.target.blur();
              !!modalRef5 && !!modalRef5.current && modalRef5.current.showModal();
            }} />
            </Form.Item>

            <Form.Item
              label="部件规格"
              name="proSpecName"
              rules={[
                { required: true, message: '' },
                { type: 'string', max: 100, message: '不超过100个字符' },
              ]}
            >
              <Input disabled/>
            </Form.Item>
            <Form.Item
              name="picUrl"
              label="标签图片"
              extra={editFlag ? '建议图片大小：118px * 95px；图片格式jpg、png、jpeg；图片大小小于1M (只能保存一张图片)' : null}
            >
              <MyUpload {...myUploadProps}>
                {editFlag && <Button>
                  <UploadOutlined /> 点击上传
                </Button>}
              </MyUpload>
            </Form.Item>
            <Form.Item
              label="排序"
              name="sort"
              rules={[{ required: true, message: '请输入排序' }]}
            >
              <InputNumber min={0} />
            </Form.Item>
            {editFlag && <Form.Item {...tailLayout}>
              <Button htmlType="submit" type="danger">
                保存
              </Button>
              <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCancel}>
                取消
              </Button>
            </Form.Item>}
            {/*<div style={{display: editFlag ? 'block' : 'none'}}>
             <Form.Item {...tailLayout}>
             <Button htmlType="submit" type="danger">
             保存
             </Button>
             <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onCancel}>
             取消
             </Button>
             </Form.Item>
             </div>*/}
          </Form>
        </div>
      </Col>
    </Row>
    <TableModal ref={modalRef5} {...tableModalProps}></TableModal>
  </Modal>;
  
}

export default forwardRef(ManageSparebomModel);
