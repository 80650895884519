import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import './style.less';

class ComplexSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // list: props.value || [],
      valueSet: {
        orgIds: [],
        empIds: [],
        roleIds: [],
        groupIds: [],
        ...props.value
      },
    };
  }
  openModal = () => {
    const { sourceModalRef } = this.props;
    if (sourceModalRef && sourceModalRef.current) {
      if (sourceModalRef.current.openModal && typeof sourceModalRef.current.openModal === 'function') {
        sourceModalRef.current.openModal(this.state.valueSet);
      } else {
        throw (new Error(`请在弹框组件<${sourceModalRef.constructor.name}>中定义[openModal]方法`));
      }
    } else if (sourceModalRef) {
      if (sourceModalRef.openModal && typeof sourceModalRef.openModal === 'function') {
        sourceModalRef.openModal(this.state.valueSet);
      } else {
        throw (new Error(`请在弹框组件<${sourceModalRef.constructor.name}>中定义[openModal]方法`));
      }
    }
  };

  triggerChange = (changedValue) => {
    if (this.props.onChange) {
      this.props.onChange(changedValue);
    }
  };

  setValue = (newVal) => {
    this.setState({ valueSet: {...newVal} }, () => {
      this.triggerChange(newVal);
    });
  };

  closeHandle = (e, item, listKey) => {
    e.preventDefault();
    const arr = [...this.state.valueSet[listKey]];
    let dataKey = 'id';
    if(listKey === 'orgIds') {
      dataKey = 'orgId';
    }
    if(listKey === 'groupIds') {
      dataKey = 'groupId';
    }
    const itemKey = item[dataKey];
    const index = arr.findIndex(x => x[dataKey] === itemKey);
    if (index > -1) {
      arr.splice(index, 1);

      const newValue = {
        ...this.state.valueSet,
        [listKey]: arr
      }
      this.setState({ valueSet: newValue }, () => {
        this.triggerChange(newValue);
      });
    }
  };

  render() {
    const self = this;
    const { valueSet } = this.state;
    const { placeholder, disabled } = this.props;
    const tags = (val) => {
      let arr = [];
      Object.keys(val).forEach(key => {
        val[key].forEach((x, index) => {
          arr.push(<Tag key={`${key}_${index}`}
                        closable={!disabled}
                        onClose={e => self.closeHandle(e, x, key)}>{x.realname || x.orgName || x.groupName || x.name}</Tag>)
        })
      })
      return arr;
    }
    const count = Object.keys(valueSet).reduce((prev, current, index) => (prev + valueSet[current].length), 0)
    return <div className={`ant-select ant-select-multiple ant-select-show-search${disabled ? ' ant-select-disabled' : ''}`}
                onClick={() => !disabled && this.openModal()}
                style={{ width: '100%' }}>
      <div className="ant-select-selector">
        {
          tags(valueSet)
        }
        {(!count && !!placeholder) &&
        <span className="ant-select-selection-placeholder">{placeholder}</span>}
      </div>
    </div>;
  }
}

// { value = [], dataKey = 'id', sourceModalRef, placeholder, onChange }
ComplexSelect.propTypes = {
  // value: PropTypes.array,
  disabled: PropTypes.any,
  placeholder: PropTypes.string,
  sourceModalRef: PropTypes.object.isRequired, // 弹框组件的ref
};
ComplexSelect.defaultProps = {
  disabled: false,
};
export default ComplexSelect;
