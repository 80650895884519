import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import './style.less';

class ModalSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.value || [],
    };
  }

  openModal = () => {
    const { sourceModalRef } = this.props;
    if (sourceModalRef && sourceModalRef.current) {
      if (sourceModalRef.current.openModal && typeof sourceModalRef.current.openModal === 'function') {
        sourceModalRef.current.openModal(this.state.list);
      } else {
        throw (new Error(`请在弹框组件<${sourceModalRef.constructor.name}>中定义[openModal]方法`));
      }
    } else if (sourceModalRef) {
      if (sourceModalRef.openModal && typeof sourceModalRef.openModal === 'function') {
        sourceModalRef.openModal(this.state.list);
      } else {
        throw (new Error(`请在弹框组件<${sourceModalRef.constructor.name}>中定义[openModal]方法`));
      }
    }
  };

  triggerChange = (changedValue) => {
    if (this.props.onChange) {
      this.props.onChange(changedValue);
    }
  };

  modalOk = (newArr) => {
    this.setState({ list: newArr }, () => {
      this.triggerChange(newArr);
    });
  };

  closeHandle = (e, item) => {
    e.preventDefault();
    const { dataKey } = this.props;
    const arr = [...this.state.list];
    const itemKey = item[dataKey];
    const index = arr.findIndex(x => x[dataKey] === itemKey);
    if (index > -1) {
      arr.splice(index, 1);
    }
    this.setState({ list: arr }, () => {
      this.triggerChange(arr);
    });
  };

  render() {
    const { list } = this.state;
    const { dataKey, textKey, placeholder, disabled } = this.props;
    return <div className={`ant-select ant-select-multiple ant-select-show-search${disabled ? ' ant-select-disabled' : ''}`}
                onClick={() => !disabled && this.openModal()}
                style={{ width: '100%' }}>
      <div className="ant-select-selector">
        {
          list.map((item, index) => <Tag key={item[dataKey]}
                                         closable={!disabled}
                                         onClose={e => this.closeHandle(e, item)}>{item[textKey]}</Tag>)
        }
        {((!list || !list.length) && !!placeholder) &&
        <span className="ant-select-selection-placeholder">{placeholder}</span>}
      </div>
    </div>;
  }
}

// { value = [], dataKey = 'id', sourceModalRef, placeholder, onChange }
ModalSelect.propTypes = {
  value: PropTypes.array,
  disabled: PropTypes.any,
  textKey: PropTypes.string, // 所选数据的文本字段，用于页面展示
  dataKey: PropTypes.string, // 所选数据的主键，具有唯一性
  placeholder: PropTypes.string,
  sourceModalRef: PropTypes.object.isRequired, // 弹框组件的ref
};
ModalSelect.defaultProps = {
  disabled: false,
  textKey: 'name',
  dataKey: 'id',
};
export default ModalSelect;
