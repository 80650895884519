import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  message,
} from 'antd';
import { controllerDC } from 'controller';

const { Option } = Select;
const { TextArea } = Input;

function LabelSetModal({ refreshList }, ref) {
  const [detailData, setDetailData] = useState({});
  const [visible, setVisible] = useState(false);
  const [dictUseObj, setDictUseObj] = useState([]);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setDetailData(data || {});
      if (data) {
        form.setFieldsValue({
          name: data.name || undefined,
          useObj: data.useObj || undefined,
          state: data.state || undefined,
          remark: data.remark || undefined,
        });
      }
    },
  }));
  useEffect(() => {
    controllerDC.dictItem.listByKey({ key: controllerDC.dictItem.dictKey.LABEL_USE_OBJ }).then(res => {
      if (res.code === __SUCCESS200__) {
        setDictUseObj(res.data);
      } else {
        message.error(res.message);
      }
    });
  }, []);
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        source: 2, // 来源 1系统 2用户
      };
      if (detailData.id) { // 编辑
        params.id = detailData.id;
        params.source = detailData.source;
      }
      controllerDC.labelSet.saveOrUpdate(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const modalProps = {
    title: detailData.id ? '编辑标签集' : '新增标签集',
    visible,
    width: 600,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
  };
  return <Modal {...modalProps}>
    <Form
      {...layout}
      form={form}
      name="basic"
    >
      <Form.Item
        label="标签集名称"
        name="name"
        rules={[
          { required: true, message: '请输入名称' },
          { type: 'string', max: 50, message: '不超过50个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="使用对象"
        name="useObj"
        rules={[
          { required: true, message: '请选择使用对象' },
        ]}
      >
        <Select>
          {dictUseObj.map(x => <Option key={x.sKey || x.skey} value={x.sKey || x.skey}>{x.name}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        label="状态"
        name="state"
        rules={[{ required: true, message: '请选择状态' }]}
      >
        <Select>
          <Option value={1}>启用</Option>
          <Option value={2}>禁用</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="备注"
        name="remark"
        rules={[{ required: false, message: 'Please input your username!' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  </Modal>;
}

export default forwardRef(LabelSetModal);
