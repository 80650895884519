/**
 * Created by stonewang on 2017/9/10.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Tree,
  Radio,
  Input,
  Col,
  Checkbox,
  Row,
} from 'antd';
import {
  CloseOutlined,
  RightOutlined,
} from '@ant-design/icons';
import styles from './style.module.less';
import { controllerDMS } from 'controller';

// const TreeNode = Tree.TreeNode;
const Search = Input.Search;

class SelectRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      title: '',
      treeData: [],
      orgListData: [],
      selectedKeys: [],
      selectedNodes: [],
      rangeType: 2, // 默认区域
      halfSelected: [],
      halfSelectedNodes: [],
      kw: '',
    };
    this.handleSave = this.handleSave.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.treeSelect = this.treeSelect.bind(this);
    this.treeSelect2 = this.treeSelect2.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getData = this.getData.bind(this);
    this.getOrg = this.getOrg.bind(this);
    this.getArea = this.getArea.bind(this);
    this.onLoadData = this.onLoadData.bind(this);
    this.radioChange = this.radioChange.bind(this);
    this.checkBexChange = this.checkBexChange.bind(this);
    /*this.searchRange = this.searchRange.bind(this);
     this.getselectedKeys = this.getselectedKeys.bind(this);*/
  }

  componentDidMount() {
  }

  radioChange(e) {
    // TODO:切换时显示默认的右侧selectedNode
    let self = this;
    self.setState({
      kw: '',
      'rangeType': e.target.value,
      'treeData': [],
      'orgListData': [],
      'selectedNodes': [],
      'selectedKeys': [],
      'halfSelected': [],
      'halfSelectedNodes': [],
    }, () => {
      self.getData('');
    });
  }

  onLoadData(treeNode) {
    return new Promise((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }
      this.getData(treeNode.props.eventKey, (datas) => {
        treeNode.props.dataRef.children = datas.map(x => ({ ...x, parentId: treeNode.props.eventKey }));
        this.setState({
          treeData: [...this.state.treeData],
        });
        resolve();
      });
    });
  }

  getData(id, cb) {
    this.state.rangeType === 1 && this.getOrg('', cb);
    this.state.rangeType === 2 && this.getArea(id, cb);
  }

  getOrg(name, cb) {
    // 获取组织
    /*if (!name) {
     message.warning('请输入组织名称');
     return;
     }*/
    const self = this;
    controllerDMS.channel.getListToSelect({ type: 0 }).then(result => {
      if (result && result.code) {
        if(!!name) {
          self.setState({
            kw: name,
            orgListData: (result.data || []).filter(x => x.name.indexOf(name) > -1)
          });
        } else {
          self.setState({ orgListData: result.data || [], kw: '' });
        }
      }
      if (typeof cb == 'function') {
        cb(result.data || []);
      }
    });
    /*
     id = id || '';
     getOrgTreeBySeld(id).then((result) => {
     if (result && result.data && result.data.length > 0) {
     if (typeof cb == 'function') {
     cb(result.data);
     } else {
     self.setState({treeData: result.data});
     }
     } else {
     if (typeof cb == 'function') {
     cb([]);
     }
     }
     });
     */
  }

  getArea(id, cb) {
    // 获取区域
    const self = this;
    controllerDMS.channel.treeList({ id: id || 0 }).then((result) => {
      if (result && result.data && result.data.length > 0) {
        if (typeof cb == 'function') {
          cb(result.data);
        } else {
          self.setState({ treeData: result.data });
        }
      } else {
        if (typeof cb == 'function') {
          cb([]);
        }
      }
    });
  }

  showModal = () => {
    const { keys, nodes, title } = this.props;
    let self = this;
    let selectedNodes = [];
    let halfSelectedNodes = [];
    let rangeType = 2;
    (nodes || []).forEach((item) => {
      rangeType = item.rangeType;
      if (item.sign == 1 || rangeType == 1) {
        selectedNodes.push(item);
      } else {
        halfSelectedNodes.push(item);
      }
    });
    self.setState({
      visible: true,
      title: title,
      kw: '',
      selectedKeys: keys || [],
      selectedNodes: selectedNodes,
      halfSelectedNodes: halfSelectedNodes,
      rangeType: rangeType || 2,
    }, () => {
      self.getData('', (datas) => {
        self.setState({
          treeData: datas,
        });
      });
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedKeys: [],
      selectedNodes: [],
    });
  };

  handleSave() {
    this.setState({ visible: false });
    if (this.props.onOk) {
      const { selectedNodes, selectedKeys, halfSelectedNodes } = this.state;
      let nodes = [];
      selectedNodes.forEach((item) => {
        nodes.push(item);
      });
      halfSelectedNodes.forEach((item) => {
        nodes.push(item);
      });
      this.props.onOk(selectedKeys, nodes.filter(x => x.key));
    }
  }

  unique(array, rowKey) { //数组元素是对象的时候需要传入rowKey（唯一性的字段名称）做比较
    var res = [];
    for (var i = 0, arrayLen = array.length; i < arrayLen; i++) {
      for (var j = 0, resLen = res.length; j < resLen; j++) {
        if (rowKey) {
          if (array[i][rowKey] === res[j][rowKey]) {
            break;
          }
        } else {
          if (array[i] === res[j]) {
            break;
          }
        }
      }
      // 如果this[i]是唯一的，那么执行完循环，j等于resLen
      if (j === resLen) {
        res.push(array[i]);
      }
    }
    return res;
  } //数组去重
  treeSelect2(selectedKeys, info) {
    let arr = [];
    let p = [];
    let list = info.checkedNodes; // .map(x => x.dataRef);
    //判断子节点是否已经全选，如果全选则只在右边显示父节点，且只传父节点数据给后端
    list.forEach(x => {
      if (x.children && x.children.length > 0) {
        if (x.children.every(y => selectedKeys.indexOf(y.value) > -1)) {
          arr.push(x);
          p.push(x.value);
        }
      }
    });
    list = list.filter(x => p.indexOf(x.parentId) === -1);
    list = this.unique(arr.concat(list), 'value');//去重
    arr = list.filter(x => p.indexOf(x.parentId) === -1);
    this.setState({
      selectedKeys: selectedKeys,
      selectedNodes: arr.map(x => ({
        name: x.label,
        key: x.value,
        rangeId: x.value,
        rangeType: this.state.rangeType,
        sign: this.state.rangeType == 2 ? 1 : 0, // 区域的选中为1, 组织的选中为0
      })),
    });
  }

  treeSelect(selectedKeys, info) {
    let selectedNodes = info.checkedNodes.map(item => {
      return {
        name: item.props.title,
        key: item.key,
        rangeId: item.key,
        rangeType: this.state.rangeType,
        sign: this.state.rangeType == 2 ? 1 : 0, // 区域的选中为1, 组织的选中为0
      };
    });
    let self = this;
    if (self.state.rangeType == 2) {
      // 区域的处理
      let childNodes = [];
      info.checkedNodes.forEach(item => {
        // 取到已选父节点下的子节点
        if (item.props.children && item.props.children.length > 0) {
          var flag = true;
          item.props.children.forEach((x => {
            if (selectedKeys.indexOf(x.key) == -1) {
              flag = false;
            }
          }));
          if (flag) {
            item.props.children.forEach((x => {
              childNodes.push(x.key);
            }));
          }
        }
      });
      let selectedKeysAfter = [];
      selectedKeys.forEach((x) => {
        if (childNodes.indexOf(x) == -1) {
          selectedKeysAfter.push(x);
        }
      });
      let selectedNodesAfter = [];
      selectedNodes.forEach((x) => {
        if (childNodes.indexOf(x.key) == -1) {
          selectedNodesAfter.push(x);
        }
      });
      self.setState({
        selectedKeys: selectedKeysAfter,
        selectedNodes: selectedNodesAfter,
      }, () => {
        // 获取选中节点的父节点是否非全选
        if (selectedKeysAfter.length > 0) {
          self.getHalfParent(info.halfCheckedKeys, info.checked);
        }
      });
      return;
    }
    self.setState({
      selectedKeys: selectedKeys,
      selectedNodes: selectedNodes,
    }, () => {
      /* 暂废弃
       if (info.node.props.children && info.node.props.children.length > 0) {
       if (info.checked) {
       // 选中父节点后,默认选中所有子节点 info.checkedNodes
       self.addChildSelected(info.node.props.children);
       } else {
       // 父节点去除选中后,所有子节点也去除选中
       self.deleteChildSelected(info.node.props.children);
       }
       }
       */
    });
    return info;
  }

  getHalfParent(halfCheckedKeys, checked) {
    const { selectedKeys, halfSelected } = this.state;
    let self = this;
    let halfSelectedNew = [];
    if (checked) {
      // 选中
      halfSelected.forEach((key) => {
        // 去除已选的
        if (selectedKeys.indexOf(key) == -1) {
          halfSelectedNew.push(key);
        }
      });
    }
    halfCheckedKeys.forEach(function (key) {
      if (halfSelectedNew.indexOf(key) == -1) {
        halfSelectedNew.push(key);
      }
    });
    this.setState({
      'halfSelected': halfSelectedNew,
      'halfSelectedNodes': [],
    }, () => {
      self.addHalfTitle(self.state.treeData);
    });
  }

  addHalfTitle(options) {
    /* 将半选的节点存入halfSelectedNodes */
    const { halfSelected, halfSelectedNodes, rangeType } = this.state;
    let self = this;
    options.forEach((item) => {
      if (halfSelected.indexOf(item.value + '') > -1) {
        // 半选节点
        halfSelectedNodes.push({
          name: item.label,
          key: item.value,
          rangeId: item.value,
          rangeType: rangeType,
          sign: 0,
        });
        self.setState({
          'halfSelectedNodes': halfSelectedNodes,
        });
      }
      if (item.children && item.children.length > 0) {
        self.addHalfTitle(item.children);
      }
    });
  }

  addChildSelected(infos) {
    // 选中父节点后,默认选中所有子节点
    const { selectedKeys, selectedNodes } = this.state;
    let self = this;
    infos.forEach((item) => {
      if (selectedKeys.indexOf(item.key) == -1) {
        selectedKeys.push(item.key);
        selectedNodes.push({
          name: item.props.title,
          rangeId: item.rangeId,
          rangeType: self.props.rangeType,
        });
      }
      self.setState({
        selectedKeys: selectedKeys,
        selectedNodes: selectedNodes,
      }, () => {
        if (item.props.children && item.props.children.length > 0) {
          self.addChildSelected(item.props.children);
        }
      });
    });
  }

  deleteChildSelected(infos) {
    // 父节点去除选中后,所有子节点也去除选中
    const { selectedKeys, selectedNodes } = this.state;
    let self = this;
    infos.forEach((item) => {
      let indexed = selectedKeys.indexOf(item.key);
      if (indexed > -1) {
        selectedKeys.splice(indexed, 1);
        selectedNodes.forEach((x, ind) => {
          if (x.key == item.key) {
            selectedNodes.splice(ind, 1);
          }
        });
        selectedNodes.splice(indexed, 1);
      }
      self.setState({
        selectedKeys: selectedKeys,
        selectedNodes: selectedNodes,
      }, () => {
        if (item.props.children && item.props.children.length > 0) {
          self.deleteChildSelected(item.props.children);
        }
      });
    });
  }

  deleteItem(index) {
    let { selectedNodes, selectedKeys } = this.state;
    selectedKeys.forEach((x, inx) => {
      if (x == selectedNodes[index].id) {
        selectedKeys.splice(inx, 1);
      }
    });
    selectedNodes.splice(index, 1);
    this.setState({
      selectedKeys,
      selectedNodes,
    });
  }

  //搜索框搜索
  /*getselectedKeys(value, arr) {
   let selectedKeys;
   let selectedvalue;
   for (let i = 0; i < arr.length; i++) {
   if (arr[i].parent == value) {
   selectedKeys = `${arr[i].key}_${i}`;
   selectedvalue = arr[i].parent;
   break;
   } else {
   const node = arr[i];
   if (node.leaf) {
   for (var j = 0; j < node.leaf.length; j++) {
   if (node.leaf[j].parent == value) {
   selectedKeys = `${node.leaf[j].key}_${j}`;
   selectedvalue = node.leaf[j].parent;
   break;
   } else {
   selectedKeys = this.getselectedKeys(value, node.leaf).selectedKeys;
   selectedvalue = this.getselectedKeys(value, node.leaf).selectedvalue;
   }
   }
   }
   }
   }
   return {selectedKeys, selectedvalue};
   }*/

  /*searchRange(value) {
   var selectedKeys = this.getselectedKeys(value, this.state.tree).selectedKeys;
   var selectedvalue = this.getselectedKeys(value, this.state.tree).selectedvalue;
   if (selectedKeys && selectedvalue) {
   var arrmsg = this.state.selected;
   arrmsg.push(selectedvalue);
   this.setState({
   selected: arrmsg,
   selectkey: selectedKeys
   })
   } else {
   alert('未搜索到相关范围');
   }
   }*/
  checkBexChange(e) {
    let { orgListData, selectedNodes, selectedKeys } = this.state;
    let data = e.target;
    let nodes = [];
    if (data.value == '-') {
      // 全选
      if (data.checked) {
        orgListData.forEach(x => {
          if (selectedKeys.indexOf(x.id + '') == -1) {
            selectedKeys.push(x.id + '');
            selectedNodes.push(x);
          }
        });
      } else {
        selectedNodes.forEach(x => {
          let flag = false;
          orgListData.forEach(m => {
            if (x.key == m.id) {
              flag = true;
            }
          });
          if (!flag) {
            nodes.push(x);
          }
        });
        selectedKeys = nodes.map(x => {
          return x.key;
        });
        selectedNodes = nodes;
      }
    } else {
      if (data.checked) {
        selectedKeys.push(data.value + '');
        selectedNodes.push(orgListData.filter(x => x.id == data.value)[0]);
      } else {
        selectedNodes.forEach(x => {
          if (x.key != data.value) {
            nodes.push(x);
          }
        });
        selectedNodes = nodes;
        selectedKeys = selectedNodes.map(x => {
          return x.key + '';
        });
      }
    }
    this.setState({
      selectedNodes: selectedNodes.map(x => ({
        ...x,
        name: x.name,
        key: x.id || x.key,
        rangeId: x.id || x.key,
        rangeType: this.state.rangeType,
        sign: this.state.rangeType == 2 ? 1 : 0, // 区域的选中为1, 组织的选中为0
      })),
      selectedKeys,
    });
  }

  render() {
    const self = this;
    const { selectedKeys, selectedNodes, visible, treeData, title, rangeType, orgListData, kw } = this.state;
    const modalOpts = {
      title: '选择' + (title || '范围'),
      visible: visible,
      onCancel: self.handleCancel,
      footer: [
        <Button key="0" size="large" onClick={self.handleCancel}>取消</Button>,
        <Button key="1" type="primary" size="large" onClick={self.handleSave}>
          确定
        </Button>,
      ],
    };
    /*const renderTreeNodes = (data) => {
     return data.map((item) => {
     if (item.children) {
     return (
     <TreeNode title={item.label} key={item.value} isLeaf={item.leaf} dataRef={item}>
     {renderTreeNodes(item.children)}
     </TreeNode>
     );
     }
     return <TreeNode title={item.label} key={item.value} isLeaf={item.leaf} dataRef={item} />;
     });
     };*/
    const loop = (data) => data.map(item => {
      if (item.children && item.children.length > 0) {
        return {
          ...item,
          key: item.value,
          title: item.label,
          isLeaf: item.leaf,
          children: loop(item.children),
        };
      }
      // delete item.children;
      return {
        ...item,
        key: item.value,
        title: item.label,
        isLeaf: item.leaf,
      };
    });
    // let isCheckAll = orgListData.filter(x => selectedKeys.indexOf(x.id) == -1);
    return (
      <Modal {...modalOpts} width={800}>
        <div className={styles['modalTitle']}>
          <Radio.Group onChange={this.radioChange} value={rangeType}>
            <Radio.Button value={2}>区域</Radio.Button>
            <Radio.Button value={1}>组织</Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles['modalMain']}>
          <div className={styles['modalLeft']}>
            {/*
             loadData={this.onLoadData}
             treeData={loop(treeData)}
             */}
            {rangeType === 2 && <Tree autoExpandParent={true}
                                      multiple={true}
                                      checkable={true}
                                      treeData={loop(treeData)}
                                      onCheck={this.treeSelect2}
                                      checkedKeys={selectedKeys}>
              {/*{renderTreeNodes(treeData)}*/}
            </Tree>
            }
            {rangeType === 1 &&
            <div className="range-org">
              <Search placeholder="请输入组织名称" value={kw} onChange={(e) => this.setState({kw: e.target.value})} style={{ width: 200 }} onSearch={this.getOrg}></Search>
              <Row style={{ marginTop: '10px' }}>
                {/*<Col span={24}><Checkbox checked={isCheckAll.length == 0 && orgListData.length > 0} value="-"*/}
                {/*onChange={this.checkBexChange}>全选</Checkbox></Col>*/}
                {
                  orgListData.map((item, index) => {
                    return <Col span={24} key={index}><Checkbox onChange={this.checkBexChange}
                                                                checked={selectedKeys.indexOf(item.id + '') > -1}
                                                                value={item.id}>{item.name}</Checkbox></Col>;
                  })
                }
              </Row>
            </div>
            }
          </div>

          <RightOutlined className='modalIcon' />

          <div className={styles['modalRight']}>
            {
              selectedNodes.map((item, key) => {
                return (
                  <p key={key} className='modalP'>
                    <span className='modalPs'>{item.name}</span>
                    {rangeType == 1 &&
                    <CloseOutlined className='closeIcon' onClick={this.deleteItem.bind(this, key)} />}
                  </p>
                );
              })
            }
          </div>
        </div>
      </Modal>
    );
  }
}

SelectRange.propTypes = {
  onOk: PropTypes.func,
  keys: PropTypes.array,
  nodes: PropTypes.array,
  title: PropTypes.string,
  rangeType: PropTypes.number, // 1 渠道, 2 区域  默认1
};
export default SelectRange;
