/**
 * @author umin
 * @date 2020/06/13
 * @description 本地缓存
 */
import tools from './tools';


/**
 * 本地缓存
 */
const storage = window.localStorage;
const prefix = 'mxm6_';
const defaultKey = 'key';

class Storage {

  /**
   * storage key转换
   * @param {string} k storage key
   */
  key(k = defaultKey) {
    return prefix + k;
  };

  /**
   * 缓存数据
   * @param {string}} k storage key
   * @param {*} v storage value
   */
  set(k, v) {
    v = tools.isObject(v) ? JSON.stringify(v) : v;
    return storage.setItem(this.key(k), v);
  };

  /**
   * 获取缓存
   * @param {string} k storage key
   */
  get(k) {
    let v = storage.getItem(this.key(k));
    return /^{.+:.+}$/.test(v) ? tools.parse(v) : v;
  };

  /**
   * 获取缓存字符串
   * @param {string} k storage key
   */
  getString(k) {
    return storage.getItem(this.key(k));
  };

  /**
   * 获取缓存对象
   * @param {string} k storage key
   */
  getObj(k) {
    return tools.parse(storage.getItem(this.key(k)));
  };

  /**
   * 清除缓存
   * @param {string} k storage key
   */
  clear(k) {
    return storage.clear(this.key(k));
  }
}


export default new Storage();