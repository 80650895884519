import React, {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  message,
} from 'antd';
import { MyUpload } from 'components';
import { UploadOutlined } from '@ant-design/icons';
import { controllerProdCenter } from 'controller';

const { Option } = Select;
const { TextArea } = Input;

function BrandModal({ refreshList }, ref) {
  const [detailData, setDetailData] = useState({});
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: (data) => {
      setVisible(true);
      setDetailData(data || {});
      if (data) {
        form.setFieldsValue({
          code: data.code || undefined,
          name: data.name || undefined,
          active: `${data.active}`,
          sortOrder: data.sortOrder || undefined,
          description: data.description || undefined,
          labelPath: data.labelPath ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: data.labelPath,
          }]: []
        });
      }
    },
  }));
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        active: values.active ? parseInt(values.active) : undefined,
        labelPath: values.labelPath && values.labelPath.length ? values.labelPath[0].url : ''
      };
      if (detailData.id) { // 编辑
        params.id = detailData.id;
        params.source = detailData.source;
      }
      controllerProdCenter.brand.saveOrUpdate(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  const modalProps = {
    // title: '新增/编辑品牌',
    title: detailData.id ? '编辑品牌' : '新增品牌',
    visible,
    width: 600,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
  };
  return <Modal {...modalProps}>
    <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{
        active: '1'
      }}
    >
      <Form.Item
        label="代码"
        name="code"
        rules={[{ required: true, message: '请输入品牌代码' },
          { type: 'string', max: 20, message: '不超过20个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="名称"
        name="name"
        rules={[{ required: true, message: '请输入品牌名称' },
          { type: 'string', max: 50, message: '不超过50个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="状态"
        name="active"
        rules={[{ required: true, message: '请选择状态' }]}
      >
        <Select>
          <Option value="1">启用</Option>
          <Option value="0">禁用</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="排序"
        name="sortOrder"
        rules={[{ required: true, message: '请输入品牌排序' }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="labelPath"
        label="标签图片"
        extra="建议图片大小：118px * 95px；图片格式jpg、png、jpeg；图片大小小于1M (只能保存一张图片)"
      >
        <MyUpload maxWidth={118} maxHeight={95} maxSize={1} maxCount={1} antdUploadProps={{ listType: 'picture' }}>
          <Button>
            <UploadOutlined /> 点击上传
          </Button>
        </MyUpload>
      </Form.Item>
      <Form.Item
        label="备注"
        name="description"
        rules={[{ required: false, message: 'Please input your username!' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  </Modal>;
}

export default forwardRef(BrandModal);
