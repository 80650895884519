import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Tabs,
  Form,
  Input,
  Radio,
  Button,
  Select,
} from 'antd';
import {
  ComplexSelect,
  // ComplexSelect2,
  StaffAndOrgModal,
} from 'components';
import { controllerShareRules } from 'controller';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

function ShareRuleDetail({ history, location, match }) {
  const id = parseInt(match.params.id);
  const [title, setTitle] = useState(id > 0 ? '共享规则详情' : '新增共享规则');
  const [editFlag, setEditFlag] = useState(!id);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [form] = Form.useForm();
  const modalEl1 = useRef(null);
  const selectEl1 = useRef(null);
  const modalEl2 = useRef(null);
  const selectEl2 = useRef(null);
  const [shareData, setShareData] = useState([]);
  // const [detailData, setDetailData] = useState({});
  const [shareDataReady, setShareDataReady] = useState(false);
  useEffect(() => {
    if (id > 0 && !fetchFlag) {
      controllerShareRules.getSharingDetail({ sharingId: id }).then(res => {
        if (res.code === __SUCCESS__) {
          // setDetailData(res.data);
          setFetchFlag(true);
          form.setFieldsValue({
            name: res.data.name,
            sharingType: res.data.sharingType,
            power: res.data.power,
            sharingSource: res.data.sharingSource,
            sharingTo: res.data.sharingTo,
            remarks: res.data.remarks,
          });
          selectEl1.current.setValue(res.data.sharingSource);
          selectEl2.current.setValue(res.data.sharingTo);
        } else {
          console.error(res.msg);
        }
      });
    }
  }, [fetchFlag, form, id]);
  useEffect(() => {
    if (!shareDataReady) {
      controllerShareRules.getSharingData().then(res => {
        if (res.code === __SUCCESS__) {
          setShareData(res.data);
          setShareDataReady(true);
        } else {
          console.error(res.msg);
        }
      });
    }
  }, [form, shareDataReady]);
  const onFinish = values => {
    console.log('Success:', values);
    Object.keys(values.addResource).forEach(key => {
      let dataKey = 'id';
      if (key === 'orgIds') {
        dataKey = 'orgId';
      }
      if (key === 'groupIds') {
        dataKey = 'groupId';
      }
      values.addResource[key] = values.addResource[key].map(x => x[dataKey]);
    });
    Object.keys(values.addSharingTo).forEach(key => {
      let dataKey = 'id';
      if (key === 'orgIds') {
        dataKey = 'orgId';
      }
      if (key === 'groupIds') {
        dataKey = 'groupId';
      }
      values.addSharingTo[key] = values.addSharingTo[key].map(x => x[dataKey]);
    });
    if (id > 0) {
      controllerShareRules.updateSharing({ ...values, id }).then(res => {
        if (res.code === __SUCCESS__) {
          goBack();
        } else {
          console.error(res.msg);
        }
      });
    } else {
      controllerShareRules.addSharing(values).then(res => {
        if (res.code === __SUCCESS__) {
          goBack();
        } else {
          console.error(res.msg);
        }
      });
    }
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const onCancel = () => {
    goBack();
  };
  const changeEditFlag = () => {
    setEditFlag(!editFlag);
    setTitle(editFlag ? '共享规则详情' : '编辑共享规则');
    setFetchFlag(editFlag);
  };
  const goBack = () => {
    if (id > 0 && editFlag) {
      setEditFlag(false);
      setTitle('共享规则详情');
      setFetchFlag(false);
    } else {
      history.go(-1);
    }
  };
  const rightTopBtns = <>
    <Button size="small" onClick={goBack}>返回</Button>&emsp;
    {!editFlag && <><Button size="small" onClick={changeEditFlag}>{editFlag ? '取消' : '编辑'}</Button>&emsp;</>}
  </>;
  return <Fragment>
    <div className="app-body">
      <Tabs defaultActiveKey="1" className="gray" tabBarExtraContent={rightTopBtns}>
        <TabPane tab={<span>&emsp;{title}&emsp;</span>} key="1" />
      </Tabs>
      <div className="form-box-m">
        <Form
          name="basic"
          form={form}
          layout="vertical"
          hideRequiredMark={!editFlag}
          onFinish={onFinish}
          // initialValues={detailData}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="共享规则名称"
            name="name"
            rules={[{ required: true, message: '请输入共享规则名称，最多20个字符' }]}
          >
            <Input disabled={!editFlag} placeholder="请输入共享规则名称，最多20个字符" />
          </Form.Item>

          <Form.Item
            label="共享的数据"
            name="sharingType"
            rules={[{ required: true, message: '请选择共享的数据' }]}
          >
            <Select disabled={!editFlag} placeholder="请选择共享的数据">
              {shareData.map(x => <Option key={x.code} value={x.code}>{x.name}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            label="共享后的权限"
            name="power"
            rules={[{ required: true, message: '请输入共享的数据' }]}
          >
            <Radio.Group disabled={!editFlag}>
              <Radio value={0}>只读</Radio>
              <Radio value={1}>读写</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="数据来源于"
            name="addResource"
            rules={[{ required: true, message: '请输入共享的数据' }]}
          >
            <ComplexSelect disabled={!editFlag}
                           ref={selectEl1}
                           sourceModalRef={modalEl1}
                           placeholder="选择员工、部门、角色或用户组" />
          </Form.Item>

          <Form.Item
            label="数据共享到"
            name="addSharingTo"
            rules={[{ required: true, message: '请输入共享的数据' }]}
          >
            <ComplexSelect disabled={!editFlag}
                           ref={selectEl2}
                           sourceModalRef={modalEl2}
                           placeholder="选择员工、部门、角色或用户组" />
          </Form.Item>

          <Form.Item
            label="备注"
            name="remarks"
            rules={[{ required: false, message: 'Please input your username!' }]}
          >
            <TextArea disabled={!editFlag} rows={4} />
          </Form.Item>

          {editFlag && <Form.Item>
            <Button onClick={onCancel}>
              取消
            </Button>
            <span>&emsp;&emsp;</span>
            <Button type="danger" htmlType="submit">
              提交
            </Button>
          </Form.Item>}
        </Form>
      </div>
    </div>
    <StaffAndOrgModal
      ref={modalEl1}
      destComRef={selectEl1}
      title="共享数据来源于" />
    <StaffAndOrgModal
      ref={modalEl2}
      destComRef={selectEl2}
      title="共享数据共享到" />
  </Fragment>;
}

export default ShareRuleDetail;
