import React from 'react';
import { connect } from 'dva';
import {
  Menu,
  Layout,
  Button,
  Dropdown,
  Breadcrumb,
} from 'antd';
import {
  DownOutlined,
  UserOutlined,
  LockOutlined,
  HomeOutlined,
  ReloadOutlined,
  PoweroffOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Link,
  routerRedux,
} from 'dva/router';
import classNames from 'classnames/bind';
import { AsynMenu } from 'components';
import { storage } from 'utils';
import PropTypes from 'prop-types';
import routeConfig from 'src/config/route.config.js';
import styles from './baseLayout.module.less';
import { controllerFile } from 'controller';
import {
  Spin,
  message,
} from 'antd';
import controllerUserAPI from 'controller/userAPI';
import controllerUser from 'controller/user';
import ModifyPwd from './ModifyPwd';

const { Header, Content, Footer, Sider } = Layout;
// const getWindowHeight = t => window.outerHeight > window.innerHeight ? window.outerHeight : window.innerHeight;
const getWindowHeight = t => document.body.clientHeight;
const cx = classNames.bind(styles)
let addEvent = function () {
  let me = this;
  window.MenuAnchorTo = (code, url) => {
    const { menusObj } = me.props;
    const key = /^\//.test(code) ? code : `/${code}`;
    const menu = menusObj[key];
    if (menu) {
      me.AsynMenu.selectFn(code);
      storage.set('activeMenu', menu);
      if (url) me.refs.frame.contentWindow.location.href = url;
    }
  };
  window.HideAsynMenu = t => {
    me.setState({ sider: false });
  };
  window.addEventListener('message', msg => {
    if (new RegExp(`${window.location.hostname}|10.0.4.57`).test(event.origin)) {
      try {
        eval(msg.data);
      } catch (error) {
        console.error(error);
      }
    }
  }, false);
};

class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
    const me = this;
    me.state = {
      collapsed: false,
      // 当前用户角色列表
      roles: [
        { 'userId': 1, 'roleId': 1, 'roleName': '管理员' },
        { 'userId': 1, 'roleId': 85, 'roleName': 'CRM销售经理' },
      ],
      // 当前活跃角色
      activeRole: {},
      wHeight: getWindowHeight(),
      // 菜单显示状态
      sider: true,
    };
    me.resizeHandle();
    // 获取用户角色
    me.getUserRole();
    me.onRoleMenuClick = me.onRoleMenuClick.bind(me);
  }

  componentWillMount() {
    addEvent.call(this);
  }

  // 获取用户角色
  getUserRole(userId) {
    // 用户登录信息
    const loginInfo = storage.get('loginInfo');
    controllerUserAPI.getUserRole({
      userId: loginInfo.userId,
    }).then(res => {
      if (res.code === __SUCCESS__) {
        this.setState({
          roles: res.data.userRoles,
        });
        // 获取用户角色菜单
        this.getRoleResourceList(this.findActiveRoleId(loginInfo.roleId));
      } else {
        message.error(res.message || '角色获取失败!');
      }
    });
  };

  // 获取左侧资源菜单
  getRoleResourceList(roleId) {
    // 获取菜单
    this.props.dispatch({
      type: 'common/getAllResourceListFn',
      payload: {
        'roleId': roleId,
        'supId': 1, // pc 菜单 id
      },
    }).then(res => {
      if (res.code !== __SUCCESS__) {
        message.error(res.message || '菜单获取失败!');
      }
    });
  };

  // 查找角色
  findRoleById(roleId) {
    const { roles } = this.state;
    return roles.find(r => r.roleId === roleId);
  };

  // 匹配当前活跃角色id
  findActiveRoleId(roleId) {
    const me = this;
    // 优先读缓存
    let role = storage.get('activeRole');
    // 匹配当前角色
    if (role) role = me.findRoleById(role.roleId);
    // 匹配不到缓存角色时,匹配传入角色
    if (!role) role = me.findRoleById(roleId);
    me.setActiveRole(role);
    return role.roleId;
  }

  resizeHandle() {
    const me = this;
    window.addEventListener('resize', t => {
      me.setState({
        wHeight: getWindowHeight(),
      });
    });
  }

  // 设置当前活跃角色
  setActiveRole(activeRole) {
    storage.set('activeRole', activeRole);
    this.setState({ activeRole });
  }

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  onSamePageClick = menu => {
    const frame = this.refs.frame;
    if (frame) frame.contentWindow.location.href = menu.url;
  };

  // 导航栏菜单按钮
  onMainMenuClick(e) {
    const { dispatch, home, routerTag } = this.props;
    switch (e.key) {
      case 'modify':
        this.refs.ModifyModal.showModal();
        break;
      case 'home':
        dispatch(routerRedux.push(home[routerTag]));
        break;
      case 'reload':
        window.location.reload();
        break;
      case 'exit':
        dispatch(routerRedux.push('/login'));
        dispatch({
          type: 'common/logoutFn',
          payload: {},
        });
        break;
      default:
        break;
    }
  }

  // 切换角色
  onRoleMenuClick(e) {
    const role = this.findRoleById(e.key * 1);
    this.setState({ sider: true });
    this.setActiveRole(role);
    // 切换角色
    controllerUser.setUserRole({
      roleId: role.roleId,
    }).then(res => {
      if (res.code === __SUCCESS__) {
        // 获取角色资源菜单
        this.getRoleResourceList(role.roleId);
        // 更新注册信息
        controllerUser.regist({
          'userName': storage.get('loginInfo').loginName,
        }).then(res => {
          if (res.code === __SUCCESS__) {
            // 获取角色资源菜单
            storage.set('registerInfo', res.data);
          } else {
            message.error(res.message || '更新注册信息失败!');
          }
        });
      } else {
        message.error(res.message || '角色切换失败!');
      }
      window.location.reload()
    });
  }

  onRef = (ref) => {
    this.AsynMenu = ref;
  };

  render() {
    const { location, menusObj, loading, homeConfig } = this.props;
    const { roles, activeRole, wHeight } = this.state;
    console.log('debug wHeight', wHeight);

    const siderHeight = wHeight - 64 - 46;
    // 用户登录信息
    const loginInfo = storage.get('loginInfo');
    const target = menusObj[location.pathname];
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const routeIndex = routeConfig.findIndex(x => {
        if (x.reg) {
          const reg = new RegExp(x.reg);
          return reg.test(url);
        }
        return x.path === url;
      });
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{routeConfig[routeIndex] && routeConfig[routeIndex].title}</Link>
        </Breadcrumb.Item>
      );
    });
    // 角色列表,切换角色
    const RoleMenu = () => {
      let MenuItem = roles.map(r => {
        return <Menu.Item key={r.roleId} icon={<UserOutlined />}>
          {r.roleName}
        </Menu.Item>;
      });
      return <Menu onClick={this.onRoleMenuClick.bind()}>
        {MenuItem}
      </Menu>;
    };
    // 主体区块
    let content = target && target.url && /^http/.test(target.url) ?
      <Content className={styles['content-box2']}>
        <iframe ref="frame"
                title="content"
                style={{ height: siderHeight }}
                src={target && target.url}></iframe>
      </Content>:
      <Content className={styles['content-box']}>
        <Breadcrumb className={styles['breadcrumb-cus']}>
          {extraBreadcrumbItems}
        </Breadcrumb>
        {this.props.children}
      </Content>;
    let bottomTitle = (homeConfig && homeConfig.bottomTitle && homeConfig.bottomTitle.confValue) || '@2019 云镖网络 版权所有 粤ICP备18130807号-1';
    let mainLogoId = (homeConfig && homeConfig.code === 0 && homeConfig.loginLogoId.confValue);
    let logoStyle = !!mainLogoId ? {
      'backgroundImage': `url(${controllerFile.download}/${mainLogoId})`,
      'backgroundRepeat': `no-repeat`,
      'backgroundPosition': `center`,
    } : null;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible
               trigger={null}
               collapsed={this.state.collapsed}
               onCollapse={this.onCollapse}
               className={styles['shadow-box']}>
          <div className={styles['sider-group']}>
            <div className={cx({logo: true, collapsed: this.state.collapsed})} style={logoStyle}></div>
            <div className={styles['menu-box']}>
              {
                this.state.sider && <AsynMenu onRef={this.onRef} onSamePageClick={this.onSamePageClick}></AsynMenu>
              }
            </div>
          </div>
        </Sider>

        <Layout className={styles['site-layout']}>
          <Header style={{ padding: 0 }}>
            &emsp;&nbsp;
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'ocss-trigger',
              onClick: this.onCollapse,
            })}
            &emsp;
            <Dropdown trigger="click" overlay={RoleMenu}>
              <Button type="text">
                {activeRole.roleName} <DownOutlined />
              </Button>
            </Dropdown>
            {/* <label className="pd10" style={{color: '#fff'}}>{loginInfo.loginName}</label> */}
            <Menu theme="" onClick={this.onMainMenuClick.bind(this)} mode="horizontal" className="fr pr10 no-border">
              <Menu.Item key="1">您好,{loginInfo.userName}</Menu.Item>
              <Menu.Item key="modify"><LockOutlined /></Menu.Item>
              <Menu.Item key="home"><HomeOutlined /></Menu.Item>
              <Menu.Item key="reload"><ReloadOutlined /></Menu.Item>
              <Menu.Item key="exit"><PoweroffOutlined /></Menu.Item>
            </Menu>
          </Header>
          {content}
          <Footer>
            <div className="mg-at fs12 gray tc">{bottomTitle}</div>
          </Footer>
        </Layout>

        <ModifyPwd ref="ModifyModal"></ModifyPwd>
        {loading.global ? <div className={styles['spin-wrapper']}><Spin className="absolute tr50 lr50"
                                                                        size="large"
                                                                        delay={100}
                                                                        tip="Loading..." /></div> : ''}
      </Layout>
    );
  }
}

BaseLayout.propTypes = {
  location: PropTypes.object.isRequired,
};
export default connect(({ common, loading }) => {
  return { ...common, loading };
})(BaseLayout);
