import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  message,
  PageHeader,
} from 'antd';
import CategorySetModal from './components/categorySetModal';
import ManageCategoryModal from './components/manageCategoryModal';
import CopyCategorySetModal from './components/copyCategorySetModal';
import { TableView } from 'components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { controllerDC } from 'controller';
import {
  stateMap,
  sourceMap,
} from './constVar';
const Option = Select.Option;
function CategorySet() {
  const modalRef1 = useRef(null);
  const modalRef2 = useRef(null);
  const modalRef3 = useRef(null);
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchObj, setSearchObj] = useState({ name: '', type: 1, p: 1, s: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await controllerDC.categorySet.list(searchObj);
      setIsLoading(false);
      setList(res.data || []);
      setTotal(res.total || 0);
      res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    fetchData();
  }, [searchObj]);
  const handleClick = () => {
    setShowForm(!showForm);
  };
  const setSetState = (record) => {
    controllerDC.categorySet.disable({
      id: record.id,
      state: record.state === 1 ? 2 : 1, // 状态：1-启用；2-禁用
    }).then(res => {
      res.code === __SUCCESS200__ && setSearchObj({ ...searchObj });
      res.code !== __SUCCESS200__ && message.error(res.msg);
    });
  };
  const optClickHandle = (record, act) => {
    console.log('debug record: ', record);
    switch (act) {
      case 'edit':
        modalRef1.current.showModal(record);
        break;
      case 'valid': // 启用/禁用
        setSetState(record);
        break;
      case 'manage':
        modalRef3.current.showModal(record);
        break;
      default:
        break;
    }
  };
  const pageChange = (page, pageSize) => {
    setSearchObj({
      ...searchObj,
      p: page,
      s: pageSize,
    });
  };
  const onSearch = (values) => {
    setSearchObj({
      ...searchObj,
      ...values,
      p: 1,
    });
  };
  const resetSearch = () => {
    setSearchObj(data => ({
      ...data,
      code: '',
      name: '',
      valid: undefined,
      page: 1,
    }));
    form.setFieldsValue({
      code: '',
      name: '',
      valid: undefined,
    });
  };
  const tableProps = {
    rowKey: 'id',
    loading: isLoading,
    columns: [
      {
        title: '代码',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: '商品分类集名称',
        dataIndex: 'name',
        key: 'name',
      },
      /*{
       title: '是否系统基本分类',
       dataIndex: 'type',
       key: 'type',
       },*/
      {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        render: (val) => stateMap[val] || '',
      },
      {
        title: '来源',
        dataIndex: 'source',
        key: 'source', // 来源 1系统 2用户
        render: (val) => sourceMap[val] || '',
      },
      {
        title: '创建人',
        dataIndex: 'createdName',
        key: 'createdName',
      },
      {
        title: '创建时间',
        dataIndex: 'createdOn',
        key: 'createdOn',
      },
      {
        title: '操作',
        width: 228,
        align: 'center',
        render: (val, record, index) => <Fragment>
          {(record.source !== 1) && <Button type="link" onClick={() => optClickHandle(record, 'edit')}>编辑</Button>}
          {(record.source !== 1) && <Button type="link"
                                            onClick={() => optClickHandle(record, 'valid')}>{record.state === 1 ? '禁用' : '启用'}</Button>}
          <Button type="link" style={record.source === 1 ? {float: 'right'} : null} onClick={() => optClickHandle(record, 'manage')}>分类管理</Button>
        </Fragment>,
      },
    ],
    rows: list,
    pageParam: {
      total: total,
      current: searchObj.p,//当前页码
      pageSize: searchObj.s,//当前分页数
      onChange: pageChange,
    },
  };
  return <Fragment>
    <div className="app-body">
      <PageHeader
        ghost={false}
        className="gray-header"
        title={<>
          <Button type="gray" onClick={() => modalRef1.current.showModal(searchObj)}>新增分类集</Button>
          &emsp;
          <Button type="gray" onClick={() => modalRef2.current.showModal(searchObj.type)}>复制分类集</Button>
        </>}
        extra={[
          <Button className="pos-bottom"
                  key="1"
                  onClick={handleClick}
                  icon={<DoubleRightOutlined rotate={showForm ? 270 : 90} />}>{showForm ? '关闭查询' : '展开查询'}</Button>,
        ]}
      />
      <Form
        form={form}
        name="basic"
        className="top-search-box"
        style={{ display: showForm ? 'block' : 'none' }}
        initialValues={searchObj}
        onFinish={onSearch}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="分类集类型"
              name="type"
            >
              <Select>
                <Option value={1}>商品分类集</Option>
                <Option value={2}>客户分类集</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="分类集名称"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>&nbsp;</Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button type="danger" htmlType="submit">
              搜索
            </Button>
            &emsp;
            <Button onClick={resetSearch} style={{ margin: '0 8px' }}>
              重置
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="page-main-box">
        <TableView {...tableProps} />
      </div>
    </div>
    <CategorySetModal ref={modalRef1} refreshList={onSearch}></CategorySetModal>
    <CopyCategorySetModal ref={modalRef2} refreshList={onSearch}></CopyCategorySetModal>
    <ManageCategoryModal ref={modalRef3} refreshList={onSearch}></ManageCategoryModal>
  </Fragment>;
}

export default CategorySet;
