/**
 * org-controller : 云镖组织管理
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'addOrg': { 'path': '/uc/org/v6/addOrg', 'method': 'POST', 'desc': '添加组织' },
  'deleteOrg': { 'path': '/uc/org/v6/deleteOrg', 'method': 'POST', 'desc': '删除菜单' },
  'getLastOrg': { 'path': '/uc/org/v6/getLastOrg', 'method': 'POST', 'desc': '获取组织下一级组织' },
  'getOrgInfo': { 'path': '/uc/org/v6/getOrgInfo', 'method': 'POST', 'desc': '获取组织' },
  'getOrgList': { 'path': '/uc/org/v6/getOrgList', 'method': 'POST', 'desc': '组织信息' },
  'getOrgTree': { 'path': '/uc/org/v6/getOrgTree', 'method': 'POST', 'desc': '获取组织树' },
  'getUserOrg': { 'path': '/uc/org/v6/getUserOrg', 'method': 'POST', 'desc': '获取当前人员主组织' },
  'queryOrgList': { 'path': '/uc/org/v6/queryOrgList', 'method': 'PUT', 'desc': 'MXM获取组织信息' },
  'updateRole': { 'path': '/uc/org/v6/updateRole', 'method': 'POST', 'desc': '修改组织' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
