/**
 * dms-order-controller : 订单接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'productIsEdit': { 'path': '/dms/api/v1/dms/order/productIsEdit', 'method': 'GET', 'desc': '根据产品id判断是否允许编辑 是否启用批次 & 是否启用序列号'},
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params,
      };
      return request(options);
    };
  });
})();
export default controller;
