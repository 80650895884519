import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  message,
} from 'antd';
// import style from './style.module.less';
import {
  controllerProdCenter,
  controllerDC,
} from 'controller';

const { Option } = Select;

function MyListSelect({ value, onChange, antdSelectProps, keyField, titelField, dataType, params }) {
  const [result, setResult] = useState(value || undefined);
  const [optionsData, setOptionsData] = useState([]);
  const [prevParams, setPrevParams] = useState(null);
  if (params && JSON.stringify(params) !== JSON.stringify(prevParams)) {
    setPrevParams(params);
  }
  useEffect(() => {
    setResult(value || undefined);
  }, [value]);
  useEffect(() => {
    const fetchData = async () => {
      let fetchFun = null;
      switch (dataType) {
        case 'unit':
          fetchFun = controllerProdCenter.unit.list;
          break;
        case 'brand':
          fetchFun = controllerProdCenter.brand.list;
          break;
        case 'labelSet':
          fetchFun = controllerDC.labelSet.list;
          break;
        case 'categorySet':
          fetchFun = controllerDC.categorySet.list;
          break;
        case 'productType': //产品类型
          fetchFun = controllerProdCenter.product.getAllTypeList;
          break;
        default:
          fetchFun = controllerProdCenter.unit.list;
          break;
      }
      const res = await fetchFun(prevParams);
      setOptionsData(res.data || []);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    if(prevParams) {
      fetchData();
    }
  }, [dataType, prevParams]); // 此处禁止了eslint自动补全依赖列表，减少请求次数
  const triggerChange = (res) => {
    if (onChange) {
      onChange(res);
    }
  };
  const handleChange = (value, label, extra) => {
    setResult(value);
    triggerChange(value);
  };
  return <Select {...antdSelectProps} value={result} onChange={handleChange}>
    {(optionsData || []).map(item => <Option key={item[keyField]} value={item[keyField]}>{item[titelField]}</Option>)}
  </Select>;
}

MyListSelect.propTypes = {
  params: PropTypes.object, // 接口参数
  dataType: PropTypes.string, // 数据类型，商品单位、商品品牌等
  keyField: PropTypes.string, // antd tree 组件参数 key 对应的字段
  titelField: PropTypes.string, // antd tree 组件参数 title 对应的字段
  onChange: PropTypes.func,
  antdSelectProps: PropTypes.object, // antd TreeSelect 组件参数，参考：https://ant.design/components/tree-select-cn/#API
};
MyListSelect.defaultProps = {
  dataType: 'unit',
  params: { code: '', name: '', p: 1, s: 10000 },
  keyField: 'id', // antd Select 组件参数 value 对应的字段
  titelField: 'name', // antd Select 组件参数 title 对应的字段
};
// export default forwardRef(MyTree);
export default MyListSelect;
