/**
 * dc-var-label-collect-controller : 全局标签集调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'copy': { 'path': '/var/v1/labelSet/copy', 'method': 'POST', 'desc': '按ID复制标签集' },
  'detail': { 'path': '/var/v1/labelSet/detail', 'method': 'POST', 'desc': '按ID获取标签集详情' },
  'disable': { 'path': '/var/v1/labelSet/disable', 'method': 'POST', 'desc': '按ID禁用标签集' },
  'list': { 'path': '/var/v1/labelSet/list', 'method': 'POST', 'desc': '按条件获取标签集列表' },
  'saveOrUpdate': { 'path': '/var/v1/labelSet/saveOrUpdate', 'method': 'POST', 'desc': '新增或者修改标签集' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
