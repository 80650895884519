/**
 * reg 和 title 不是react-router的属性，这两个字段用于处理面包屑导航。
 * 具体用法请查看代码 src/layouts/BaseLayout.js
 * */
module.exports = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    models: () => [require('../pages/login/models').default],
    component: () => require('../pages/login').default,
  },
  {
    path: '/home',
    name: 'home',
    component: () => require('../pages/home').default,
  },
  {
    path: '/crm_visitApply',
    name: 'crm_visitApply',
    title: '拜访申请',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/custom/crm_visitApply').default,
  },
  {
    path: '/applicationManage',
    name: 'applicationManage',
    title: '应用管理',
    models: () => [require('../pages/applicationManage/models').default],
    component: () => require('../pages/applicationManage').default,
  },
  {
    path: '/applicationManage/:id',
    name: 'appDetail',
    reg: '^\\/applicationManage\\/\\d+$',
    title: '应用详情',
    component: () => require('../pages/applicationManage/form').default,
  },
  {
    path: '/shareRulesManage',
    name: 'shareRulesManage',
    title: '共享规则管理',
    models: () => [require('../pages/shareRulesManage/models').default],
    component: () => require('../pages/shareRulesManage').default,
  },
  {
    path: '/shareRulesManage/:id',
    name: 'shareRuleDetail',
    reg: '^\\/shareRulesManage\\/\\d+$',
    title: '共享规则详情',
    component: () => require('../pages/shareRulesManage/form').default,
  },
  {
    path: '/brand',
    name: 'brand',
    title: '商品品牌',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/brand').default,
  },
  {
    path: '/categorySet',
    name: 'categorySet',
    title: '分类集管理',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/categorySet').default,
  },
  {
    path: '/labelSet',
    name: 'labelSet',
    title: '标签集管理',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/labelSet').default,
  },
  {
    path: '/sparebom',
    name: 'sparebom',
    title: '维修BOM',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/sparebomManage/sparebom').default,
  },
  {
    path: '/unit',
    name: 'unit',
    title: '商品单位',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/unit').default,
  },
  {
    path: '/specification',
    name: 'specification',
    title: '商品规格',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/specification').default,
  },
  {
    path: '/mchManage',
    name: 'mchManage',
    title: '商品管理',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/mchManage').default,
  },
  {
    path: '/mchManage/:id',
    name: 'mchManage',
    reg: '^\\/mchManage\\/\\d+$',
    title: '商品详情',
    component: () => require('../pages/merchandiseCenter/mchForm').default,
  },
  {
    path: '/deviceManage',
    name: 'deviceManage',
    title: '设备管理',
    // models: () => [require('../pages/merchandiseCenter/models').default],
    component: () => require('../pages/merchandiseCenter/deviceManage').default,
  },
  {
    path: '/contractManage',
    name: 'contractManage',
    title: '合同管理',
    models: () => [require('../pages/contractManage/models').default],
    component: () => require('../pages/contractManage').default,
  },
  {
    path: '/contractManage/:id',
    name: 'contractManage',
    // reg: '^\\/contractManage\\/\\d+$',
    title: '合同详情',
    component: () => require('../pages/contractManage/form').default,
  },
];
