import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { storage } from 'utils';
import controllerUser from 'controller/user';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

class ModifyPwd extends React.Component {
  
  constructor() {
    super();
    this.state = { 
      visible: false,
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  // 提交
  handleSubmit() {
    this.refs.form.submit();
  }

  render() {
    const onFinish = values => {
      this.setState({
        loading: true
      });
      const loginInfo = storage.get('loginInfo');
      // 提交修改
      controllerUser.updatePwd({
        loginName: loginInfo.loginName,
        oldPassword: values.oldPassword,
        password: values.newPassword,
        confirmPassword: values.confirmPassword
      }).then(res => {
        let visible = false;
        if (res.code === __SUCCESS__) {
          message.success('修改成功!');
        } else {
          visible = true;
          message.error(res.message || '修改失败!');
        }
        this.setState({
          visible: visible,
          loading: false
        });
      })
    };
  
    return (
      <div>
        <Modal
          title="修改密码"
          cancelText="取消"
          okText="确认"
          confirmLoading={this.state.loading}
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
        >
          <Form
            {...layout}
            ref="form"
            name="basic"
            onFinish={onFinish}
          >
            <Form.Item
              label="旧密码"
              name="oldPassword"
              rules={[{ required: true, message: '请输入旧密码!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="新密码"
              name="newPassword"
              rules={[
                { required: true, message: '请输入新密码!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                      // 正则
                     var reg= /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{8,}$/ ;
                     if(reg.test(value)){
                       return Promise.resolve();
                     }else{
                       return Promise.reject('密码必须8位数以上，数字+字母+特殊字符组合，列如12345678a+');
                     }
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="新密码确认"
              name="confirmPassword"
              rules={[
                { required: true, message: '请再次输入新密码!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      // 正则
                     var reg= /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{8,}$/ ;
                     if(reg.test(value)){
                       return Promise.resolve();
                     }else{
                       return Promise.reject('密码必须8位数以上，数字+字母+特殊字符组合，列如12345678a+');
                     }
                    } else {
                      return Promise.reject('请检查两次新密码不一致!');
                    }
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
          
        </Modal>
      </div>
    );
  }
}

export default ModifyPwd;