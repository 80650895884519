/**
 * dc-var-dictionaryItem-controller : 全局数据字典调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'listByKey': { 'path': '/var/v1/dictionaryItem/listByKey', 'method': 'POST', 'desc': '获取数据字典项' },
};
const controller = {
  dictKey: {
    LABEL_USE_OBJ: 'LABEL_USE_OBJ', // 标签集使用对象
  }
};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
