/**
 * dc-var-sort-collect-controller : 分类集调用接口
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'list': { 'path': '/cs-web/api/v1/cs/csSpareBom/listPc', 'method': 'POST', 'desc': '维修bom列表' },
  'insertOrUpdate': { 'path': '/cs-web/api/v1/cs/csSpareBom/insertOrUpdate', 'method': 'POST', 'desc': '新增修改维修BOM' },
  'listPc': { 'path': '/cs-web/api/v1/cs/csSpareBom/listPc', 'method': 'POST', 'desc': 'PC备件列表' },
  'enableOrDisable': { 'path': '/cs-web/api/v1/cs/csSpareBom/enableOrDisable', 'method': 'POST', 'desc': '按ID禁用BOM' },
  'addOrUpdateBomItem': { 'path': '/cs-web/api/v1/cs/csSpareBom/addOrUpdateBomItem', 'method': 'POST', 'desc': '添加或者修改BOM下部件' },
  'bomItemTree': { 'path': '/cs-web/api/v1/cs/csSpareBom/bomItemTree/', 'method': 'GET', 'desc': '获取BOM下部件树' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      return request(options);
    };
  });
})();
export default controller;
