import React from 'react';
import {
  Router,
  Route,
} from 'dva/router';
import App from './App';

function RouterConfig({ history, app }) {
  return (
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>);
}

export default RouterConfig;
