/**
 * @author umin
 * @date 2020/06/13
 * @description 工具函数
 */
class Tools {

  /**
   * json 字符串转 object
   * @param json json 字符串
   */
  parse(json) {
    try {
      return typeof json === 'string' ? JSON.parse(json) : json;
    } catch (e) {
      console.error('字符串转JSON对象失败！' + e);
      return {
        status: 'json parse fail！'
      };
    }
  };

  /**
   * 转数组对象
   */
  toArray(o) {
    return o === undefined ? [] : this.isArray(o) ? o : [o];
  };

  /**
   * 转时间对象
   */
  toDate(date) {
    return typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : date;
  };

  /**
   * 判断是否数组
   * @param o
   * @returns {*|boolean}
   */
  isArray(o) {
    let ots = Object.prototype.toString;
    return o && (o.constructor === Array || ots.call(o) === '[object Array]');
  };

  /**
   * 判断是否对象
   * @param o
   * @returns {*|boolean}
   */
  isObject(o) {
    return typeof o === 'object';
  };

  /**
   * 判断是否布尔
   * @param o
   * @returns {*|boolean}
   */
  isBoolean(o) {
    return typeof o === 'boolean' || (o.constructor === Boolean);
  };

  /**
   * 判断是否数字
   * @param o
   * @returns {*|boolean}
   */
  isNumber(o) {
    return typeof o === 'number' || (o.constructor === Number);
  };

  /**
   * 判断是否 undefined
   * @param o
   * @returns {*|boolean}
   */
  isUndefined(o) {
    return o === undefined;
  };

  /**
   * 判断是否 null
   * @param o
   * @returns {*|boolean}
   */
  isNull(o) {
    return o === null;
  };

  /**
   * 是否无效值
   * @param o
   * @returns {*|boolean}
   */
  isInvalidValue(o) {
    return this.isNull(o) || this.isUndefined(o);
  };

  /**
   * 判断是否方法
   * @param o
   * @returns {*|boolean}
   */
  isFunction(o) {
    return typeof o === 'function' || (o.constructor === Function);
  };

  /**
   * 判断是否字符串
   * @param o
   * @returns {boolean|*}
   */
  isString(o) {
    return typeof o === 'string' || (o.constructor === String);
  };

  /**
   * 是否空对象
   * @param obj 对象
   * @returns {boolean} true / false
   */
  isEmptyObject(obj) {
    for (let n in obj) {
      return false;
    }
    return true;
  };

  /**
   * 锚点日志信息
   */
  info(...args) {
    let normalStyle = 'padding:1px; color:#fff; background:#35495e; border-radius: 3px 0 0 3px;';
    let content = args.pop().split(' ');
    let style = normalStyle + (args.pop() || 'background:#41b883; border-radius: 0 3px 3px 0;');
    console.log('%c ' + content.join(' %c ') + ' %c',
      normalStyle,
      style,
      'background:transparent');
  };
}

export default new Tools();