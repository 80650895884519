/**
 * dc-*-controller : 数据中心
 */
import label from './label';
import labelSet from './labelSet';
import category from './category';
import categorySet from './categorySet';
import dictItem from './dictionaryItem';

export default {
  label,
  dictItem,
  labelSet,
  category,
  categorySet,
};
