import React from 'react';
import { Tree } from 'antd';
import { controllerOrg } from 'controller';

// 将 children 插入treeData
function updateTreeData(list, key, children) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children };
    }
    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children) };
    }
    return node;
  });
}

class AsynTree extends React.Component {
  state = {
    treeData: [],
  };
  componentDidMount() {
    controllerOrg.getOrgTree({orgId: 0}).then(res => {
      if (res.code === __SUCCESS__) {
        this.setState({treeData: res.data});
      } else {
        console.error(res.msg);
      }
    })
  }

  onLoadData = treeNode => {
    return new Promise(resolve => {
      if (treeNode.children) {
        resolve();
        return;
      }
      // 模拟接口请求
      setTimeout(() => {
        const children = [
          {
            title: 'Child Node',
            key: `${treeNode.key}-0`,
          },
          {
            title: 'Child Node',
            key: `${treeNode.key}-1`,
          },
        ];
        const list = updateTreeData(this.state.treeData, treeNode.key, children);
        this.setState({
          treeData: list,
        }, () => {
          resolve();
        });
      }, 1000);
    });
  };

  handleSelect = (selectedKeys, e) => {
    const { selected, selectedNodes, node, event } = e;
    console.log('debug', selected, selectedNodes, node, event);
    if(this.props.onSelect && typeof this.props.onSelect === 'function') {
      this.props.onSelect(selectedNodes)
    }
  }
  render() {
    const { treeData } = this.state;
    // const loop = data =>
    //   data.map(item => {
    //     if (item.children) {
    //       return {
    //         ...item,
    //         key: item.id,
    //         title: item.name,
    //         children: loop(item.children)
    //       };
    //     }
    //     return {
    //       ...item,
    //       key: item.id,
    //       title: item.name,
    //     };
    //   });
    return <Tree loadData={this.onLoadData} treeData={treeData} onSelect={this.handleSelect} />;
  }
}

export default AsynTree;
