/**
 * file-controller : 附件管理
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';

const apiInfo = {
  'upload': { 'path': '/fc/file/v6/upload', 'method': 'POST', 'desc': '上传文件' },
};
const controller = {
  fileUrlHead: `${service.host}/fc`,
  fileViewUrlHead: `${service.host}/fc/file/v6/download/`,
  uploadUrl: `${service.host}${apiInfo.upload.path}`,
  download: `${service.host}/fc/file/v6/id`
};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };
      if(key === 'upload') {

      }
      return request(options);
    };
  });
})();
export default controller;
