import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  message,
} from 'antd';
import { controllerDC } from 'controller';

const { Option } = Select;

function CopyLabelSetModal({ refreshList }, ref) {
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchObj] = useState({ name: '', type: 1, p: 1, s: 200 });
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    showModal: () => {
      setVisible(true);
      getSourceList();
    },
  }));
  const getSourceList = () => {
    controllerDC.labelSet.list(searchObj).then(res => {
      setList(res.data || []);
      res.code !== __SUCCESS200__ && message.error(res.msg);
    });
  };
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = () => {
    form.validateFields().then(values => {
      const sourceSet = list.filter(x => x.id === values.id)[0]; // 标签集来源
      const params = {
        name: values.name,
        id: sourceSet.id,
        useObj: sourceSet.useObj,
        source: sourceSet.source,
        state: sourceSet.state,
        remark: sourceSet.remark,
        // isDeleted: sourceSet.isDeleted,
      };
      controllerDC.labelSet.copy(params).then(res => {
        if (res.code === __SUCCESS200__) {
          if (refreshList && typeof refreshList === 'function') {
            refreshList();
          }
          onCancel();
        } else {
          message.error(res.message);
        }
      });
    });
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const modalProps = {
    title: '复制分类集',
    visible,
    width: 600,
    cancelText: '取消',
    okButtonProps: { type: 'danger' },
    okText: '确认',
    onOk: onFinish,
    onCancel: onCancel,
  };
  return <Modal {...modalProps}>
    <Form
      {...layout}
      form={form}
      name="basic"
    >

      <Form.Item
        label="标签集来源"
        name="id"
        rules={[{ required: true, message: '请选择标签集来源' }]}
      >
        <Select>
          {list.map(x => <Option key={x.id} value={x.id}>{x.name}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item
        label="目标标签集名称"
        name="name"
        rules={[
          { required: true, message: '请输入名称' },
          { type: 'string', max: 50, message: '不超过50个字符' },
        ]}
      >
        <Input />
      </Form.Item>

    </Form>
  </Modal>;
}

export default forwardRef(CopyLabelSetModal);
