/**
 * resource-controller : 云镖菜单管理
 *
 * POST /resource/v6/addResource 添加菜单
 * POST /resource/v6/allResourceList 获取所有菜单信息递归查询
 * POST /resource/v6/deleteSource 删除菜单
 * POST /resource/v6/getRoleList 菜单信息
 * POST /resource/v6/getSourceList 获取所有菜单
 * POST /resource/v6/updateResource 修改菜单
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';
const apiInfo = {
  'addResource': { 'path': '/uc/resource/v6/addResource', 'method': 'POST', 'desc': '添加菜单' },
  'allResourceList': { 'path': '/uc/resource/v6/allResourceList', 'method': 'POST', 'desc': '获取所有菜单信息递归查询' },
  'deleteSource': { 'path': '/uc/resource/v6/deleteSource', 'method': 'POST', 'desc': '删除菜单' },
  'getRoleList': { 'path': '/uc/resource/v6/getRoleList', 'method': 'POST', 'desc': '菜单信息' },
  'getSourceList': { 'path': '/uc/resource/v6/getSourceList', 'method': 'POST', 'desc': '获取所有菜单' },
  'updateResource': { 'path': '/uc/resource/v6/updateResource', 'method': 'POST', 'desc': '修改菜单' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };

      return request(options);
    };
  })
})();

export default controller;
