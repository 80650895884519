/**
 * 应用管理
 */
// import { controllerUser } from 'controller';
import controllerUser from 'controller/user';

export default {
  namespace: 'applicationManage',
  state: {
    loginInfo: {},
  },
  subscriptions: {
    init({ dispatch, history }) {
      history.listen((location) => {
        if (location.pathname === '/applicationManage') {
          dispatch({
            type: 'loginFn',
            payload: {
              'captcha': '111111',
              'loginName': 'admin',
              'password': '12345678',
              'rememberMe': true,
              'source': 'pc',
            },
          });
        }
      });
    },
  },
  effects: {
    * loginFn({ payload }, { call, put }) {
      const response = yield call(controllerUser.login, payload);
      if (response.code === __SUCCESS__) {
        yield put({
          type: 'updateState',
          payload: {
            loginInfo: response.data, // corTicket
          },
        });
      }
    },
    * logoutFn({ payload }, { call, put }) {
      const response = yield call(controllerUser.logout, payload);
      if (response.code === __SUCCESS__) {
        yield put({
          type: 'updateState',
          payload: {
            loginInfo: {}, // corTicket
          },
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
