import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  TreeSelect,
  message,
} from 'antd';
import {
  controllerOrg,
  controllerDC,
} from 'controller';

const { TreeNode } = TreeSelect;
function StrangeTreeSelect({ value, onChange, antdTreeSelectProps, keyField, titelField, displayField, childrenField, treeType, params }) {
  const [result, setResult] = useState(value || undefined);
  const [treeData, setTreeData] = useState([]);
  const [prevParams, setPrevParams] = useState(null);
  if (params && JSON.stringify(params) !== JSON.stringify(prevParams)) {
    setPrevParams(params);
  }
  useEffect(() => {
    setResult(value || undefined);
  }, [value]);
  useEffect(() => {
    const fetchData = async () => {
      let fetchFun = null;
      switch (treeType) {
        case 'org':
          fetchFun = controllerOrg.getOrgTree;
          break;
        case 'category':
          fetchFun = controllerDC.category.categoryTree;
          if (!prevParams.categorySetCode) return;
          break;
        case 'label':
          fetchFun = controllerDC.label.labelTree;
          break;
        default:
          fetchFun = controllerOrg.getOrgTree;
          break;
      }
      const res = await fetchFun(prevParams);
      setTreeData(res.data || []);
      res.code !== __SUCCESS__ && res.code !== __SUCCESS200__ && message.error(res.msg);
    };
    if (!!prevParams) {
      fetchData();
    }
  }, [prevParams, treeType]); // 此处禁止了eslint自动补全依赖列表，减少请求次数
  const triggerChange = (res) => {
    if (onChange) {
      onChange(res);
    }
  };
  const handleChange = (value, label, { triggerNode }) => {
    setResult(value);
    triggerChange(value);
  };
  const resultKeys = antdTreeSelectProps.multiple === false ? [] : (result || []).map(x => x.value || x); // 选择的值，兼容labelInValue
  const loop = (data, brotherKeys=[], isRadio) => data.map(item => {
    if (item[childrenField] && item[childrenField].length > 0) {
      return <TreeNode key={item[keyField]}
                       value={item[keyField]}
                       title={`${item[titelField]}${!!item[displayField] ? ' (单选)' : ''}`}
                       checkable={false}
                       selectable={false}
                       extra={item}>
        {loop(item[childrenField], item[childrenField].map(x => x[keyField]), !!item[displayField])}
      </TreeNode>;
    }
    delete item[childrenField];
    let flag = false;
    if(antdTreeSelectProps.multiple !== false && !!brotherKeys.length && !!resultKeys.length && isRadio) {
      // 单选标签控制，选中一个则其他项目不可选择
      flag = brotherKeys.filter(x => x !== item[keyField]).some(x => resultKeys.includes(x));
    }
    return <TreeNode key={item[keyField]}
                     value={item[keyField]}
                     title={item[titelField]}
                     disableCheckbox={flag}
                     extra={item}
                     isLeaf />;
  });
  const selectTreeProps = {
    value: result,
    treeCheckable: true,
    multiple: true,
    treeDefaultExpandAll: true,
    dropdownStyle: { maxHeight: 400, overflow: 'auto' },
    onChange: handleChange,
    ...antdTreeSelectProps,
  };
  return <TreeSelect {...selectTreeProps}>
    {loop(treeData)}
  </TreeSelect>;
}

StrangeTreeSelect.propTypes = {
  params: PropTypes.object, // 接口参数
  treeType: PropTypes.string, // 树类型，组织树、分类树等
  displayField: PropTypes.string, // 控制显示效果的字段
  keyField: PropTypes.string, // antd tree 组件参数 key 对应的字段
  titelField: PropTypes.string, // antd tree 组件参数 title 对应的字段
  childrenField: PropTypes.string, // antd tree 组件参数 children 对应的字段
  onChange: PropTypes.func,
  antdTreeSelectProps: PropTypes.object, // antd TreeSelect 组件参数，参考：https://ant.design/components/tree-select-cn/#API
};
StrangeTreeSelect.defaultProps = {
  treeType: 'label',
  params: { labelSetId: 1 },
  keyField: 'id', // antd tree 组件参数 key 对应的字段
  titelField: 'name', // antd tree 组件参数 title 对应的字段
  childrenField: 'children', // antd tree 组件参数 children 对应的字段
  displayField: 'isRadio', // 控制选择框展示效果的字段，效果有：Checkbox、Radio
};
// export default forwardRef(StrangeTreeSelect);
export default StrangeTreeSelect;
