import React from 'react'
import PropTypes from 'prop-types';
import {Table} from 'antd';//Pagination

//表格组件
const TableView = (props) => {
  const {rowSelection, pageParam, rows, columns, loading, style, rowKey, scroll, size, antdTableProps} = props;
  const sizeChange = (current, size) => {
    pageParam.onChange(1, size);
  }
  const antdTablePropsMeger = {
    ...antdTableProps,
    style
  }
  const pagination = {
    total: pageParam.total,
    pageSize: pageParam.pageSize,
    pageSizeOptions: pageParam.pageSizeOptions ? pageParam.pageSizeOptions.map(x => x + '') : ['1', '10', '20', '30', '40', '50'],
    showTotal: total => `共 ${total} 条`,
    showSizeChanger: pageParam.showSizeChanger !== undefined ? pageParam.showSizeChanger : true,
    showQuickJumper: pageParam.showQuickJumper !== undefined ? pageParam.showQuickJumper : true,
    onShowSizeChange: sizeChange,
    onChange: pageParam.onChange,
    size: "small"
  };
  const locale = {
    filterConfirm: '确定',
    filterReset: '重置',
    emptyText: '暂无数据'
  };

  /*if (pageParam.current) {
    pagination.current = pageParam.current;

    if (pageParam.total > 0 && rows.length === 0) {
      locale.emptyText = `第${pagination.current}页没有数据。你可以重置查询条件或翻页试一试。`
    }
  }*/
  return <Table {...antdTablePropsMeger}
                rowSelection={rowSelection}
                bordered
                rowKey={record => record[rowKey] }
                columns={columns}
                loading={loading}
                dataSource={rows}
                pagination={pagination}
                locale={locale}
                size={size || 'middle'}
                scroll={{...scroll}}
  />
};
TableView.propTypes = {
  pageParam: PropTypes.shape({
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,//当前页码
    pageSize: PropTypes.number.isRequired,//当前分页数
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired
  }).isRequired,
  rowSelection: PropTypes.shape({
    selectedRowKeys: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  }),
  scroll: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool
    ]),
    y: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool
    ]),
  }),//表格区域滚动参数
  rowKey: PropTypes.string.isRequired, //行记录的索引
  loading: PropTypes.bool.isRequired, //是否显示表格的loading
  columns: PropTypes.array.isRequired, //表格的列参数
  rows: PropTypes.array.isRequired, //需要展示的记录, 对应Table 的dataSource属性
  style: PropTypes.object,
  antdTableProps: PropTypes.object, // antd Table 组件参数，参考：https://ant.design/components/table-cn/#API
};
TableView.defaultProps = {
  loading: false,
  rowSelection: null,
  scroll: {x: false, y: false}
};
export default TableView
