/**
 * dc-*-controller : 数据中心
 */
import brand from './brand';
import spec from './spec';
import unit from './unit';
import product from './product';

export default {
  unit,
  spec,
  brand,
  product,
};
