/**
 * user-controller : 云镖用户中心
 *
 * POST /userAPI/v6/api/getUserRole 获取用户角色列表
 */
import { request } from 'utils';
import {
  service,
} from '../../api.config';
const apiInfo = {
  'getUserRole': { 'path': '/uc/userAPI/v6/api/getUserRole', 'method': 'POST', 'desc': '获取用户角色列表' },
};
const controller = {};
(function () {
  Object.keys(apiInfo).forEach(key => {
    controller[key] = async function (params) {
      const options = {
        url: `${service.host}${apiInfo[key].path}`,
        method: apiInfo[key].method,
        data: params || undefined,
      };

      return request(options);
    };
  })
})();

controller.getApi = key => `${service.host}${apiInfo[key].path}`;

export default controller;
